import React from 'react'
import Layout from "components/common/layout"
import doneImage from "assets/img/asset_2.svg"

const SuccessUploadFile = () => {
  return (
    <Layout backgroundColor="bg-white" footerColor="bg-warning">
      <main className="container" role="main">
        <div className="text-center">
          <h1 className="mb-5">Congratulations!</h1>
          <img style={{width:"70%"}} src={doneImage} />
        </div>
      </main>
    </Layout>
  )
}

export default SuccessUploadFile
