import React, { useState, useEffect } from "react"
import Layout from "components/common/layout"
import { navigate, useParams, Link } from "@reach/router"
import { callApi, GET_PRINT_ORDER, PUT_PRINT_ORDER, DELETE_PRINT_ORDER } from "lib/api-server"
import ReactTooltip from "react-tooltip"

const PrintOrderDetail = (props) => {
  const { projectId, printOrderId } = useParams()
  const [printOrder, setPrintOrder] = useState()
  const [pONumber, setPONumber] = useState("")
  const [deliveryAddress, setDeliveryAddress] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    callApi("GET", GET_PRINT_ORDER.replace(":id", printOrderId)).then(res => {
      setPrintOrder(res)
      setDeliveryAddress(res.delivery_address)
    })
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  const handleSentToPrint = () => {
    if(!isSubmitting)
    {
      setIsSubmitting(true)

      callApi("PUT", PUT_PRINT_ORDER.replace(":id", printOrderId), { po_number: pONumber, delivery_address: deliveryAddress, is_sent: true }).then(res => {
        setPrintOrder({...printOrder, is_sent: true})
        setIsSubmitting(false)
      }).catch(err => {
        setIsSubmitting(false)
      })
    }

  }

  const OrderNotSubmitted= (
    <>
      <div className="card-header border-0 pb-0">
        <h2 className="text-warning mb-0">Enter Order Details</h2>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label htmlFor="po-number">PO Number</label>
          <input
            className="form-control"
            type="text"
            id="po-number"
            aria-describedby="poHelp"
            placeholder="Type PO number"
            value={pONumber}
            onChange={(e) => setPONumber(e.target.value)}
            // onclick='window.location.href="teacher-project-print-po-exist.html"'
          />
          <small className="form-text text-muted" id="poHelp">
            Would you like to have other payment options available? Let
            us know in the feedback.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="deliver-address">Delivery Address</label>
          <input
            className="form-control"
            type="text"
            id="deliver-address"
            aria-describedby=""
            placeholder="Enter the address for delivery"
            value={deliveryAddress}
            onChange={(e) => setDeliveryAddress(e.target.value)}
          />
        </div>
        <div className="form-group text-right">
          <a
            className="btn btn-link undefined false"
            role="button"
            aria-disabled="true"
            download="download"
            href={printOrder?.download_quote_url}
            target="_blank"
          >
            <i className="ri-xl ri-file-download-fill mr-1"></i>Download Quote
          </a>
          <button className="btn btn-primary" onClick={handleSentToPrint}>
            {
              isSubmitting ? <span className="spinner-border" role="status" style={{ width: "1rem", height: "1rem" }} /> : (pONumber === "" || deliveryAddress === "" ? "Enter PO to Print" : "Send to Print")
            }
          </button>
        </div>
      </div>
    </>
  )

  const OrderSubmitted = (
    <>
      <div className="card-header border-0 pb-0">
        <h2 className="text-warning mb-0">Order Submitted</h2>
      </div>
      <div className="card-body">
        <h3 className="text-success">Order received!</h3>
        <p>You should receive your models within 2-5 business days.</p>
        <br/>
        <p>Note: Student submissions are now closed. You can open them again
          later on the <Link className="underline" to={`/projects/${projectId}`}>{printOrder?.project_name}</Link> view page.
        </p>
      </div>
      <div className="card-footer text-right border-0">
        <a className="btn btn-link pr-0 undefined" target="_blank" role="button" aria-disabled="true" href={printOrder?.download_invoice_url} download>
          <i className="ri-xl ri-file-download-fill mr-1"></i>Download Invoice
        </a>
      </div>
    </>
  )

  const handleDeleteOrder = () => {
    callApi("DELETE", DELETE_PRINT_ORDER.replace(":id", printOrderId)).then(res => {
      navigate(`/projects/${projectId}`)
    })
  }

  return (
    <Layout title="Print Order">
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title text-dark-blue">
          <span className="page-title__icon">
            <i className="ri-5x ri-checkbox-blank-circle-fill text-primary"></i>
          </span>
          <h1 className="ml-3 mb-0">Print Order</h1>
          <div className="btn-wrapper ml-auto">
            <Link
              className="page-title__link"
              to={`/projects/${projectId}`}
            >
              <span className="btn btn-icon btn-outline-warning rounded-circle bg-white">
                <i className="ri-xl ri-arrow-left-line"></i>
              </span>
              Back to Selection
            </Link>
          </div>
        </div>
        <div className="row row-summary">
          <div className="col-12 col-summary-left">
            <div className="card card-content-wrapper card-project card-summary-order">
              <div className="card-header">
                <h2 className="text-warning mb-0">Order Summary</h2>
                {!printOrder?.is_sent && (
                  <a className="text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteOrder}>
                    <i className="ri-xl ri-delete-bin-2-line"></i>
                    Delete Order
                  </a>
                )}
              </div>
              <div className="card-body">
                <dl>
                  <dt>Total Files</dt>
                  <dd>{printOrder?.total_file}</dd>
                  <dt>Total Print Time:</dt>
                  <dd>{printOrder?.total_print_time}</dd>
                  <dt>Total Grams Used:</dt>
                  <dd className="mb-4">{printOrder?.total_grams}</dd>
                  <dt className="d-item-bold">
                    Subtotal Cost (excluding delivery):
                  </dt>
                  <dd className="d-item-bold">{printOrder?.subtotal_cost}</dd>
                  <dt className="d-item-bold">Set Up Cost:</dt>
                  <dd className="mb-4 d-item-bold">{printOrder?.setup_cost}</dd>
                  <dt>Delivery Fee:</dt>
                  <dd>
                    {printOrder?.delivery_fee}
                    <span
                      className="tooltip-wrapper ml-1"
                      data-title=""
                    >
                      <i className="tooltip-icon fa fa-check-circle" data-tip="If the total weight is above 500g, the delivery is $30. Most prints will be delivered within 2 - 5 business days. If you need same day or next day delivery please contact TinkerSteps to discuss."></i>
                    </span>
                  </dd>
                  { printOrder?.coupon && (
                    <>
                      <dt>Discount:</dt>
                      <dd>
                        -{printOrder?.discount}
                        <span
                            className="tooltip-wrapper ml-1"
                            data-title="Not including setup or delivery cost"
                          >
                            <i className="tooltip-icon fa fa-check-circle" data-tip='Not including setup or delivery cost'/>
                          </span>
                      </dd>
                    </>
                  )}
                </dl>
              </div>
              <div className="card-footer">
                <dl>
                  <dt className="d-item-bold">GST:</dt>
                  <dd className="d-item-bold">{printOrder?.gst}</dd>
                </dl>
                <dl>
                  <dt className="d-item-bold">
                    Total Cost (including Set Up, GST & Delivery):
                  </dt>
                  <dd className="h5">{printOrder?.total_cost}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="col-12 col-summary-right">
            <div className="card card-content-wrapper card-project card-summary-order">
              {
                printOrder && (printOrder?.is_sent ? OrderSubmitted : OrderNotSubmitted)
              }
            </div>
          </div>
        </div>
        <div className="card card-content-wrapper card-project">
          <div className="card-header border-0 pb-0">
            <h2 className="text-warning mb-0">Items Sent to Print</h2>
          </div>
          <div className="card-body">
            <div className="table-scroll has-scroller">
              <div className="table-head">
                <table className="table mb-0">
                  <colgroup>
                    <col width="350px" />
                    <col width="120px" />
                    <col width="100px" />
                    <col width="180px" />
                    <col width="109px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">File</th>
                      <th scope="col">Name</th>
                      <th className="text-center" scope="col">Quantity</th>
                      <th scope="col">Print Time</th>
                      <th scope="col">Grams(g)</th>
                      <th scope="col">Cost</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="table-body">
                <div className="table-body__wrapper">
                  <div className="table-responsive">
                    <table className="table table-align-center">
                      <colgroup>
                        <col width="350px" />
                        <col width="120px" />
                        <col width="100px" />
                        <col width="180px" />
                        <col width="109px" />
                      </colgroup>
                      <tbody>
                        {
                          printOrder?.files.map(file => (
                            <tr key={file.id}>
                              <td>
                                <div className="media align-items-center">
                                  <img
                                    className="mr-3"
                                    src={file?.thumb_url}
                                    width='100'
                                    alt={file.file_name}
                                  />
                                  {file.file_name}
                                </div>
                              </td>
                              <td>{file.name}</td>
                              <td className="text-center">{file.quantity}</td>
                              <td>{file.print_time}</td>
                              <td>{file.grams}</td>
                              <td>{file.cost}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="table-body__scroller">
                <div className="scroller__panel"></div>
                <div className="scroller__dot" style="top:50px"></div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default PrintOrderDetail
