import React, { useState, useEffect } from "react"
import { VALID_EMAIL } from "lib/validate"
import ValidateForm from "components/common/ValidateForm"
import {
  ACCOUNT_API,
  GET_SCHOOLS_API,
  callApi,
  GET_NEW_VERIFY_CODE,
  VERIFY_EMAIL,
  SIGN_OUT,
} from "lib/api-server"
import Layout from "../common/layout"
import "../../assets/scss/pages/register.scss"
import { navigate } from "gatsby"
import { logout } from "../../services/auth"
import toast from "react-hot-toast"
import VerifyEmailPopup from "components/common/VerifyEmailPopup"
import { getUser } from "services/auth"

const Profile = props => {
  const isAdmin = getUser()?.role?.includes("admin")
  const [messageErrors, setMessageErrors] = useState({})
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    new_password: "",
    repeat_new_password: "",
    school_address: ""
  })

  const [schoolList, setSchoolList] = useState([])
  const [selectedSchool, setSelectedSchool] = useState(null)
  // const [originalEmail, setOriginalEmail] = useState("")
  const [isShowConfirEmailButton, setIsShowConfirEmailButton] = useState(false)

  const [isShow, setIsShow] = useState({
    isShowPassword: false,
    isShowRepeatPassword: false,
    isShowNewPassword: false,
  })

  const [isChange, setIsChange] = useState({
    isChangeFirstName: false,
    isChangeLastName: false,
    isChangeContactNumber: false,
    isChangeEmail: false,
    isChangeSchoolAddress: false,
    isChangePassWord: false,
    isChangeNewPassWord: false,
    isChangeRepeatPassWord: false,
  })

  useEffect(() => {
    const teacherDetailPromise = callApi("GET", ACCOUNT_API)
    const schoolsPromise= callApi("GET", GET_SCHOOLS_API)

    Promise.all([teacherDetailPromise, schoolsPromise]).then(values => {
      if(values[0].id)
      {
        setData({
          ...data,
          email: values[0].email,
          first_name: values[0].first_name,
          last_name: values[0].last_name,
          phone: values[0].phone,
          school: values[0].school,
          school_address: values[0].school_address,
          unconfirmed_email: values[0].unconfirmed_email
        })
        // setOriginalEmail(values[0].email)
      }

      if(values[1].length > 0 )
      {
        setSchoolList(values[1])
      }

      if(values[0].school)
      {
        const originalSchoolId = values[1].find(option => option.name === values[0].school)?.id

        if(originalSchoolId)
        {
          setSelectedSchool(originalSchoolId)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (data.email !== "") {
      if (!VALID_EMAIL.test(data.email) && !messageErrors?.emailMessage) {
        setMessageErrors({
          ...messageErrors,
          emailMessage: "Invalid email format",
        })
      } else if (VALID_EMAIL.test(data.email) && messageErrors?.emailMessage) {
        delete messageErrors.emailMessage
        setMessageErrors({...messageErrors})
      }
    }
  }, [data.email])

  //Update profile
  const handleSave = e => {
    e.preventDefault()
    if (isSaveLoading) {
      return
    }
    setIsSaveLoading(true)

    let body = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      school_id: selectedSchool,
      school_address: data.school_address,
      current_password: data.password
    }

    let pass = {
      password: data.new_password,
      password_confirmation: data.repeat_new_password,
    }

    if (data.new_password) {
      body = { ...body, ...pass }
    }

    callApi("PUT", ACCOUNT_API, body)
      .then(res => {
        setIsSaveLoading(false)
        setIsChange({
          ...isChange,
          isChangeFirstName: false,
          isChangeLastName: false,
          isChangeContactNumber: false,
          isChangeEmail: false,
          isChangeSchoolAddress: false,
          isChangePassWord: false,
          isChangeNewPassWord: false,
          isChangeRepeatPassWord: false,
        })

        if(res.unconfirmed_email) {
          setIsShowModal(true)
        } else {
          toast.success("The account has been updated")
        }

        setData({...data, password: '', new_password: '', repeat_new_password: ''})
      })
      .catch(err => {
        setIsSaveLoading(false)

        if (err.response) {
          const fieldErrors = err.response.data.errors

          if(fieldErrors) {
            fieldErrors.forEach(item => {
              switch (item.field) {
                case "first_name":
                  setMessageErrors({
                    ...messageErrors,
                    firstNameMessage: item.message,
                  })
                  break
                case "last_name":
                  setMessageErrors({
                    ...messageErrors,
                    lastNameMessage: item.message,
                  })
                  break
                case "phone":
                  setMessageErrors({
                    ...messageErrors,
                    contactMessage: item.message,
                  })
                  break
                case "school_address":
                  setMessageErrors({
                    ...messageErrors,
                    schoolAddressMessage: item.message,
                  })
                  break
                case "email":
                  setMessageErrors({
                    ...messageErrors,
                    emailMessage: item.message,
                  })
                  break
                case "password":
                  setMessageErrors({
                    ...messageErrors,
                    newPasswordMessage: item.message,
                  })
                  break
                case "current_password":
                  setMessageErrors({
                    ...messageErrors,
                    passwordMessage: item.message,
                  })
                  break
                case "password_confirmation":
                  setMessageErrors({
                    ...messageErrors,
                    repeatPasswordMessage: item.message,
                  })
                  break
              }
            })
          }
        }
      })
  }

  //verify email address
  const [isShowModal, setIsShowModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [inform, setInform] = useState(
    "Verify code was sent, let check in your email"
  )
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const handleVerifyEmail = (code) => {

    if (isVerifyLoading) {
      return
    }
    setIsVerifyLoading(true)
    setInform("")

    callApi("POST", VERIFY_EMAIL, { verify_code: code })
      .then(res => {
        if (res.errors) {
          setIsError(true)
        } else {
          toast.success("The account has been updated")
          setIsShowModal(false)
        }

        setIsVerifyLoading(false)
      })
      .catch(err => {
        setIsError(true)
        setIsVerifyLoading(false)
      })
  }

  const handleConfirmEmail = event => {
    event.preventDefault()
    setIsShowModal(true)
    callApi("GET", GET_NEW_VERIFY_CODE)
  }

  const handleSignOut = event => {
    event.preventDefault()

    callApi("DELETE", SIGN_OUT)
      .then(res => { logout(navigate(isAdmin ? "/admin" : "/login")) })
      .catch(res => { logout(navigate(isAdmin ? "/admin" : "/login")) })
  }

  return (
    <>
      <Layout title="Account Setting" footerColor="bg-warning">
      <main className="bg-white " role="main">
          <div className="container">
            <div className="text-center">
            <h1 className="page-title">Account Setting</h1>
            <p className="pb-5">Change your personal details
              <br/>and account settings.
            </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="form-group">
                  <label htmlFor="f-name">First Name</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      id="f-name"
                      name="first_name"
                      aria-describedby=""
                      placeholder="Type your first name"
                      value={data.first_name}
                      onChange={e => {
                        setData({ ...data, first_name: e.target.value })
                        setIsChange({
                          ...isChange,
                          isChangeFirstName: true,
                        })
                        delete messageErrors.firstNameMessage
                      }}
                      required
                    />
                    {isChange.isChangeFirstName && messageErrors.firstNameMessage && (
                        <ValidateForm
                          dataTitle={messageErrors.firstNameMessage}
                          isError={messageErrors.firstNameMessage}
                        />
                      )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="l-name">Last Name</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      id="l-name"
                      name="last_name"
                      aria-describedby=""
                      placeholder="Type your last name"
                      value={data.last_name}
                      onChange={e => {
                        setData({ ...data, last_name: e.target.value })
                        setIsChange({
                          ...isChange,
                          isChangeLastName: true,
                        })
                        delete messageErrors.lastNameMessage
                      }}
                      required
                    />
                    {isChange.isChangeLastName && messageErrors.lastNameMessage && (
                        <ValidateForm
                          dataTitle={messageErrors.lastNameMessage}
                          isError={messageErrors.lastNameMessage}
                        />
                      )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Contact Number</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      id="phone"
                      name="phone"
                      aria-describedby=""
                      placeholder="Type number"
                      value={data.phone}
                      onChange={e => {
                        setData({ ...data, phone: e.target.value })
                        setIsChange({
                          ...isChange,
                          isChangeContactNumber: true,
                        })
                        delete messageErrors.contactMessage
                      }}
                    />
                    {isChange.isChangeContactNumber && messageErrors.contactMessage && (
                        <ValidateForm
                          dataTitle={messageErrors.contactMessage}
                          isError={messageErrors.contactMessage}
                        />
                      )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Type email"
                      value={data.email}
                      onChange={e => {
                        setData({ ...data, email: e.target.value })
                        setIsChange({
                          ...isChange,
                          isChangeEmail: true,
                        })
                      }}
                    />
                    {isChange.isChangeEmail && messageErrors.emailMessage && (
                      <ValidateForm
                        dataTitle={messageErrors.emailMessage}
                        isError={messageErrors.emailMessage}
                      />
                    )}
                  </div>

                  {/* <div className={`text-right mt-3 ${(originalEmail === data.email || messageErrors.emailMessage) ? "d-none" : "d-block"}`}> */}
                  <div className={`text-right mt-3 ${data.unconfirmed_email ? 'd-block' :  "d-none"}`}>
                    <a
                    className="btn btn-outline-primary"
                    onClick={handleConfirmEmail}>
                      CONFIRM EMAIL</a>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group ml-md-auto">
                  <label htmlFor="#pword-current">Current Password</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="pword-current"
                      name="current password"
                      type={"password"}
                      placeholder=""
                      value={data.password}
                      onChange={e => {
                        setData({ ...data, password: e.target.value })
                        delete messageErrors.passwordMessage
                      }}
                    />
                    {(messageErrors.passwordMessage) && (
                      <ValidateForm
                        dataTitle={messageErrors.passwordMessage}
                        isError={messageErrors.passwordMessage}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group ml-md-auto">
                  <label for="#pword-new">New Password</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="#pword-new"
                      name="new_password"
                      type={isShow.isShowNewPassword ? "text" : "password"}
                      placeholder="Password must be at least 6 characters"
                      value={data.new_password}
                      onChange={e => {
                        setData({ ...data, new_password: e.target.value })
                        delete messageErrors.newPasswordMessage
                      }}
                    />
                    {messageErrors.newPasswordMessage ? (
                      <ValidateForm
                        dataTitle={messageErrors.newPasswordMessage}
                        isError={messageErrors.newPasswordMessage}
                      />
                    ) : (
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {isShow.isShowNewPassword ? (
                            <i
                              className="ri-lg ri-eye-off-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowNewPassword: !isShow.isShowNewPassword,
                                })
                              }}
                            />
                          ) : (
                            <i
                              className="ri-lg ri-eye-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowNewPassword: !isShow.isShowNewPassword,
                                })
                              }}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group ml-md-auto">
                  <label for="pword-repeat">Repeat New Password</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="pword-repeat"
                      name="repeat_new_password"
                      aria-describedby=""
                      type={isShow.isShowRepeatPassword ? "text" : "password"}
                      placeholder="Repeat Password"
                      value={data.repeatPassword}
                      onChange={e => {
                        setData({ ...data, repeat_new_password: e.target.value })
                        delete messageErrors.repeatPasswordMessage
                      }}
                    />
                    {messageErrors.repeatPasswordMessage ? (
                      data.repeat_new_password && (
                        <ValidateForm
                          dataTitle={messageErrors.repeatPasswordMessage}
                          isError={messageErrors.repeatPasswordMessage}
                        />
                      )
                    ) : (
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {isShow.isShowRepeatPassword ? (
                            <i
                              className="ri-lg ri-eye-off-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowRepeatPassword: !isShow.isShowRepeatPassword,
                                })
                              }}
                            />
                          ) : (
                            <i
                              className="ri-lg ri-eye-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowRepeatPassword: !isShow.isShowRepeatPassword,
                                })
                              }}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                { !isAdmin && (
                  <>
                    <div className="form-group ml-md-auto">
                      <label htmlFor="school">School</label>
                      <select
                        className="custom-select"
                        id="school"
                        name="school"
                        value={
                          schoolList.find(option => option.id === selectedSchool)
                            ?.id
                        }
                        onChange={e => setSelectedSchool(e.target.value)}
                      >
                        {schoolList.map((school, index) => (
                          <option key={index} value={school.id}>
                            {school.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group ml-md-auto">
                      <label for="school-address">School Address</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          id="school-address"
                          name="school_address"
                          aria-describedby=""
                          placeholder="School Address"
                          value={data.school_address}
                          onChange={e => {
                            setData({ ...data, school_address: e.target.value })
                            setIsChange({
                              ...isChange,
                              isChangeSchoolAddress: true,
                            })
                          }}
                        />
                        {isChange.isChangeSchoolAddress && (
                          <ValidateForm
                            dataTitle={messageErrors.schoolAddressMessage}
                            isError={messageErrors.schoolAddressMessage}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="text-center form-cta">
              <a
                className="btn btn-primary btn-single"
                onClick={handleSave}
              >
                {isSaveLoading ? (
                  <span className="spinner-border text-primary" style={{height: "1rem", width: "1rem"}} role="status" />
                ) : (
                  `SAVE`
                )}
              </a>

              <br/>
              <a className="btn btn-link underline" onClick={handleSignOut}>Sign out</a>
            </div>
          </div>
        </main>
        {
          isShowModal &&
            <VerifyEmailPopup
            isError={isError}
            inform={inform}
            handleVerifyEmail={handleVerifyEmail}
            isVerifyLoading={isVerifyLoading}
            handleCancel={() => setIsShowModal(false)}
          />
        }
      </Layout>
    </>
  )
}

export default Profile
