import AWS from "aws-sdk"

export const uploadFile = async (file) => {
  const formData = new FormData()
  formData.append("file", file)

  AWS.config.update({
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
    region: process.env.AWS_REGION,
  })

  const s3 = new AWS.S3()

  const objParams = {
    Bucket: process.env.AWS_BUCKET_NAME,
    ACL: 'public-read',
    Key: `fileUpload/${file.name}`,
    Body: file
  }
  return s3.putObject(objParams)
}