import React, { useState } from 'react'
import ReactCodeInput from "react-code-input"

const VerifyEmailPopup = (props) => {
  const { isError, inform, isVerifyLoading, handleGetNewVerifyCode, isGetNewVerifyCodeLoading, handleVerifyEmail, handleCancel, forSigninPage } = props
  const [code, setCode] = useState("")
  const styleForButtonSigninPage = { margin: "0 6px 0 6px" }

  return (
    <>
      <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }} >
        <div className="modal-dialog modal-dialog-centered modal-noti" role="document" >
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="modal-title">Verify Email Address</h1>
              {isError ? (
                <p className="text-danger mb-0 mt-3">
                  The number you have entered is incorrect. Please try again.
                </p>
              ) : inform ? (
                <p className="text-primary mb-0 mt-3">{inform}</p>
              ) : null}

              <ReactCodeInput
                value={code}
                onChange={value => setCode(value)}
                className="form-row justify-content-center"
                type="text"
                fields={6}
              />
              <div className="btn-group">
                {
                  handleCancel && <a className="btn btn-outline-primary" style={forSigninPage && styleForButtonSigninPage} onClick={() => handleCancel()} >CANCEL</a>
                }

                <a className="btn btn-primary" style={forSigninPage && styleForButtonSigninPage} onClick={() => handleVerifyEmail(code)}>
                  {isVerifyLoading ? <span className="spinner-border text-primary" role="status"/> : "VERIFY & SIGN IN"}
                </a>

                {
                  handleGetNewVerifyCode && (
                    <p className="mt-5 text-primary">
                      Didn't receive the code ?
                      <a className="btn btn-link font-weight-bold" onClick={handleGetNewVerifyCode}>
                        {isGetNewVerifyCodeLoading ? (
                          <span style={{ width: "1rem", height: "1rem" }} className="spinner-border text-primary" role="status"/>
                        ) : (
                          `SEND AGAIN`
                        )}
                      </a>
                    </p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop"></div>
    </>
  )
}

export default VerifyEmailPopup
