import React, { useState, useEffect, useCallback } from "react"
import Layout from "components/common/layout"
import { getUser } from "services/auth"
import { GET_FILTER_TAGS_API, DETAIL_LESSON_PLAN_ADMIN, callApi } from "lib/api-server"
import { useParams } from "@reach/router"
import { Link, navigate } from "gatsby"
import TagsInput from "react-tagsinput"
import ValidateForm from "components/common/ValidateForm"
import { useDropzone } from "react-dropzone"
import Select, { components } from 'react-select'

import "react-tagsinput/react-tagsinput.css" // If using WebPack and style-loader.
import "assets/scss/pages/lesson-plan.scss"

import CKEditor from "react-ckeditor-component"
import toast from "react-hot-toast"

const LessonPlanEdit = props => {
  const { id } = useParams()

  const [data, setData] = useState({ title: "", description: "", content: "", files: [] })
  const [messageErrors, setMessageErrors] = useState({})
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [fileDeletes, setFileDeletes] = useState([])
  const [isChange, setIsChange] = useState({
    isChangeTitle: false,
    isChangeDescription: false,
    isChangeContent: false,
  })

  const [content, setContent] = useState('')
  const [options, setOptions] = useState([{}])
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    const url = DETAIL_LESSON_PLAN_ADMIN.replace(":id", id)

    callApi("GET", url)
      .then(res => {
        setData({
          ...data,
          title: res.title,
          description: res.description,
          content: res.content,
          id: res.id,
          files: res.lession_files,
        })

        setSelectedOptions(res.tag_list.map(item => {return {value: item, label: item}}))

        setContent(res.content)
      })
      .catch(err => console.log(err.response))

      callApi("GET", GET_FILTER_TAGS_API)
      .then(data => {
        const tags = data?.map((item) =>(
          {value: item, label: item}
        ))
        setOptions(tags)
      })
      .catch(err => console.log(err))

  }, [])

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setData({ ...data, files: [...data.files, ...acceptedFiles] })
        toast.dismiss()
      }
      else {
        toast.error("Only accept pdf type file", { id: "upload-error-type-file" })
      }
    },
    [data.files]
  )

  const { getRootProps, getInputProps } = useDropzone({onDrop, noClick: true, accept: "application/pdf"})

  const handleRemoveFile = i => {
    const reducedFiles = [...data.files]
    const arr = reducedFiles.splice(i, 1)
    if (Object.keys(arr[0]).includes('url')) {
      setFileDeletes(fileDeletes => [...fileDeletes, ...arr])
    }
    setData({ ...data, files: reducedFiles })
  }

  useEffect(() => {
    setData({ ...data, files: data.files })
  }, [data.files])

  const handleSave = event => {
    event.preventDefault()

    if (isSaveLoading) {
      return
    }
    setIsSaveLoading(true)
    const formData = new FormData()
    formData.append("title", data.title)
    formData.append("tag_list", selectedOptions.map(item => item.value))
    formData.append("description", data.description)
    formData.append("content", content)

    const listNew = data.files.filter(item => !Object.keys(item).includes('url'))

    listNew.forEach((item, index) => formData.append(`lesson_files_attributes[${index}][file]`, item))

    const lengthNewFiles = listNew.length

    fileDeletes.forEach((item, index) => {
      formData.append(
        `lesson_files_attributes[${lengthNewFiles + index}][id]`,
        item.id
      )
      formData.append(`lesson_files_attributes[${lengthNewFiles + index}][_destroy]`, true)
    })

    const url = DETAIL_LESSON_PLAN_ADMIN.replace(":id", id)
    callApi("PUT", url, formData)
      .then(res => {
        navigate(`/admin/resources/${res.id}`)
      })
      .catch(err => {
        setIsSaveLoading(false)

        if (err.response) {
          const fieldErrors = err.response.data.errors
          if(fieldErrors)
          {
            fieldErrors.forEach(item => {
              switch (item?.field) {
                case "title":
                  setMessageErrors({
                    ...messageErrors,
                    titleMessage: item.message,
                  })
                  break
                case "description":
                  setMessageErrors({
                    ...messageErrors,
                    descriptionMessage: item.message,
                  })
                  break
                case "content":
                  setMessageErrors({
                    ...messageErrors,
                    contentMessage: item.message,
                  })
                  break
              }
            })
          }
        }
      })
  }

  const handleDeleteLessonPlan = id => {
    const url = DETAIL_LESSON_PLAN_ADMIN.replace(":id", id)
    callApi("DELETE", url)
      .then(res => {
        navigate(`/admin/resources`)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Layout title="Lesson Plan Edit">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title">
          <span className="page-title__icon bg-white">
            <i className="ri-xl ri-user-fill text-warning"></i>
          </span>
          <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
          <div className="btn-wrapper ml-auto">
            <Link to={"/admin/resources"}>
              <a className="btn btn-outline-primary">Cancel</a>
            </Link>
            <a className="btn btn-primary" onClick={handleSave}>
              {isSaveLoading ? (
                <span
                  className="spinner-border"
                  role="status"
                  style={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Save"
              )}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col col-content">
            <div className="card card-content-wrapper">
              <div className="card-header">
                <h2 className="text-warning mb-0">Lesson Plan</h2>
                <a
                  className="text-danger"
                  onClick={() => handleDeleteLessonPlan(data.id)}
                >
                  <i className="ri-xl ri-delete-bin-2-line"></i>
                  <span>Delete Lesson Plan</span>
                </a>
              </div>
              <div className="card-body">
                <form className="mt-3">
                  <div className="form-group">
                    <label htmlFor="lesson-tags">Related tags</label>
                    <Select
                      className='react-select-container'
                      classNamePrefix="react-select"
                      options={options}
                      value={selectedOptions}
                      onChange={(val) => setSelectedOptions(val)}
                      placeholder="Related Tags"
                      isMulti
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lesson-title">Lesson Title</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        id="lesson-title"
                        aria-describedby=""
                        placeholder="Title"
                        value={data.title}
                        onChange={e => {
                          setData({ ...data, title: e.target.value })
                          setIsChange({
                            ...isChange,
                            isChangeTitle: true,
                          })
                          delete messageErrors.titleMessage
                        }}
                        required
                      />
                      {isChange.isChangeTitle &&
                        (data.title != "" || messageErrors.titleMessage) && (
                          <ValidateForm
                            dataTitle={messageErrors.titleMessage}
                            isError={messageErrors.titleMessage}
                          />
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lesson-desc">Short Description</label>
                    <div className="input-group">
                      <textarea
                        className="form-control"
                        id="lesson-desc"
                        aria-describedby=""
                        placeholder="Short Description"
                        rows="5"
                        value={data.description}
                        onChange={e => {
                          setData({ ...data, description: e.target.value })
                          setIsChange({
                            ...isChange,
                            isChangeDescription: true,
                          })
                          delete messageErrors.descriptionMessage
                        }}
                        required
                      />
                      {isChange.isChangeDescription &&
                        (data.description != "" ||
                          messageErrors.descriptionMessage) && (
                          <ValidateForm
                            dataTitle={messageErrors.descriptionMessage}
                            isError={messageErrors.descriptionMessage}
                          />
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="ckEditor">Lesson Content</label>
                    <CKEditor
                      className="form-control"
                      content={content}
                      events={{
                        "change": (evt) => {
                          const dataEdit = evt.editor.getData()
                          setContent(dataEdit)
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col col-aside">
            <div className="card card-content-wrapper">
              <div className="card-header">
                <h2 className="text-warning mb-0">Downloads</h2>
              </div>
              <div className="card-body">
                <div className="dragdrop" id="dragdrop" {...getRootProps()}>
                  <input id="ip-file-upload" {...getInputProps()} />
                  <div className="dragdrop__wrapper dragdrop--empty">
                    <i className="ri-4x ri-drag-drop-line dragdrop__icon"></i>
                    <div className="dragdrop__cta">
                      <label htmlFor="ip-file-upload" className="btn btn-white dragdrop__cta">
                        Upload File
                      </label>
                    </div>
                  </div>
                </div>
                <ul className="list-unstyled list-icon" id="uploaded-files">
                  {data.files?.map((item, index) => (
                    <li className="list-icon__item" key={index}>
                      <span className="list-icon__icon text-primary">
                        <i className="ri-file-download-fill uploaded__icon"></i>
                      </span>
                      <a className="small mr-auto" download>
                        Download {item.name}
                      </a>
                      <a className="list-icon__icon ml-auto">
                        <i
                          className="ri-xl ri-close-line"
                          onClick={handleRemoveFile}
                        ></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default LessonPlanEdit
