import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';

const middlewares = [thunkMiddleware, loggerMiddleware];
// const middlewares = [thunkMiddleware];

export default function initStore(baseReducer) {
  return createStore(
    baseReducer,
    applyMiddleware(...middlewares),
  );
}


