import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCouponList } from 'actions'
import { ADMIN_COUPON_API, callApi } from "lib/api-server"
import moment from 'moment'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { handleToastCopy } from "lib/toast"
import BeatLoader from "react-spinners/BeatLoader";
import toast from "react-hot-toast"

const CouponsTable = () => {
  const dispatch = useDispatch()
  const coupons = useSelector(state => state.coupons)
  const [isGettingCoupons, setIsGettingCoupons] = useState(false)
  
  useEffect(() => {
    setIsGettingCoupons(true)

    callApi("GET", ADMIN_COUPON_API).then(res => {
      setIsGettingCoupons(false)
      dispatch(setCouponList(res))
    }).catch(err => setIsGettingCoupons(false))

    return () => {
      dispatch(setCouponList([]))
    }
  }, [])

  const deleteCoupon=(id) => {
    // delete coupon

    callApi("DELETE", `${ADMIN_COUPON_API}/${id}`)
      .then(res => {
        toast.success("Coupon is deleted successfully")
        
        callApi("GET", ADMIN_COUPON_API).then(res => {
          setIsGettingCoupons(false)
          dispatch(setCouponList(res))
        }).catch(err => setIsGettingCoupons(false))
      })
      .catch(err => {
      })
  }

  return (
    <>
      <table className="table table-align-center">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Code</th>
            <th scope="col">Value</th>
            <th scope="col">Expiration Date</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {coupons.map(coupon => (
            <tr key={coupon.id}>
              <th scope="row">{coupon.id}</th>
              <td>
                <CopyToClipboard text={coupon.code} onCopy={handleToastCopy}>
                  <a className="underline text-primary">{coupon.code}</a>
                </CopyToClipboard>
              </td>
              <td>{coupon.value}</td>
              <td>{moment(coupon.expiration_date).format('DD-MM-YYYY, h:mm a')}</td>
              <td><a href='javascript:void(0)' id={coupon.id} onClick={e => deleteCoupon(coupon.id)}>Delete</a></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        <BeatLoader loading={isGettingCoupons} size={10} color="#E37D2B"/>
      </div>
      { !isGettingCoupons && coupons.length === 0 &&
        <p className="w-100 text-center">You don't have any coupons</p>
      }
    </>
  )
}

export default CouponsTable
