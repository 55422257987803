import React, { useEffect } from "react"
import { navigate, useLocation } from "@reach/router"
import { useDispatch } from "react-redux"
import { updateInfoUploadFileInfo, updateProjectUploadFileInfo } from "src/redux/actions"

const UploadFile = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if(!location.state?.project)
    {
      navigate("/")
    }
    else{
      dispatch(updateProjectUploadFileInfo(location.state?.project))
    }

    return () =>{
      dispatch(updateInfoUploadFileInfo({}))
      dispatch(updateProjectUploadFileInfo({}))
      localStorage.removeItem("fileUploadId")
    }
  }, [])

  return (
    <>
     {props.children}
    </>
  )
}

export default UploadFile
