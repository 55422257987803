import React, { useState, useEffect } from "react"
import Layout from "components/common/layout"
import { getUser } from "services/auth"
import { ADMIN_GET_PRINT_ORDER_API, callApi, ADMIN_PUT_PRINT_ORDER_API } from "lib/api-server"
import BeatLoader from "react-spinners/BeatLoader";
import MoonLoader from "react-spinners/MoonLoader"
import Pagination from "rc-pagination"
import "rc-pagination/assets/index.css"

const AdminOrders = props => {
  const [loadings, setLoadings] = useState([])
  const [isGettingPrintOrder, setIsGettingPrintOrder] = useState()
  const [dataOrders, setDataOrders] = useState([])
  const [pagination, setPagination] = useState({
    per_page: 10,
    total_objects: 0,
    total_pages: 1,
    current_page: 1,
  })

  const getOrders = (page = 1) => {
    callApi(
      "GET",
      `${ADMIN_GET_PRINT_ORDER_API}?search=${searchTerm}&page=${page}&per=${pagination.per_page}`
    )
    .then(res => {
      setIsGettingPrintOrder(false)
      setDataOrders(res.data)
      setPagination(res.meta.pagination)
      if(res.data.length > 0)
      {
        const newLoadings = res.data.reduce((obj,item) => {
          obj[item.id] = [false, false]
          return obj
        }, {})

        setLoadings(newLoadings)
      }
    })
    .catch(err => setIsGettingPrintOrder(false))
  }

  const onChangePage = page => {
    setPagination({ ...pagination, current_page: page })
    getOrders(page)
  }

  //Search
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getOrders(1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleCheckPaid = (id, value) => {
    if(!loadings[id][0])
    {
      setLoadings(loadings => {
        return {...loadings, [id]: [true, loadings[id][1]]}
      })
      callApi("PUT", ADMIN_PUT_PRINT_ORDER_API.replace(":id", id), {is_paid_by_admin: value}).then(res => {
        const index = dataOrders.findIndex(item => item.id === res.id)
        if(index !== -1 && dataOrders.length > 0)
        {
          setDataOrders([...dataOrders.slice(0,index), {...dataOrders[index], is_paid_by_admin: res.is_paid_by_admin}, ...dataOrders.slice(index + 1)])
        }
        setLoadings(loadings => {
          return {...loadings, [id]: [false, loadings[id][1]]}
        })
      }).catch(err => {
        setLoadings(loadings => {
          return {...loadings, [id]: [false, loadings[id][1]]}
        })
      })
    }
  }

  const handleCheckSent = (id, value) => {
    if(!loadings[id][0])
    {
      setLoadings(loadings => {
        return {...loadings, [id]: [loadings[id][0], true]}
      })
      callApi("PUT", ADMIN_PUT_PRINT_ORDER_API.replace(":id", id), {is_sent_by_admin: value}).then(res => {
        const index = dataOrders.findIndex(item => item.id === res.id)
        if(index !== -1 && dataOrders.length > 0)
        {
          setDataOrders([...dataOrders.slice(0,index), {...dataOrders[index], is_sent_by_admin: res.is_sent_by_admin}, ...dataOrders.slice(index + 1)])
        }
        setLoadings(loadings => {
          return {...loadings, [id]: [loadings[id][0], false]}
        })
      }).catch(err => {
        setLoadings(loadings => {
          return {...loadings, [id]: [loadings[id][0], false]}
        })
      })
    }
  }

  return (
    <Layout title="Print Orders">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title">
          <span className="page-title__icon bg-white">
            <i className="ri-xl ri-user-fill text-warning"></i>
          </span>
          <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
        </div>
        <div className="card card-content-wrapper">
          <div className="card-header">
            <h2 className="text-warning mb-0">Purchase Orders</h2>
            <form>
              <input
                className="form-control form-control--content-search"
                type="text"
                placeholder="Search (PO No., School, Teacher)"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </form>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <BeatLoader loading={isGettingPrintOrder} size={10} color="#E37D2B"/>
            </div>
            <div className="table-responsive">
              <table className="table table-align-center">
                <thead>
                  <tr>
                    <th scope="col">PO NO.</th>
                    <th scope="col">School</th>
                    <th scope="col">Teacher</th>
                    <th scope="col">Date</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Sent</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataOrders && dataOrders?.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{item.po_number}</th>
                        <td>{item.school_name}</td>
                        <td>{item.teacher_name}</td>
                        <td>{item.created_date}</td>
                        <td>
                          { loadings[item.id] && loadings[item.id][0] ?
                            <div className="d-flex" style={{marginLeft: "-3px"}}>
                              <MoonLoader loading={loadings[item.id][0]} size={24} color="#E37D2B"/>
                            </div> :
                            <>
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id={`isPaid-${item.id}`}
                                  type="checkbox"
                                  checked={item.is_paid_by_admin}
                                  onChange={() => handleCheckPaid(item.id, !item.is_paid_by_admin)}
                                />
                                <label htmlFor={`isPaid-${item.id}`} className="custom-control-label"/>
                              </div>
                            </>
                          }
                        </td>
                        <td>
                          { loadings[item.id] && loadings[item.id][1] ?
                            <div className="d-flex" style={{marginLeft: "-3px"}}>
                              <MoonLoader loading={loadings[item.id][1]} size={24} color="#E37D2B"/>
                            </div> :
                            <>
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id={`isSent-${item.id}`}
                                  type="checkbox"
                                  checked={item.is_sent_by_admin}
                                  onChange={() => handleCheckSent(item.id, !item.is_sent_by_admin)}
                                />
                                <label htmlFor={`isSent-${item.id}`} className="custom-control-label"/>
                              </div>
                            </>
                          }
                        </td>
                        <td>{item.total_cost}</td>
                        <td>
                          <a
                            className="btn btn-outline-primary btn-icon rounded-circle"
                            href={item.download_url}
                            download
                          >
                            <i className="ri-xl ri-download-2-fill"></i>
                          </a>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {!isGettingPrintOrder && pagination.total_pages > 1 && (
            <nav className="mt-auto" aria-label="...">
              <ul className="pagination justify-content-center">
                <Pagination
                  defaultCurrent={1}
                  pageSize={pagination.per_page}
                  total={pagination.total_objects}
                  defaultPageSize={3}
                  onChange={page => {
                    onChangePage(page)
                  }}
                  current={pagination.current_page}
                />
              </ul>
            </nav>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default AdminOrders
