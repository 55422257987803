import React, { useState, useEffect } from 'react';
import Layout from "components/common/layout"
import { GET_DETAIL_LESSON_PLAN, DOWNLOAD_LESSON_FILE_API, callApi } from "lib/api-server"
import { useParams } from "@reach/router"
import { Link } from 'gatsby';
import { DiscussionEmbed } from "disqus-react"

import "assets/scss/pages/lesson-plan.scss"

const LessonPlanDetail = (props) => {
  const lessonPath = props.admin ? '/admin/resources' : '/resources'

  const [lessonPlan, setLessonPlan] = useState({})
  const { id } = useParams()

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: id }
  }

  useEffect(() => {
    getLessonPlan()
  },[])

  const resultReplace = (lesson) => {
    let result = lesson?.replaceAll("<oembed url", `<iframe title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width="90%" height= "315px" src`);
    result = result?.replaceAll("></oembed>", ` allowfullscreen=""></iframe>`);
    result = result?.replaceAll("youtu.be/", "www.youtube.com/embed/");

    return result
  }

  const handleDownloadFile = (id_file) => {
    const urlPath = DOWNLOAD_LESSON_FILE_API.replace(':id', id_file)

    callApi('PUT', urlPath)
    .then(res => {
      getLessonPlan()
    })
    .catch(err => console.log(err.response))
  }

  const getLessonPlan =() => {
    const url = GET_DETAIL_LESSON_PLAN.replace(':id', id)
    callApi('GET', url).then(res => {
      setLessonPlan(res)
    }).catch(err => console.log(err.response))
  }


  return (
    <Layout title="Lesson Plan">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title">
          <span className="page-title__icon bg-white">
            <i className="ri-xl ri-book-open-line text-warning"></i>
          </span>
          <h1 className="ml-3 mb-0">{lessonPlan.title}</h1>
          <div className="btn-wrapper ml-auto">
            <Link to={lessonPath} className="page-title__link">
              <span className="btn btn-icon btn-outline-warning rounded-circle">
                <i className="ri-xl ri-arrow-left-line"></i>
              </span>Back to Resources
            </Link>

            {props.admin && (
              <Link
                className="btn btn-primary ml-3"
                to={`/admin/resources/${lessonPlan.id}/edit`}
              >
                EDIT
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col col-content">
            <div className="card card-content-wrapper">
              <div className="card-header border-0 pb-0">
                <h2 className="text-warning mb-0">{lessonPlan.title}
                </h2>
              </div>
              <div className="card-body">
                <article>
                  <td style={{width:"650px"}} dangerouslySetInnerHTML={{__html: resultReplace(lessonPlan.content)}}/>
                </article>

                <DiscussionEmbed {...disqusConfig} />
              </div>
            </div>
          </div>
          <div className="col col-aside">
            <div className="card card-content-wrapper">
              <div className="card-header">
                <h2 className="text-warning mb-0">Downloads</h2>
              </div>
              <div className="card-body">
                <ul className="list-unstyled list-icon" id="uploaded-files">
                  {
                    lessonPlan.lession_files?.map((item, index) => (
                      <li className="list-icon__item uploaded-file" key={index}>
                        <a onClick={() => handleDownloadFile(item.id)} className="list-icon__icon text-primary"
                          href={item.url}
                          target='_blank'
                          download={item.name}
                        >
                          <i className="ri-file-download-fill uploaded__icon"></i>
                        </a>
                        <a
                          className="small mr-auto"
                          href={item.url}
                          target='_blank'
                          download={item.name}
                          onClick={() => handleDownloadFile(item.id)}
                          >{item.name}
                          <small className="list-icon__note" >
                            {item.downloads} downloads</small>
                        </a>
                      </li>
                  ))}
                </ul>
              </div>
              <div className="card-footer">
                <h3 className="fz-18 mt-3">Related Tags</h3>
                <div className="tags">
                  {
                    lessonPlan.tag_list?.map((item, index) =>(
                      <a className="badge badge-warning badge-pill badge-md" key={index}>{item}</a>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default LessonPlanDetail;