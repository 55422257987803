import React, { useEffect, useState, useCallback , useMemo, useRef} from "react"
import Layout from "components/common/layout"
import { useLocation, useParams, navigate } from "@reach/router"
import { callApi, GET_DETAIL_PROJECT_API, GET_DETAIl_FILE, UPLOAD_FILE, APPROVE_FILE } from "lib/api-server"
import { useDropzone } from 'react-dropzone'
import { uploadFile } from "services/upload"
import axios from "axios"
import { toast } from 'react-hot-toast'
import { ProgressBar } from 'react-bootstrap'
import { DIMENTION_LIMIT } from "lib/scope"
import { Link } from "gatsby"
import ModalPreview from 'components/student/modalPreview/index'

const ReviewFile = () => {
  const location = useLocation()
  const { projectID, fileID } = useParams()
  const [project, setProject] = useState()
  const [file, setFile] = useState({})

  const [fileName, setFileName] = useState("")
  const [cancelTokenSource, setCancelTokenSource] = useState()
  const [loadedPercent, setLoadedPercent] =useState()
  const ProgressBarInstance = useMemo(()=>  <ProgressBar now={loadedPercent} label={`${loadedPercent}%`}/>, [loadedPercent])

  const noteInputRef = useRef(null)

  useEffect(() => {
    if(location.state?.project)
    {
      setProject(location.state.project)
    }
    else{
      callApi('GET', GET_DETAIL_PROJECT_API.replace(':id', projectID)).then(res => {
        setProject(res)
      })
    }

    callApi('GET', GET_DETAIl_FILE.replace(':id', fileID)).then(res => {
      setFileName(res?.file_name)
      setFile(res)
    })

  },[])

  const checkFile = useCallback((fileUrl) => {
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource)

    const formData = new FormData()
    formData.append("file_url", fileUrl)

    callApi("POST", UPLOAD_FILE.replace(":id", project.id), formData, {cancelToken: newCancelTokenSource.token})
  }, [project])

  const onDrop = useCallback(acceptedFiles => {
    setFileName(acceptedFiles[0].name)

    if(acceptedFiles.length > 0)
    {
      if(cancelTokenSource)
      {
        cancelTokenSource.cancel()
      }

      uploadFile(acceptedFiles[0]).then((res) => {
        res.on("httpUploadProgress", (progress) => {
          setLoadedPercent(() => Math.round(progress.loaded / progress.total * 100))
        })
        .send(function(err, data) {
          if (err) {
            toast.error(`Something went wrong ${err.code} ${err.message}`, {duration: 4000})
          } else {
            const urlFIle= `https://${process.env.AWS_BUCKET_NAME}.s3.${process.env.AWS_REGION}.amazonaws.com/fileUpload/${acceptedFiles[0].name}`
            checkFile(urlFIle)
          }
        })
      })
    } else {
      toast.error("The type file must be STL or OBJ", { id: "dropFileError" })
    }
  }, [cancelTokenSource, checkFile])

  const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false, noClick: true, accept: ".obj, .stl"})

  const handleComfirmApprove = (isApproved) => {
    if(isApproved) {
      callApi("PUT", APPROVE_FILE.replace(":id", fileID), {is_approved: true, note: noteInputRef.current.value}).then(res => {
        if(res.isApproved)
        {
          toast.success("File is updated")
        }
        navigate(-1)
      })
    } else {
      navigate(-1)
    }
  }

  const modal = useMemo(() => (
    <div className="custom-canvas-preview" id="custom_canvas_preview">
      <ModalPreview url={file?.url} typeFile={file?.file_name?.slice(-3).toLowerCase()}/>
    </div>
  ), [file?.url, file?.file_name])

  return (
    <>
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <Layout title="Review File">
        <main className="container" role="main">
          <div className="d-flex align-items-center page-title text-dark-blue">
            <span className="page-title__icon">
              <i className="ri-5x ri-checkbox-blank-circle-fill"></i>
            </span>
            <h1 className="ml-3 mb-0">{project?.name}</h1>
            <div className="btn-wrapper ml-auto">
              <Link className="page-title__link" to={`/projects/${projectID}`}>
                <span className="btn btn-icon btn-outline-warning rounded-circle bg-white">
                  <i className="ri-xl ri-arrow-left-line"></i>
                </span>
                Back to Project
              </Link>
            </div>
          </div>
          <div className="row row-summary">
            <div className="col-12 col-summary-left"></div>
            <div className="col-12 col-summary-right"></div>
          </div>
          <div className="card card-content-wrapper card-project card-file-info">
            <div className="card-header border-0 pb-0">
              <h2 className="text-warning mb-0">Review of {fileName}</h2>
              <Link
                to={`${file?.url}`}
                className="btn btn-link"
                role="button"
                aria-disabled="false"
                download={file.file_name}
              >
                <i className="ri-xl ri-file-download-fill mr-1"></i> Download File
              </Link>
            </div>
            <div className="card-body">
              <div className="row px-5">
                <div className="col-12 col-left">
                  {file?.url ? modal : <img className="img-fluid" src="https://via.placeholder.com/445?text=MODEL+PREVIEW"/>}
                  <span className='preview-note'>* Use your scroll wheel to zoom in and out</span>
                </div>
                <div className="col-12 col-right">
                  <div className="d-flex justify-content-start flex-column">
                  {loadedPercent ? ProgressBarInstance : (
                    <>
                      <h3>Project Constraints Checklist</h3>
                      <hr />
                      <ul className="list-unstyled">
                        <li className="project-constraint">
                        {file?.checks?.cost_per_print ?  <i className="ri-xl ri-check-line"/> : <i className="tooltip-icon ri-xl ri-close-line text-danger"/>}
                        <span className="project-constraint__title">Cost per Model Limit:</span>
                          <strong>{file?.project?.cost_per_print ? file?.project.cost_per_print : 0}</strong>
                        </li>
                        <li className="project-constraint">
                        {file?.checks?.dimension_limit ?  <i className="ri-xl ri-check-line"/> : <i className="tooltip-icon ri-xl ri-close-line text-danger"/>}
                          <span className="project-constraint__title">
                            Dimension Limits:
                          </span>
                          <strong>{file?.project?.dimension_limit} (W {DIMENTION_LIMIT[file?.project?.dimension_limit]?.width}, L {DIMENTION_LIMIT[file?.project?.dimension_limit]?.length}, H {DIMENTION_LIMIT[file?.project?.dimension_limit]?.height})</strong>
                        </li>
                        <li className="project-constraint">
                          <span className="project-constraint__title">
                            Student comments:
                          </span>
                          <strong>{file?.note}</strong>
                        </li>
                        <li className="project-constraint">
                          <span className="project-constraint__title">Quantity:</span>
                          <strong>
                            <input id="number" type="number" step="1" min="0" value={file.quantity} disabled/>
                          </strong>
                        </li>
                      </ul>
                    </>
                  )}
                  </div>
                </div>
              </div>
              <div className="row px-5">
                <div className="col-12 col-left">
                  {/* <div className="dragdrop" id="dragdrop" {...getRootProps()}>
                    <div className="dragdrop__wrapper dragdrop--empty d-flex align-items-center">
                      <input value={fileUpload}  accept=".obj, .stl" id="ip-file-upload" {...getInputProps({disabled: true})} />
                      <i className="ri-4x ri-drag-drop-line dragdrop__icon"></i>
                      <div className="dragdrop__cta">
                        <p>
                          To reupload, drop a 3D model file into this box or
                          click the button below.
                        </p>
                        <label htmlFor="ip-file-upload" className="btn btn-white dragdrop__cta">
                          Upload File
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-12 col-right">
                  <textarea
                    ref={noteInputRef}
                    maxLength="100"
                    className="form-control"
                    id="file-info"
                    placeholder={"Add personal notes (character limit 100)"}
                    rows="6"
                    defaultValue={file.teacher_note}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-group text-right">
            <button className="btn btn-outline-primary" onClick={() => handleComfirmApprove(false)} >
              Back
            </button>
            <button className="btn btn-primary" onClick={() => handleComfirmApprove(true)}>
              Save
            </button>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default ReviewFile
