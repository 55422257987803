import React from "react"
import LessonPlanDetail from "components/teacher/lesson_plans/Detail"

const LessonPlan = props => {
  return (
    <LessonPlanDetail admin={true}/>
  )
}

export default LessonPlan
