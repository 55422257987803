import React from 'react'
import Layout from "components/common/layout"

const SubmitFile = (props) => {
  return (
    <Layout>
      <div className="background--white-circle" id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        {props.children}
      </main>
    </Layout>
  )
}

export default SubmitFile
