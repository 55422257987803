import React from "react"
import Layout from "components/common/layout"
import LoginForm from "components/teacher/forms/login"

export default function Login() {
  return (
    <Layout title="Login for Teacher">
      <div className="container-fluid" id="background">
        <div className="row h-100">
          <div className="col-md-8 bg-blue-dark h-100">1</div>
          <div className="col-md-4 bg-yellow-dark h-100">2</div>
        </div>
      </div>

      <main className="container" role="main">
        <div className="row align-items-md-center">
          <div className="col-md-4 col-lg-5 text-white">
            <h1>Welcome Back</h1>
            <p></p>
          </div>
          <div className="col-md-6">
            <div className="card text-center card-signin">
              <div className="card-body">
                <h2 className="h1">Sign In for Teacher </h2>
                <p className="my-3 my-lg-5">
                  Please sign in using your email address. Sign up if you do not
                  have an account.
                </p>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
