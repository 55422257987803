import React, { useState, useEffect } from "react"
import { NOTIFICATION_API, NOTIFICATIONS_API, callApi } from "lib/api-server"

import { getUser } from "../../services/auth"
import { Link } from "gatsby"

import Layout from "../common/layout"
import Pagination from 'rc-pagination';
import BeatLoader from "react-spinners/BeatLoader";

// css ##
import 'rc-pagination/assets/index.css';
import '../../assets/scss/pages/notification.scss'

const Notifications = props => {
  const [loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState([])
  const [pagination, setPagination] = useState({
    per_page: 20,
    total_objects: 0,
    total_pages: 1,
    current_page: 1
  });

  const onChangePage = (page) => {
    pagination.current_page = page
    setPagination({...pagination})

    getNotifications()
  }

  const handleDismiss = notifyID => {
    callApi("PUT", `${NOTIFICATION_API}/${notifyID}/dismiss`)
      .then(res => {
        getNotifications()
      })
      .catch(err => console.log(err))
  }

  const getNotifications =() => {
    setLoading(true)
    callApi("GET", `${NOTIFICATIONS_API}?page=${pagination.current_page}&per=20`)
      .then(data => {
        setLoading(false)
        setNotifications(data.data)
        setPagination(data.meta.pagination)
      })
      .catch(err => { 
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => { getNotifications() }, [])

  return (
    <>
      <Layout title="Notifications">
        <div id="background">
          <div className="circle circle--top"></div>
          <div className="circle circle--bottom"></div>
        </div>
        <main className="container" role="main">
          <div className="d-flex align-items-center page-title">
            <span className="page-title__icon bg-white">
              <i className="ri-xl ri-user-fill text-warning"></i>
            </span>
            <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
          </div>
          <div className="card card-content-wrapper">
            <div className="card-header">
              <h2 className="text-warning mb-0">Notifications</h2>
            </div>
            <div className="card-body pt-0">
              <BeatLoader loading={loading} size={10} color="#E37D2B"/>
              {!loading && notifications.length == 0 && (
                <label>You don't have any notification</label>
              )}

              <ul className="list-group">
                {notifications?.map((notification, index) => (
                  <li className="list-group-item notification" key={index}>
                    <p className="notification__content">
                      {notification.message}
                      &nbsp;
                      <Link
                        className="underline"
                        to={`/projects/${notification.project_code}`}
                      >
                        {notification.project_code}.
                      </Link>
                    </p>
                    <small className="notification__at">
                      {notification.created_at}
                    </small>
                    <a
                      className="ml-auto btn btn-icon btn-link"
                      onClick={() => handleDismiss(notification.id)}
                    >
                      <i className="ri-lg ri-close-line"></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {pagination.total_pages > 1 && (
              <nav className="mt-auto" aria-label="...">
                <ul className="pagination justify-content-center">
                  <Pagination
                    pageSize={pagination.per_page}
                    total={pagination.total_objects}
                    onChange={(page) => {
                      onChangePage(page)
                    }}
                    current={pagination.current_page}
                  />
                </ul>
              </nav>
            )}
          </div>
        </main>
      </Layout>
    </>
  )
}
export default Notifications
