import React , { Suspense, useEffect, useRef } from 'react'
import * as THREE from "three"
import { Canvas, useFrame, render, events, unmountComponentAtNode} from "@react-three/fiber"
import  { OrbitControls, useProgress, Html , Center, useHelper, } from '@react-three/drei'
import Model from './model'
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import { FaMinus } from "@react-icons/all-files/fa/FaMinus"
import { useDispatch, useSelector } from "react-redux"
import { changeZoom } from "actions"

import {
  PointLightHelper,
} from "three";

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

const Zoom = ({ zoom }) => {

  return useFrame((state) => {
    state.camera.zoom = THREE.MathUtils.lerp(state.camera.zoom, zoom * 4, 0.1)
    state.camera.updateProjectionMatrix()
  })
}

const Lights = () => {
  const refLight1 = useRef();
  const refLight2 = useRef();
  useHelper(refLight1, PointLightHelper, 5);
  useHelper(refLight2, PointLightHelper, 5);

  return (
    <>
      <ambientLight intensity={0.1} />
      <pointLight ref={refLight1} position={[10, -10, -20]} intensity={0.3} />
      <pointLight ref={refLight2} position={[0, 10, 5]} intensity={0.3} />
      <spotLight intensity={1} position={[0, 1000, 0]} />
    </>
  );
};

const ModalPreview = (props) => {
  const zoom = useSelector(state => state.zoom)
  const dispatch = useDispatch()

  const handleZoom = (isZoomIn) => {
    if(isZoomIn && zoom < 1)
    {
      dispatch(changeZoom(parseFloat(parseFloat(zoom + 0.1).toFixed(1))))
    }
    else if( !isZoomIn && zoom > 0.2)
    {
      dispatch(changeZoom(parseFloat(parseFloat(zoom - 0.1).toFixed(1))))
    }
  }

  useEffect(() => {
    const parent = document.getElementById("custom_canvas_preview")
    render(<mesh />, document.querySelector('canvas'), {
      events,
      size: { width: parent.offsetWidth, height: parent.offsetWidth },
    })

    const renderCanvasResponsive = () => {
      const parent = document.getElementById("custom_canvas_preview")
      parent.style.height = parent.offsetWidth
      render(<mesh />, document.querySelector('canvas'), {
        events,
        size: { width: parent.offsetWidth, height: parent.offsetWidth },
      })
    }

    window.addEventListener('resize', renderCanvasResponsive)

    window.dispatchEvent(new Event('resize'))

    return () => {
      window.removeEventListener('resize', renderCanvasResponsive)
      unmountComponentAtNode(document.querySelector('canvas'))
    }
  }, [])

  useEffect(() => {
    dispatch(changeZoom(0.2))
  },[props.url])

  return (
    <>
    <div className="zoom-button-group">
      <button className="zoom-btn zoom-btn-top" disabled={zoom >= 1} onClick={() => handleZoom(true)}><FaPlus/></button>
      <button className="zoom-btn zoom-btn-bot" disabled={zoom <= 0.2} onClick={() => handleZoom(false)} ><FaMinus/></button>
    </div>
    <Canvas id="model_preview" >
      <color attach="background" args={["white"]} />
      {/* <pointLight position={[10, 10, 10]} /> */}
      <Lights/>
      <OrbitControls/>
      <Suspense fallback={<Loader/>}>
        <Center>
          <Model url={props.url} typeFile={props.typeFile}/>
        </Center>
      </Suspense>
      <Zoom zoom={zoom}/>
    </Canvas>
    </>
  )
}

export default ModalPreview