import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { updateInfoUploadFileInfo } from "actions"
import { navigate } from "gatsby"
import ReactTooltip from "react-tooltip"

const SubmitFileSecondStep = () => {
  const { info } = useSelector(state => state.fileUploadInfo)
  const dispatch = useDispatch()
  const onChangeValue = (event) => {
    dispatch(updateInfoUploadFileInfo({...info, [event.target.name]: event.target.value.toUpperCase()}))
  }
  useEffect(() => {
    ReactTooltip.rebuild();
  },[])

  const [errors, setErrors] = useState({
    first_name_errors: "",
    last_name_errors: ""
  })

  const handleChangeNextPage = () => {
    if(!info?.first_name  || !info?.last_name)
    {
      if(!info?.first_name)
      {
        setErrors(errors => {
          return {...errors, first_name_errors: { message: "First name is required" } }
        })
      }
      if(!info?.last_name)
      {
        setErrors(errors => {
          return {...errors, last_name_errors: { message: "Last name is required" } }
        })
      }
      return
    }
    navigate("/upload-file/submit-file/submit-file-final-step")
  }

  return (
    <>
      <h1 className="text-center">Submit File</h1>
      <ul className="nav nav-progress">
        <li className="nav-item">
          <a className="nav-link">REVIEW</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active">FORM</a>
        </li>
        <li className="nav-item">
          <a className="nav-link">SUBMIT</a>
        </li>
      </ul>
      <div className="card card-submit">
        <div className="card-body d-flex">
          <div className="card-left">
            <h3 className="card-title">Submission Form</h3>
            <ul className="list-group list-group-submit">
              <li className="list-group-item">File Name:<span className="ml-auto">{info?.file_name}</span>
              </li>
            </ul>
            <form>
              <div className="form-group">
                <label htmlFor="f-name">First Name Initial
                  <span className="tooltip-wrapper ml-1" data-title="First name just have one letter only">
                    <i className="tooltip-icon ri-question-fill" data-tip="First name just have one letter only"></i>
                  </span>
                </label>
                <input className="form-control text-uppercase" type="text" id="f-name" maxLength="1" aria-describedby="" value={info?.first_name} name="first_name" onChange={onChangeValue}/>
                {errors && errors["first_name_errors"] && (
                  <label className="text-danger mt-2 mb-0">
                    {errors["first_name_errors"].message}
                  </label>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="l-name">Last Name Initial
                  <span className="tooltip-wrapper ml-1" data-title="Last name just have one letter only">
                    <i className="tooltip-icon ri-question-fill" data-tip="Last name just have one letter only"></i>
                  </span>
                </label>
                <input className="form-control text-uppercase" type="text" id="l-name" maxLength="1" aria-describedby="" value={info?.last_name} name="last_name" onChange={onChangeValue}/>
                {errors && errors["last_name_errors"] && (
                  <label className="text-danger mt-2 mb-0">
                    {errors["last_name_errors"].message}
                  </label>
                )}
              </div>
            </form>
          </div>
          <div className="card-right">
            <a className="btn-next" onClick={() => handleChangeNextPage()}>NEXT<i className="ri-xl ri-arrow-right-line"></i>
            </a>
            <a className="btn-back" onClick={() => navigate(-1)}>BACK<i className="ri-xl ri-arrow-left-line"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubmitFileSecondStep
