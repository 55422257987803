import React from "react"
import { Router } from "@reach/router"

// pages ##
import Login from "components/teacher/SignIn"
import Signup from "components/teacher/SignUp"
import Profile from "components/teacher/Profile"

import ForgotPassword from "components/teacher/ForgotPassword"
import ChangePassword from "components/teacher/ResetPassword"
import ReviewFile from "components/teacher/projects/file/ReviewFile"
import Dashboard from "components/teacher/dashboard"
import Notifications from "components/teacher/Notifications"

import NewProject from "components/teacher/projects/NewProject"
import Projects from "components/teacher/projects/ProjectList"
import ProjectDetail from "components/teacher/projects/ProjectDetail"
import PrintOrderDetail from "components/teacher/projects/PrintOrderDetail"

import LessonPlans from "components/teacher/lesson_plans"
import LessonPlanDetail from "components/teacher/lesson_plans/Detail"
import Feedback from "components/teacher/feedback"

// Students
import UploadFile from "components/student/upload_file"
import SubmitFile from "components/student/submit_file"
import SubmitFileFirstStep from "components/student/submit_file/SubmitFileFirstStep"
import SubmitFileSecondStep from "components/student/submit_file/SubmitFileSecondStep"
import SubmitFileFinalStep from "components/student/submit_file/SubmitFileFinalStep"
import SuccessUploadFile from "components/student/submit_file/SuccessUploadFile"
import UploadFileStep from "components/student/upload_file/UploadFileStep"
import UploadAnalysis from "components/student/upload_file/UploadAnalysis"

// Admin
import AdminRoute from "components/AdminRoute"
import AdminLogin from "components/admin/login"
import AdminSetting from "components/admin/setting"
import AdminLessonPlans from "components/admin/lesson_plans/index"
import AdminLessonPlanDetail from "components/admin/lesson_plans/show"
import AdminLessonPlanNew from "components/admin/lesson_plans/new"
import AdminLessonPlanEdit from "components/admin/lesson_plans/edit"
import AdminOrders from "components/admin/print_orders"
import AdminTeacherList from "components/admin/teacher"
import AdminTeacherNew from "components/admin/teacher/New"
import AdminTeacherDetail from "components/admin/teacher/Show"

import PrivateRoute from "components/PrivateRoute"
import Default from "components/home"
import NotFound from "components/NotFound"

// pages
import TermsAndConditions from "components/pages/TermsAndConditions"
import PrivacyPolicy from "components/pages/PrivacyPolicy"
import About from "components/pages/About"
import ContactUs from "components/pages/ContactUs"
import Pricing from "components/pages/Pricing"

// Toast
import { Toaster } from "react-hot-toast"
import ReactTooltip from "react-tooltip"

// Create store redux
import { Provider } from "react-redux"
import rootReducer from "src/redux/reducers"
import initStore from "src/redux/configureStore"

const store = initStore(rootReducer)

const App = () => (
  <Provider store={store} >
    <Toaster position="top-center" reverseOrder={false} />
    <ReactTooltip place='right' className='tooltip-inner' multiline={true}/>

    <Router>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/notifications" component={Notifications}/>
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/resources" component={LessonPlans} />
      <PrivateRoute path="/resources/:id" component={LessonPlanDetail} />
      <PrivateRoute path="/projects" component={Projects} />
      <PrivateRoute path="/new-project" component={NewProject} />
      <PrivateRoute path="/projects/:id" component={ProjectDetail} />
      <PrivateRoute path="projects/:projectID/files/:fileID" component={ReviewFile}/>
      <PrivateRoute path="/projects/:projectId/print-orders/:printOrderId" component={PrintOrderDetail} />
      <PrivateRoute path="/feedback" component={Feedback} />

      {/* Admin */}
      <AdminLogin path="/admin"/>
      <AdminRoute path="/admin/print-orders" component={AdminOrders} />
      <AdminRoute path="/admin/resources" component={AdminLessonPlans} />
      <AdminRoute path="/admin/resources/:id" component={AdminLessonPlanDetail} />
      <AdminRoute path="/admin/resources/new" component={AdminLessonPlanNew} />
      <AdminRoute path="/admin/resources/:id/edit" component={AdminLessonPlanEdit} />
      <AdminRoute path="/admin/setting" component={AdminSetting} />
      <AdminRoute path="/admin/teachers" component={AdminTeacherList} />
      <AdminRoute path="/admin/teachers/new" component={AdminTeacherNew} />
      <AdminRoute path="/admin/teachers/:id" component={AdminTeacherDetail} />

      {/* Upload file, submit file*/}
      <UploadFile path="/upload-file" >
        <UploadFileStep path="/" />
        <UploadAnalysis path="/upload-analysis" />
        <SubmitFile path="/submit-file">
          <SubmitFileFirstStep path="/" />
          <SubmitFileSecondStep path="/submit-file-second-step" />
          <SubmitFileFinalStep path="/submit-file-final-step" />
        </SubmitFile>
        <SuccessUploadFile path="/succes-upload-file" />
      </UploadFile>

      <ForgotPassword path="/forgot-password"/>
      <ChangePassword path="/reset-password/:token"/>
      <Signup path="/signup" />
      <Login path="/login" />
      <Default path="/" />

      <TermsAndConditions path="/terms-and-conditions"/>
      <PrivacyPolicy path="/privacy-policy"/>
      <About path="/about"/>
      <ContactUs path="/contact"/>
      <Pricing path="/pricing"/>

      <NotFound default />
    </Router>
  </Provider>
)
export default App
