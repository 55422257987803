import React, { useState } from 'react'
import ReactCodeInput from "react-code-input"
import { callApi, CHECK_PROJECT_CODE } from 'lib/api-server'
import { navigate } from "gatsby"
import { useDispatch } from 'react-redux'

const CheckCodeProjectModal = (props) => {
  const { setIsShowModalUploadFile } = props
  const [code, setCode] = useState("")
  const [isLoading, setIsloading] = useState(false)
  const [checkStatus, setCheckStatus] = useState("checking")

  const dispatch = useDispatch()

  const handleCheckCode = () =>{
    if(isLoading)
    {
      return
    }

    setIsloading(true)

    callApi("GET", CHECK_PROJECT_CODE, { project_code: code}).then(res => {
      if(res?.id)
      {
        navigate("/upload-file", { state: { project: res}, replace: false})
      }
    }).catch(err => {
      const response = err.response.data
      setCheckStatus("failed")
      setIsloading(false)
    })
  }

  const Checking = (
    <>
      <h1 className="modal-title">Enter your project code</h1>
        <ReactCodeInput
          value={code}
          onChange={value => setCode(value)}
          className="form-row justify-content-center"
          type="text"
          fields={6}
        />
      <div className="btn-group">
        <a className="btn btn-outline-primary" onClick={() => setIsShowModalUploadFile(false)} >CANCEL</a>
        <a className="btn btn-primary" onClick={handleCheckCode}>
          {
            isLoading ?
              <span className="spinner-border"  style={{ width: "1rem", height: "1rem" }} role="status" />
              : "SUBMIT"
          }
        </a>
      </div>
    </>
  )

  const CheckFailed = (
    <>
      <h1 className="modal-title">Check your project code!</h1>
      <p className="mt-4">The project code you entered is incorrect or the
        project has been closed.
        <br/>Please check your project code with the teacher.
      </p>
      <div className="btn-group">
        <a className="btn btn-primary" onClick={() => setIsShowModalUploadFile(false)}>OK</a>
      </div>
    </>
  )

  return (
    <>
      <div className="modal show" tabIndex="-1" role="dialog" style={{display:'block'}}>
        <div className="modal-dialog modal-dialog-centered modal-noti" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {
                checkStatus === "checking" ? Checking : CheckFailed
              }
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop"></div>
    </>
  )
}

export default CheckCodeProjectModal
