import React, { useState, useEffect } from "react"
import { VALID_EMAIL } from "lib/validate"
import { REGISTER_API, GET_SCHOOLS_API, callApi } from "lib/api-server"
import TeacherProjectForm from "components/common/form/TeacherProjectForm"

const Register = props => {
  const { setIsShowModal, sendGetNewVerifyCodeAPI } = props
  const [isAgreeTerm, setIsAgreeTerm] = useState(false)
  const [messageErrors, setMessageErrors] = useState({})
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    repeat_password: "",
    school_address: ""
  })
  const [schoolList, setSchoolList] = useState([])
  const [selectedSchool, setSelectedSchool] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isShow, setIsShow] = useState({
    isShowPassword: false,
    isShowRepeatPassword: false,
  })

  useEffect(() => {
    callApi("GET", GET_SCHOOLS_API)
      .then(data => {
        setSchoolList(data)
      })
  }, [])

  useEffect(() => {
    if (selectedSchool && schoolList && schoolList.length > 0) {
      setData({
        ...data,
        school_address: schoolList.find(item => item.id === selectedSchool)
          ?.address,
      })
    }
  }, [selectedSchool, schoolList])

  useEffect(() => {
    if (data.email !== "") {
      if (!VALID_EMAIL.test(data.email) && !messageErrors?.emailMessage) {
        setMessageErrors({
          ...messageErrors,
          emailMessage: "Invalid email format",
        })
      } else if (VALID_EMAIL.test(data.email) && messageErrors.emailMessage) {
        delete messageErrors.emailMessage
        setMessageErrors({...messageErrors})
      }
    }
  }, [data.email])

  const handleSubmit = e => {
    e.preventDefault()

    if (isLoading) {
      return
    }

    let body = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      school_id: selectedSchool,
      school_address: data.school_address,
      password: data.password,
      password_confirmation: data.repeat_password,
    }

    setIsLoading(true)

    callApi("POST", REGISTER_API, body)
      .then(res => {
        setIsLoading(false)
        setIsShowModal(true)

        localStorage.setItem(
          "unverified_user",
          JSON.stringify({ token: res.token })
        )
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response) {
          const fieldErrors = err.response.data.errors

          if(fieldErrors) {
            fieldErrors.forEach(item => {
              switch (item.field) {
                case "first_name":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      firstNameMessage: item.message,
                    }
                  })
                  break
                case "last_name":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      lastNameMessage: item.message,
                    }
                  })
                  break
                case "phone":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      contactMessage: item.message,
                    }
                  })
                  break
                case "school_address":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      schoolAddressMessage: item.message,
                    }
                  })
                  break
                case "email":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      emailMessage: item.message,
                    }
                  })
                  break
                case "password":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      passwordMessage: item.message,
                    }
                  })
                  break
                case "password_confirmation":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      repeatPasswordMessage: item.message,
                    }
                  })
                case "school_id":
                  setMessageErrors(messageErrors =>{
                    return {
                      ...messageErrors,
                      schoolIDMessage: item.message,
                    }
                  })
              }
            })
          }
        }
      })
  }

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TeacherProjectForm
          data={data}
          setData={setData}
          messageErrors={messageErrors}
          isShow={isShow}
          setIsShow={setIsShow}
          schoolList={schoolList}
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
          isCreate={true}
          />
        <div className="text-center my-5">
          <div className="custom-control custom-checkbox checkbox-fill-warning mb-3">
            <input
              className="custom-control-input"
              id="tc-agree"
              type="checkbox"
              checked={isAgreeTerm}
              onChange={() => setIsAgreeTerm(isAgreeTerm => !isAgreeTerm)}
            />
            <label className="custom-control-label" htmlFor="tc-agree">
              By creating an account you agree to our&nbsp;
              <a className="font-weight-bold text-body">
                Terms of Service and Privacy Policy
              </a>
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              // data.first_name === "" ||
              // data.last_name === "" ||
              // data.phone === "" ||
              // data.password === "" ||
              // data.repeat_password === "" ||
              // !selectedSchool ||
              // data.school_address === "" ||
              // data.email === "" ||
              messageErrors.emailMessage ||
              !isAgreeTerm
            }
          >
            {!isLoading ? (
              ` NEXT`
            ) : (
              <span
                className="spinner-border"
                style={{ width: "1rem", height: "1rem" }}
                role="status"
              ></span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default Register
