import React, {useEffect, useState} from "react"
import Logo from "assets/img/logo.svg"
import { Link } from "gatsby"

import { isLoggedIn, getUser } from "services/auth"
import { useLocation } from "@reach/router"

export default function Header() {
  const location = useLocation()
  const [user, setUser] = useState({})

  useEffect(() => {
    const userResult = getUser()
    if(userResult)
    {
      setUser(userResult)
    }
  },[])

  const NavLink = props => {
    if (location.pathname == props.path) {
      return (
        <li className="nav-item active">
          <Link className="nav-link link-icon" to={props.path}>
            <span>{props.title}</span>
            <span className="sr-only">current</span>
          </Link>
        </li>
      )
    } else {
      return (
        <li className="nav-item">
          <Link className="nav-link link-icon" to={props.path}>
            <span>{props.title}</span>
          </Link>
        </li>
      )
    }
  }

  const LogedMenu = props => {
    if(props.admin) {
      return (
        <menu className="navbar-nav ml-sm-auto navbar-right">
          <NavLink path='/admin/print-orders' title="Print Orders"/>
          <NavLink path='/admin/resources' title="Resources"/>
          <NavLink path='/admin/teachers' title="Teachers"/>
          <NavLink path='/admin/setting' title="Setting"/>

          <li className="nav-item">
            <Link className="nav-link link-icon" to="/notifications">
              <i className="ri-xl ri-notification-2-fill"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link link-icon" to="/profile">
              <i className="ri-xl ri-user-3-fill"></i>
            </Link>
          </li>
        </menu>
      )
    } else {
      return (
        <menu className="navbar-nav ml-sm-auto navbar-right">
          <NavLink path='/dashboard' title="Dashboard"/>
          <NavLink path='/projects' title="Projects"/>
          <NavLink path='/resources' title="Resources"/>
          <NavLink path='/feedback' title="Feedback"/>

          <li className="nav-item">
            <Link className="nav-link link-icon" to="/notifications">
              <i className="ri-xl ri-notification-2-fill"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link link-icon" to="/profile">
              <i className="ri-xl ri-user-3-fill"></i>
            </Link>
          </li>
        </menu>
      )
    }
  }

  return (
    <>
      <header className="container-fluid bg-white fixed-top">
        <nav className="navbar navbar-expand-sm navbar-light">
          <Link className="navbar-brand" to="/">
            <img src={Logo} height="40" alt="Tinker Steps" />
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            {isLoggedIn() ? (
              <LogedMenu admin={getUser().role && getUser().role[0] == 'admin'}/>
            ) : (
              <Link className="btn btn-link font-weight-bold" to="/login">
                TEACHER SIGN IN
              </Link>
            )}
          </div>
        </nav>
      </header>
    </>
  )
}
