import React from "react"
import "assets/scss/style.scss"

import Footer from "./footer"
import Header from "./header"
import Meta from "./meta"

const Layout = props => {
  return (
    <div className={`${props.backgroundColor}`}>
      <Meta title={props.title} description={props.description}/>
      <Header />
      {props.children}
      <Footer footerColor={props.footerColor} />
    </div>
  )
}

Layout.defaultProps = {
  title: "Tinker",
  footerColor: "bg-white",
  backgroundColor: ""
}

export default Layout
