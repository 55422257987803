import React from "react"
import Logo from "assets/img/logo.svg"
import { Link } from "gatsby"

const Footer = props => {
  return (
    <footer className={`container-fluid small ${props.footerColor}`}>
      <div className="row px-3">
        <div className="col-md-3 col-lg-2 mb-3 offset-2">
          <nav className="list-group">
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="/about"
              target="_blank"
            >
              <span>About Us</span>
            </a>
            {/* <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="contact"
              target="_blank"
            >
              <span>Contact</span>
            </a> */}
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="/terms-and-conditions"
              target="_blank"
            >
              <span>Terms &amp; Conditions</span>
            </a>
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="/privacy-policy"
              target="_blank"
            >
              <span>Privacy policy </span>
            </a>
          </nav>
        </div>
        <div className="col-md-3 mb-3">
          <nav className="list-group">
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              target="_blank"
              href="https://www.facebook.com/Tinker-Steps-118583040056979"
            >
              <i className="ri ri-facebook-fill mr-1"></i>
              <span>Facebook</span>
            </a>
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              target="_blank"
              href="https://twitter.com/tinkersteps"
            >
              <i className="ri ri-twitter-fill mr-1"></i>
              <span>Twitter</span>
            </a>
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              target="_blank"
              href="https://www.instagram.com/tinker_steps/"
            >
              <i className="ri ri-instagram-fill mr-1"></i>
              <span>Instagram</span>
            </a>
          </nav>
        </div>
        <div className="col ml-auto mb-3">
          <nav className="list-group">
            {/* <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="https://goo.gl/maps/LKKLuy6WxJ6nbjux6"
              target="_blank"
            >
              <span>497 Albert Rd. Melbourne, VIC 3000</span>
            </a> */}
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="/contact"
              target="_blank"
            >
              <span>Contact</span>
            </a>
            {/* <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="tel:+44345678903"
              target="_blank"
            >
              <span>+44 345 678 903</span>
            </a> */}
            <a
              className="list-group-item list-group-item-action border-0 py-1"
              href="mailto:tinkersteps@gmail.com"
              target="_blank"
            >
              <span>tinkersteps@gmail.com</span>
            </a>
          </nav>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  footerColor: "bg-white",
}

export default Footer
