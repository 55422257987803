import React, { useState, useEffect } from "react"
import Layout from "components/common/layout"
import { navigate, Link } from "gatsby"
import ValidateForm from "components/common/ValidateForm"
import { VALID_EMAIL } from "lib/validate"
import { SEND_REQUEST_FORGOT_PASSWORD_API, callApi } from "lib/api-server"
import { isLoggedIn } from "services/auth"

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    if (email) {
      const isEmailValid = VALID_EMAIL.test(email) || email.length === 0
      if (isEmailValid) {
        setError({})
      } else {
        setError("Invalid email")
      }
    }
  }, [email])

  const handleSendResetPassword = event => {
    event.preventDefault()

    if (isLoading || error === "") {
      return
    }

    setIsLoading(true)

    callApi("POST", SEND_REQUEST_FORGOT_PASSWORD_API, { email: email })
      .then(res => {
        setIsLoading(false)
        setError("")
        setIsShowModal(true)
      })
      .catch(err => {
        const data = err.response.data
        if (data.error) {
          setError(data.error)
        }
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/dashboard")
    }
  }, [])

  return (
    <Layout title="Forgot Password">
      <div className="container-fluid" id="background">
        <div className="row h-100">
          <div className="col-md-8 bg-blue-dark h-100">1</div>
          <div className="col-md-4 bg-yellow-dark h-100">2</div>
        </div>
      </div>
      <main className="container" role="main">
        <div className="row align-items-md-end">
          <div className="col-md-4 col-lg-5">
            <h1>Tinker Steps</h1>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Obcaecati corporis ad soluta eos odio.
            </p>
          </div>
          <div className="col-md-6">
            <div className="card text-center card-signin">
              <div className="card-body">
                <h2 className="h1">Reset Password</h2>
                <p className="my-3 my-lg-5">
                  Please enter your email address
                  <br />
                  to request a password reset.
                </p>
                <div className="form-group text-left">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Type email"
                      id="email"
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    {email != "" && (
                      <ValidateForm
                        dataTitle={error}
                        isError={error.length > 0}
                      />
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <a
                    className="btn btn-primary"
                    onClick={handleSendResetPassword}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border"
                        style={{ width: "1rem", height: "1rem" }}
                        role="status"
                      />
                    ) : (
                      "RESET"
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isShowModal && (
        <>
          <div
            className="modal show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-noti"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h1 className="modal-title">Check your email!</h1>
                  <p className="mb-0 mt-3">
                    We emailed you a password reset link.
                    <br />
                    Please click on the link in the email and create a new
                    password.
                  </p>
                  <div className="btn-group mt-5">
                    <Link to="/login" className="btn btn-primary">
                      OK
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop"></div>
        </>
      )}
    </Layout>
  )
}

export default ForgotPassword
