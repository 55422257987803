import React, { useState, useEffect, useCallback, useRef } from "react"
import Layout from "components/common/layout"
import ReactTooltip from "react-tooltip"
import {
  callApi,
  GET_DETAIL_PROJECT_API,
  PUT_DETAIL_PROJECT_API,
  POST_CREATE_PRINT_ORDER,
  REVIEW_ALL,
  DOWNLOAD_FILES,
  CHECK_COUPON
} from "lib/api-server"
import { useParams, Link, navigate, useLocation } from "@reach/router"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { handleToastCopy } from "lib/toast"
import "assets/scss/pages/project-details.scss"
import toast from "react-hot-toast"
import { DIMENTION_LIMIT } from "lib/scope"

const fileDownload = require('js-file-download');

const CLASS_NAME_REVIEW_ALL_FOR_FILE = {
  submitted: "ri-subtract-line text-muted",
  approved: "ri-check-fill text-success",
  rejected: "ri-close-line text-danger",
}

const ProjectDetaill = (props) => {
  const { id } = useParams()
  const [project, setProject] = useState({})
  const location = useLocation()

  const [
    isChangeStatusStudentSubmisstionLoading,
    setIsChangeStatusStudentSubmisstionLoading,
  ] = useState(false)

  const [orderPreview, setOrderPreview] = useState({
    total_print_time: "",
    subtotal_cost: "",
    gst: "",
    setup_cost: "",
    delivery_fee: "",
    total_cost: "$ 0",
    total_budget: '',
    dimension_limit: 'small',
    cost_per_print: "",
    layer_height: '',
    print_orders: []
  })
  const [selectedFiles, setSelectedFiles] = useState([])

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const uri = GET_DETAIL_PROJECT_API.replace(':id', id)

    callApi('GET', uri).then(res => {
      setProject(res)
    })
  }, [])

  const handleChangeStatusStudentSubmission = value => {
    if (!isChangeStatusStudentSubmisstionLoading) {
      setIsChangeStatusStudentSubmisstionLoading(true)
      callApi("PUT", PUT_DETAIL_PROJECT_API.replace(":id", id), {
        open_for_submission: value,
      })
      .then(res => {
        setProject({...project, open_for_submission: res.open_for_submission })
        console.log(project.open_for_submission)
        setIsChangeStatusStudentSubmisstionLoading(false)
      })
      .catch(err => {
        setIsChangeStatusStudentSubmisstionLoading(false)
      })
    }
  }

  const handleSelectFile = id => {
    const currentSlectedFiles = selectedFiles
    const index = currentSlectedFiles.indexOf(id)

    if (index === -1) {
      const newSelectedFiles = [...currentSlectedFiles, id]
      setSelectedFiles(newSelectedFiles)
    } else {
      currentSlectedFiles.splice(index, 1)
      setSelectedFiles([...currentSlectedFiles])
    }
  }

  useEffect(() => {
    if (selectedFiles.length > 0) {
      callApi("POST", POST_CREATE_PRINT_ORDER.replace(":project_id", id), {
        file_ids: selectedFiles, is_draft: true
      }).then(res => {
        setOrderPreview(res);
        setProject({...project,
          is_budget_exceed: res.project_is_budget_exceed,
          total_cost: res.project_total_cost
        })
      })
    }
    else
    {
      setOrderPreview();
      setProject({...project,
        is_budget_exceed: project.origin_is_budget_exceed,
        total_cost: project.origin_total_cost
      })
    }
  }, [selectedFiles])

  const handleCreatePrintOrder = () => {
    const data = { file_ids: selectedFiles, coupon_code: couponValueInputRef.current.value }

    callApi("POST", POST_CREATE_PRINT_ORDER.replace(":project_id", id), data).then(res => {
      navigate(`/projects/${id}/print-orders/${res.id}`)
    })
  }

  const [isShowModelReviewAll, setIsShowModelReviewAll] = useState(false)
  const [reviewLoading, setReviewLoading] = useState({
    notApprove: false,
    aprrove: false
  })
  const [downloading, setDownloading] = useState(false)

  const handleDownloadFiles = useCallback(() => {
    if(!downloading)
    {
      setDownloading(true)

      callApi("POST", DOWNLOAD_FILES, {file_ids: selectedFiles}, {"content-type": "application/zip", responseType: 'blob' }).then(res => {
        fileDownload(res, 'model_files.zip');
        setDownloading(false)
      }).catch(err => {
        setDownloading(false)
        toast.error("Can't download files now!")
      })
    }
  }, [selectedFiles, downloading])

  const handleReviewAll = useCallback((is_approve) => {
    if(!is_approve)
    {
      setReviewLoading({...reviewLoading, notApprove: true})
    }
    else
    {
      setReviewLoading({...reviewLoading, aprrove: true})
    }

    callApi("PUT", REVIEW_ALL.replace(":id", id), {is_approve: is_approve}).then(res => {
      setProject(res)
      setIsShowModelReviewAll(false)
      setReviewLoading({
        notApprove: false,
        aprrove: false
      })
    }).catch(err => {
      setIsShowModelReviewAll(false)
      setReviewLoading({
        notApprove: false,
        aprrove: false
      })
    })
  }, [reviewLoading])

  const couponValueInputRef = useRef()
  const [couponValid, setCouponValid] = useState(null)
  const [isApplyCouponLoading, setIsApplyCouponLoading] = useState(false)

  const handleApplyDiscount = () => {
    if(couponValueInputRef.current.value !== "" || isApplyCouponLoading)
    {
      setIsApplyCouponLoading(true)

      callApi("POST", POST_CREATE_PRINT_ORDER.replace(":project_id", id), {
        file_ids: selectedFiles, is_draft: true, coupon_code: couponValueInputRef.current.value
      }).then(res => {
        setIsApplyCouponLoading(false)
        if(!res.coupon) {
          toast.error("Discount code is not valid")
        }

        setOrderPreview(res)
        setProject({...project,
          is_budget_exceed: res.project_is_budget_exceed,
          total_cost: res.project_total_cost
        })
      })
    }
  }

  const handleRemoveCoupon = () => {
    couponValueInputRef.current.value = ""

    callApi("POST", POST_CREATE_PRINT_ORDER.replace(":project_id", id), {
      file_ids: selectedFiles, is_draft: true
    }).then(res => {
      setOrderPreview(res)
    })
  }

  const PrintOrders = (
    <div className={`card card-content-wrapper card-project card-summary-order ${project?.print_orders?.length === 0 && "card-summary-order__empty"}`}>
      <div className="card-header border-0 pb-0">
        <h2 className="text-warning mb-0">Print Orders</h2>
      </div>
      <div className="card-body">
        {
          project?.print_orders?.length === 0 ? (
            <p className="text-muted">No print orders have been made.
              <br/>Please select files to print orders.
            </p>
          ) : (
          <div className="table-responsive">
            <table className="table table-align-center">
              <thead>
                <tr>
                  <th scope="col">Invoice No.</th>
                  <th scope="col" style={{textAlign: "center"}}>Sent to Print
                  </th>
                  <th scope="col" style={{textAlign: "center"}}>Cost (Incld. Delivery & GST)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  project.print_orders?.map(printOrder => (
                    <tr key={printOrder.id}>
                      <th scope="row">{printOrder.invoice_no}</th>
                      <td align="center">
                        <i className={`ri-xl ${printOrder.is_sent ? "ri-check-line" : "ri-subtract-line text-muted"}`}/>
                      </td>
                      <td align="center">{printOrder.cost}</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to={`/projects/${id}/print-orders/${printOrder.id}`}>View</Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )

  const CreatePrintOrder = (
    <>
      <div
        className="card card-content-wrapper card-project card-summary-order" >
        <div className="card-header border-0 pb-0">
          <h2 className="text-warning mb-0">Create Print Order Preview</h2>
        </div>
        <div className="card-body">
          <>
            <dl>
              <dt>Total Files</dt>
              <dd>{orderPreview?.total_file}</dd>
              <dt>Total Print Time:</dt>
              <dd>{orderPreview?.total_print_time}</dd>
              <dt>Subtotal Cost (excluding Set Up, GST & Delivery):</dt>
              <dd>{orderPreview?.subtotal_cost}</dd>
              <dt>Set Up Cost:</dt>
              <dd>
                {orderPreview?.setup_cost}
                <span className="tooltip-wrapper ml-1" data-title="blah blabh blah" >
                  <i className="tooltip-icon fa fa-check-circle" data-tip="It's the setup cost"></i>
                </span>
              </dd>
              <dt>Delivery Fee:</dt>
              <dd>
                {orderPreview?.delivery_fee}
                <span
                  className="tooltip-wrapper ml-1"
                  data-title="If the total weight is above 500g, the delivery is $30. Most prints will be delivered within 2 - 5 business days. If you need same day or next day delivery please contact TinkerSteps to discuss."
                >
                  <i className="tooltip-icon fa fa-check-circle" data-tip='If the total weight is above 500g, the delivery is $30. Most prints will be delivered within 2 - 5 business days. If you need same day or next day delivery please contact TinkerSteps to discuss.'></i>
                </span>
              </dd>

              {orderPreview?.coupon && (
                <>
                  <dt>Discount:</dt>
                  <dd>
                    -{orderPreview?.discount}
                    <span
                      className="tooltip-wrapper ml-1"
                      data-title="Not including setup or delivery cost"
                    >
                      <i className="tooltip-icon fa fa-check-circle" data-tip='Not including setup or delivery cost'/>
                    </span>
                  </dd>
                </>
              )}

              <dt>GST:</dt>
              <dd>{orderPreview?.gst}</dd>

              <dt className="d-item-bold">
                Total Cost (including Set Up, GST & Delivery):
              </dt>
              <dd className="h5">{orderPreview?.total_cost}</dd>
            </dl>

            <dl className="mt-4 mb-4">
              <dt className="d-flex align-items-center">
                <input ref={couponValueInputRef} style={{marginLeft: "0.19rem"}} className="form-control" placeholder="Enter your coupon"/>
                { orderPreview?.coupon &&
                  <a onClick={handleRemoveCoupon}><i className="ri-xl ri-close-line text-danger ml-2"/></a>
                }
              </dt>
              <dd>
                <a className="btn btn-primary" onClick={handleApplyDiscount}>
                  {isApplyCouponLoading ?
                    <span className="spinner-border" role="status" style={{ width: "1rem", height: "1rem" }}/>
                      :
                    "Apply Discount"
                  }
                </a>
              </dd>
            </dl>

            <a className="btn btn-primary float-right" onClick={handleCreatePrintOrder}>Create Print Order</a>
          </>
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>

      <main className="container" role="main">
        <div className="d-flex align-items-center page-title text-dark-blue">
          <span className="page-title__icon">
            <i className="ri-5x ri-checkbox-blank-circle-fill"></i>
          </span>
          <h1 className="ml-3 mb-0">{project.name}</h1>
          <div className="btn-wrapper ml-auto">
            <Link className="page-title__link" to="/projects">
              <span className="btn btn-icon btn-outline-warning rounded-circle bg-white">
                <i className="ri-xl ri-arrow-left-line"></i>
              </span>
              Back to Projects
            </Link>
          </div>
        </div>
        <div className="row row-summary">
          <div className="col-12 col-summary-left">
            <div className="card card-content-wrapper card-project card-summary-submission">
              <div className="card-header align-items-center">
                <h3 className="fz-18 mb-0">STUDENT SUBMISSION</h3>
                <div className="switch mr-auto ml-2">
                  <input
                    className="switch-input"
                    id="switch-submission"
                    type="checkbox"
                    checked={project.open_for_submission}
                    onChange={e =>
                      handleChangeStatusStudentSubmission(e.target.checked)
                    }
                  />
                  <label
                    className="switch-label mb-0"
                    htmlFor="switch-submission"
                    data-texton="On"
                    data-textoff="Off"
                  ></label>
                </div>
              </div>
              <div className="card-body">
                <dl className="dl-strong">
                  <dt>Project Code:</dt>
                  <dd>
                    <i className="ri-xl ri-clipboard-line"></i>
                    <CopyToClipboard
                      text={project.project_code}
                      onCopy={handleToastCopy}
                    >
                      <a
                        className="underline text-body"
                        style={{ cursor: "pointer" }}
                      >
                        {project.project_code}
                      </a>
                    </CopyToClipboard>
                  </dd>
                  <dt>Project Submission Due Date:</dt>
                  <dd>{project.submission_date}</dd>
                </dl>
              </div>
              <div className="card-footer">
                <dl>
                  <dt>Per student budget:</dt>
                  <dd>{project.cost_per_print}</dd>
                  <dt>Dimension limit:</dt>
                  <dd>{project?.dimension_limit} (W {DIMENTION_LIMIT[project?.dimension_limit]?.width}, L {DIMENTION_LIMIT[project?.dimension_limit]?.length}, H {DIMENTION_LIMIT[project?.dimension_limit]?.height})</dd>
                  <dt>Layer Height:</dt>
                  <dd>{project.layer_height}</dd>
                  <dt>Total project budget:</dt>
                  <dd>{project.total_budget}</dd>
                  <br/>
                  <dt>Total Submitted Files:</dt>
                  <dd>{project.files_count}</dd>
                  <dt>Total Files to be Printed:</dt>
                  <dd>{project?.printed_files_count}</dd>
                  <dt>Total Print Time:</dt>
                  <dd>{project.total_print_time }</dd>
                  <dt className="d-item-bold">
                    Total Cost (including Set Up, GST & Delivery):
                  </dt>
                  <dd
                    className={`${
                      project.is_budget_exceed
                        ? "text-danger"
                        : "text-success"
                    } d-item-bold`}
                  >
                    {project?.total_cost}
                    <span
                      className="tooltip-wrapper ml-1"
                      data-title="Total cost is within set budget"
                    >
                      <i className="tooltip-icon fa fa-check-circle" data-tip={project.is_budget_exceed ? 'Total cost is over project budget' : 'Total cost is within cost budget'}></i>
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="col-12 col-summary-right">
          {((selectedFiles.length > 0) && orderPreview) ? CreatePrintOrder : PrintOrders}
          </div>
        </div>
        <div className="card card-content-wrapper card-project">
          <div className="card-header">
            <h2 className="text-warning mb-0">Submitted Files</h2>
            <a
              className={`btn btn-link ${selectedFiles.length === 0 || downloading ? "disabled" : ""}`}
              role="button"
              disabled={selectedFiles.length === 0}
              onClick={handleDownloadFiles}
            >
              <i className="ri-xl ri-file-download-fill"></i>
              Select Item(s) Download{' '}
              {downloading && <span className="spinner-border" role="status" style={{ width: "1rem", height: "1rem" }}/>}
            </a>
          </div>
          <div className="card-body">
            <div className="table-scroll has-scroller">
              <div className="table-head">
                <table className="table mb-0">
                  <colgroup>
                    {/* <col width="120px" /> */}
                    <col width="20%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="16%" />
                    <col width="16%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <thead>
                    <tr>
                      {/* <th
                        role="button"
                        onClick={() => setIsShowModelReviewAll(true)}
                        scope="col"
                        className="text-center review-all"
                      >
                        <i className="ri-xl ri-check-double-line mr-2"></i>
                        Review All
                      </th> */}
                      <th scope="col">File</th>
                      <th scope="col">Name</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Print Time</th>
                      <th scope="col">Student Comment</th>
                      <th scope="col">Teacher Comment</th>
                      <th scope="col">Grams(g)</th>
                      <th scope="col">Cost</th>
                      <th scope="col">Print</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="table-body">
                <div className="table-body__wrapper">
                  <div className="table-responsive">
                    <table className="table table-align-center">
                      <colgroup>
                        {/* <col width="120px" /> */}
                        <col width="20%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="16%" />
                        <col width="16%" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>
                      <tbody>
                        {project.files?.map(file => (
                          <tr
                            key={file.id}
                            className={`${
                              file.aasm_state === "approved" && "printable"
                            }`}
                          >
                            {/* <td className="pl-0" align="center">
                              <i
                                className={`ri-xl ${
                                  CLASS_NAME_REVIEW_ALL_FOR_FILE[
                                    file.aasm_state
                                  ]
                                }`}
                              ></i>
                            </td> */}
                            <td>
                              <div className="media align-items-center">
                                <img className="mr-3" src={file.thumb_url} alt={file.file_name} width='80' />
                                <a role="button" className="underline"
                                  onClick={() => navigate(`${location.pathname}/files/${file.id}`,{state: {project: project}})} >
                                  {file.file_name}
                                </a>
                              </div>
                            </td>
                            <td>{file.name}</td>
                            <td className="text-center">{file.quantity}</td>
                            <td>{file.print_time}</td>
                            <td>{file.student_comment}</td>
                            <td>{file.teacher_comment}</td>
                            <td>{file.grams}</td>
                            <td>{file.cost}</td>
                            <td>
                              <div className="custom-control custom-checkbox checkbox-fill">
                                <input
                                  className="custom-control-input"
                                  id={`submission-${file.id}`}
                                  type="checkbox"
                                  onChange={() => handleSelectFile(file.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`submission-${file.id}`}
                                ></label>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {
        isShowModelReviewAll && (
          <>
            <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }} >
              <div className="modal-dialog modal-dialog-centered modal-noti" role="document" >
                <div className="modal-content">
                  <div className="modal-body">
                    <h1 className="modal-title">Review All File</h1>
                    <div className="btn-group mt-5">
                      <a className="btn btn-outline-primary" onClick={() => setIsShowModelReviewAll(false)} >
                        CANCEL
                      </a>
                      <a className={`btn btn-outline-primary ${reviewLoading.notApprove || reviewLoading.aprrove ? "disabled": ""}`} onClick={() => handleReviewAll(false)} >
                        {reviewLoading.notApprove ? <span className="spinner-border text-primary" style={{width: "1rem", height: "1rem"}} role="status"/> : "NOT APPROVE"}
                      </a>
                      <a className={`btn btn-primary ${reviewLoading.notApprove || reviewLoading.aprrove ? "disabled": ""}`}onClick={() => handleReviewAll(true)}>
                        {reviewLoading.aprrove ? <span style={{width: "1rem", height: "1rem"}} className="spinner-border" role="status"/> : "APPROVE"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop"/>
          </>
        )
      }
    </Layout>
  )
}

export default ProjectDetaill
