import React, { useEffect } from "react"
import Layout from "components/common/layout"
import { getUser } from "services/auth"
import { Link } from "gatsby"
import {
  UNREAD_NOTIFICATIONS_API,
  DISMISS_NOTIFICATIONS_API,
  callApi,
} from "lib/api-server"

import ProjectsImg from "assets/img/projects.svg"
import PlansImg from "assets/img/plans.svg"
import AccountImg from "assets/img/account.svg"
import FeedbackImg from "assets/img/feedback.svg"

const Dashboard = props => {
  const [isLoading, setIsLoading] = React.useState()
  const [notifications, setNotifications] = React.useState([])

  const dismissNotification = id => {
    const apiURL = DISMISS_NOTIFICATIONS_API.replace(":id", id)

    callApi("PUT", apiURL)
      .then(response => {
        callApi("GET", UNREAD_NOTIFICATIONS_API)
          .then(response => setNotifications(response))
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
  }
  useEffect(() => {
    callApi("GET", UNREAD_NOTIFICATIONS_API)
      .then(response => setNotifications(response))
      .catch(error => console.log(error))
  }, [])

  return (
    <Layout title="Dashboard">
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title text-dark-blue">
          <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
          <div className="btn-wrapper ml-auto">
            <a className="page-title__link">
              <span>
                <i className="ri-xl ri-information-fill"></i>
              </span>
              Site Tutorial
            </a>
          </div>
        </div>
        <div className="row row-summary">
          <div className="col-12 col-summary-left"></div>
          <div className="col-12 col-summary-right"></div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-xl-7">
            <div className="row">
              <div className="col-sm-6">
                <div className="card card-content-wrapper card-dashboard">
                  <div className="card-header border-0 pb-0">
                    <h2 className="text-warning mb-0">Projects</h2>
                  </div>
                  <div className="card-body text-center">
                    <img src={ProjectsImg} alt="Projects" />
                    <p>View projects you have set up and create new projects</p>
                  </div>
                  <div className="card-footer text-center border-0">
                    <Link className="btn btn-primary" to="/projects">
                      View Projects
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-content-wrapper card-dashboard">
                  <div className="card-header border-0 pb-0">
                    <h2 className="text-warning mb-0">Resources</h2>
                  </div>
                  <div className="card-body text-center">
                    <img src={PlansImg} alt="Resources" />
                    <p>View various resources and download them</p>
                  </div>
                  <div className="card-footer text-center border-0">
                    <Link className="btn btn-primary" to={getUser()?.role?.includes("admin") ? ("/admin/resources") : ("/resources")}>
                      View Plans
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-content-wrapper card-dashboard">
                  <div className="card-header border-0 pb-0">
                    <h2 className="text-warning mb-0">Account Settings</h2>
                  </div>
                  <div className="card-body text-center">
                    <img src={AccountImg} alt="Account Settings" />
                    <p>Change your personal details and account settings</p>
                  </div>
                  <div className="card-footer text-center border-0">
                    <Link className="btn btn-primary" to="/profile">
                      Edit Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-content-wrapper card-dashboard">
                  <div className="card-header border-0 pb-0">
                    <h2 className="text-warning mb-0">Feedback</h2>
                  </div>
                  <div className="card-body text-center">
                    <img src={FeedbackImg} alt="Feedback" />
                    <p>Please send us feedback</p>
                  </div>
                  <div className="card-footer text-center border-0">
                    <Link className="btn btn-primary" to="/feedback">
                      Send Feedback
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-5">
            <div className="card card-content-wrapper card-noti-dashboard">
              <div className="card-header border-0 pb-0">
                <h2 className="text-warning mb-0">Notifications</h2>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  {notifications.length == 0 && (
                    <li>You don't have any unread notification</li>
                  )}
                  {notifications.map(item => (
                    <li className="list-group-item notification" key={item.id}>
                      <p className="notification__content">
                        {item.message} &nbsp;
                        <Link
                          className="underline"
                          to={`/projects/${item.project_code}`}
                        >
                          {item.project_code}.
                        </Link>
                      </p>
                      <small className="notification__at">
                        {item.created_at}
                      </small>
                      <a
                        className="ml-auto btn btn-icon btn-link"
                        onClick={() => dismissNotification(item.id)}
                      >
                        <i className="ri-lg ri-close-line"></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Dashboard
