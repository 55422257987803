import { navigate } from 'gatsby-link'
import React from 'react'
import { useSelector } from "react-redux"

const SubmitFileFirstStep = () => {
  const { info } = useSelector(state => state.fileUploadInfo)

  return (
    <>
      <h1 className="text-center">Submit File</h1>
      <ul className="nav nav-progress">
        <li className="nav-item">
          <a className="nav-link active" >REVIEW
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" >FORM</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" >SUBMIT</a>
        </li>
      </ul>
      <div className="card card-submit">
        <div className="card-body d-flex">
          <div className="card-left">
            <h3 className="card-title">Printing Summary</h3>
            <ul className="list-group list-group-submit">
              <li className="list-group-item">Class Name:<span className="ml-auto">{info?.project.class_name}</span>
              </li>
              <li className="list-group-item">Teacher:<span className="ml-auto">{info?.project.teacher}</span>
              </li>
              <li className="list-group-item">Quantity:<span className="ml-auto">{info?.quantity}</span>
              </li>
              <li className="list-group-item">Technology:<span className="ml-auto">{info?.project.technology}</span>
              </li>
              <li className="list-group-item">Material:<span className="ml-auto">{info?.project.material}</span>
              </li>
              <li className="list-group-item">Layer Height:<span className="ml-auto">{info?.project.layer_height}</span>
              </li>
              <li className="list-group-item font-weight-bold">Total Cost:<span
                  className="ml-auto">{info?.total_cost}</span>
              </li>
            </ul>
          </div>
          <div className="card-right">
            <a className="btn-next" onClick={() => navigate("/upload-file/submit-file/submit-file-second-step")}>NEXT<i
                className="ri-xl ri-arrow-right-line"></i>
            </a>
            <a className="btn-back" onClick={() => navigate(-1)}>BACK<i
                className="ri-xl ri-arrow-left-line"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubmitFileFirstStep
