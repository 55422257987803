import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, getUser } from "../services/auth"
import toast from "react-hot-toast"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn()) {
    navigate("/admin")
    return null
  } else if (getUser().role && !getUser().role.includes("admin")) {
    toast.error("You do not have permission to access that page!")
    navigate('/dashboard')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
