import React, { useState, useEffect } from "react"
import ValidateForm from "components/common/ValidateForm"
import Select, { components } from 'react-select'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="tooltip-wrapper ml-1" data-title="School can't be blank">
        <i className="tooltip-icon ri-error-warning-fill text-danger" data-tip="School can't be blank"></i>
      </span>
    </components.DropdownIndicator>
  )
}

const TeacherProjectForm = (props) => {
  const { data, setData, messageErrors, isShow, setIsShow, schoolList, selectedSchool, setSelectedSchool, isCreate} = props
  const [isChange, setIsChange] = useState({
    isChangeFirstName: false,
    isChangeLastName: false,
    isChangeContactNumber: false,
    isChangeEmail: false,
    isChangeSchoolAddress: false,
  })

  const [schoolOptions, setSchoolOptions] = useState()
  const [selectedSchoolOption, setSelectedSchoolOption] = useState(null)

  useEffect(() => {
    if(selectedSchoolOption)
    {
      setSelectedSchool(selectedSchoolOption.value)
    }
  },[selectedSchoolOption])

  useEffect(() => {
    if(selectedSchool && !selectedSchoolOption && schoolOptions)
    {
      setSelectedSchoolOption(schoolOptions.find(item => item.value === selectedSchool))
    }
  },[selectedSchool, selectedSchoolOption, schoolOptions])

  useEffect(() => {
    if(schoolList.length > 0)
    {
      setSchoolOptions(schoolList.map(school => {
        return { value:  school.id, label: school.name}
      }))
    }
  }, [schoolList])

  return (
    <div className="row justify-content-center">
      <div className="col-md-5">
        <div className="form-group">
          <label htmlFor="f-name">First Name</label>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              id="f-name"
              name="first_name"
              aria-describedby=""
              placeholder="Type your first name"
              value={data.first_name}
              onChange={e => {
                setData({ ...data, first_name: e.target.value })
                if(!isChange.isChangeFirstName)
                {
                  setIsChange({...isChange, isChangeFirstName: true})
                }
                delete messageErrors.firstNameMessage
              }}
              required
            />
            {(isChange.isChangeFirstName || isCreate) && (data.first_name !== "" || messageErrors.firstNameMessage) && (
              <ValidateForm
                dataTitle={messageErrors.firstNameMessage}
                isError={messageErrors.firstNameMessage}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="l-name">Last Name</label>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              id="l-name"
              name="last_name"
              aria-describedby=""
              placeholder="Type your last name"
              value={data.last_name}
              onChange={e => {
                setData({ ...data, last_name: e.target.value })
                if(!isChange.isChangeLastName)
                {
                  setIsChange({...isChange, isChangeLastName: true})
                }
                delete messageErrors.lastNameMessage
              }}
              required
            />
            {(isChange.isChangeLastName || isCreate) && (data.last_name !== "" || messageErrors.lastNameMessage) && (
              <ValidateForm
                dataTitle={messageErrors.lastNameMessage}
                isError={messageErrors.lastNameMessage}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Contact Number</label>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              id="phone"
              name="phone"
              aria-describedby=""
              placeholder="Type number"
              value={data.phone}
              onChange={e => {
                setData({ ...data, phone: e.target.value })
                if(!isChange.isChangeContactNumber)
                {
                  setIsChange({...isChange, isChangeContactNumber: true})
                }
                delete messageErrors.contactMessage
              }}
            />
            {(isChange.isChangeContactNumber || isCreate) && (data.phone !== "" || messageErrors.contactMessage) && (
              <ValidateForm
                dataTitle={messageErrors.contactMessage}
                isError={messageErrors.contactMessage}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <div className="input-group">
            <input
              className="form-control"
              id="email"
              name="email"
              type="email"
              placeholder="Type email"
              value={data.email}
              onChange={e =>{
                setData({ ...data, email: e.target.value })
                if(!isChange.isChangeEmail)
                {
                  setIsChange({...isChange, isChangeEmail: true})
                }
              }}
            />
            {(isChange.isChangeEmail || isCreate) && (data.email !== "" || messageErrors.emailMessage) && (
              <ValidateForm
                dataTitle={messageErrors.emailMessage}
                isError={messageErrors.emailMessage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="form-group ml-md-auto">
          <label htmlFor="#pword-new">New Password</label>
          <div className="input-group">
            <input
              className="form-control"
              id="#pword-new"
              name="password"
              type={isShow.isShowPassword ? "text" : "password"}
              placeholder="Password must be at least 6 characters"
              value={data.password}
              onChange={e =>{
                setData({ ...data, password: e.target.value })
                if(e.target.value.length > 5)
                {
                  delete messageErrors.passwordMessage
                }
              }}
            />
            {messageErrors.passwordMessage ? (
              <ValidateForm
                dataTitle={messageErrors.passwordMessage}
                isError={true}
              />
            ) : (
              <div className="input-group-append">
                <span className="input-group-text">
                  {isShow.isShowPassword ? (
                    <i
                      className="ri-lg ri-eye-off-line text-warning"
                      onClick={() => {
                        setIsShow({
                          ...isShow,
                          isShowPassword: !isShow.isShowPassword,
                        })
                      }}
                    />
                  ) : (
                    <i
                      className="ri-lg ri-eye-line text-warning"
                      onClick={() => {
                        setIsShow({
                          ...isShow,
                          isShowPassword: !isShow.isShowPassword,
                        })
                      }}
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="form-group ml-md-auto">
          <label htmlFor="pword-repeat">Repeat New Password</label>
          <div className="input-group">
            <input
              className="form-control"
              id="pword-repeat"
              name="repeat_password"
              aria-describedby=""
              type={isShow.isShowRepeatPassword ? "text" : "password"}
              placeholder="Repeat Password"
              value={data.repeat_password}
              onChange={e => {
                setData({ ...data, repeat_password: e.target.value })
                if(e.target.value.length > 5 && e.target.value === data.password)
                {
                  delete messageErrors.repeatPasswordMessage
                }
              }}
            />
            {messageErrors.repeatPasswordMessage ? (
              <ValidateForm
                dataTitle={messageErrors.repeatPasswordMessage}
                isError={true}
              />
            ) : (
              <div className="input-group-append">
                <span className="input-group-text">
                  {isShow.isShowRepeatPassword ? (
                    <i
                      className="ri-lg ri-eye-off-line text-warning"
                      onClick={() => {
                        setIsShow({
                          ...isShow,
                          isShowRepeatPassword: !isShow.isShowRepeatPassword,
                        })
                      }}
                    />
                  ) : (
                    <i
                      className="ri-lg ri-eye-line text-warning"
                      onClick={() => {
                        setIsShow({
                          ...isShow,
                          isShowRepeatPassword: !isShow.isShowRepeatPassword,
                        })
                      }}
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="form-group ml-md-auto">
          <label htmlFor="school">School</label>
          <Select options={schoolOptions}
            className="custom-react-select-form"
            value={selectedSchoolOption}
            onChange={(val) => setSelectedSchoolOption(val)}
            styles={{menu: (provided)=> ({...provided, 'zIndex': 999})}}
            placeholder="School"
            components= {!selectedSchool && messageErrors.schoolIDMessage && { DropdownIndicator }}
          />
        </div>
        <div className="form-group ml-md-auto">
          <label htmlFor="school-address">School Address</label>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              id="school-address"
              name="school_address"
              aria-describedby=""
              placeholder="School Address"
              value={data.school_address}
              onChange={e => {
                setData({ ...data, school_address: e.target.value })
                if(!isChange.isChangeSchoolAddress)
                {
                  setIsChange({...isChange, isChangeSchoolAddress: true})
                }
                delete messageErrors.schoolAddressMessage
              }}
            />
            {(isChange.isChangeSchoolAddress || isCreate) && messageErrors.schoolAddressMessage && (
              <ValidateForm
                dataTitle={messageErrors.schoolAddressMessage}
                isError={messageErrors.schoolAddressMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeacherProjectForm
