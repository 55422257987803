import React, { useState, useEffect } from "react"
import { getUser } from "../../../services/auth"
import Layout from "../../common/layout"
import SettingsForm from './SettingsForm'
import AddCouponForm from './AddCouponForm'
import CouponsTable from "./CouponsTable"
import "assets/scss/pages/settings.scss"

const Setting = props => {
  const [user, setUser] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const userResult = getUser()
    if (userResult) {
      setUser(userResult)
    }
  }, [])

  const handleChangeTab = (tabIndex) => {
    if(tabIndex !== selectedTab)
    {
      setSelectedTab(tabIndex)
    }
  }

  return (
    <>
      <Layout title="Setting">
        <div id="background">
          <div className="circle circle--top"></div>
          <div className="circle circle--bottom"></div>
        </div>
        <main className="container" role="main">
          <div className="d-flex align-items-center page-title">
            <span className="page-title__icon bg-white">
              <i className="ri-xl ri-user-fill text-warning"></i>
            </span>
            <h1 className="ml-3 mb-0">Hello, {user.name}</h1>
          </div>

          <div className="settings-tabs mb-5">
            <a onClick={() => handleChangeTab(0)} className={`${selectedTab === 0 ? 'active' : ''}`}>Setting</a>
            <a onClick={() => handleChangeTab(1)} className={`${selectedTab === 1 ? 'active' : ''}`}>Discount code</a>
          </div>

          {/* Manual Settings */}
          <div className={`${selectedTab !== 0 ? 'd-none' : ''}`}>
            <h2 className="text-warning text-center">Settings</h2>
            <p className="my-5 text-center">Change your system settings.</p>
            <SettingsForm/>
          </div>

          {/* Coupons Settings */}
          <div className={`row justify-content-center ${selectedTab !== 1 ? 'd-none' : ''}`}>
            <div className="col-md-6">
              <AddCouponForm/>
            </div>
            <div className="col-md-6">
              <h2 className="text-warning">Discount Codes</h2>
              <div className="card card-content-wrapper">
                <CouponsTable/>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}
export default Setting
