import React, { useEffect } from "react"
import { useNavigate, Link } from "@reach/router"
import { handleLogin, isLoggedIn } from "services/auth"
import ReactTooltip from "react-tooltip"

import { VALID_EMAIL } from "lib/validate"
import "assets/scss/pages/login.scss"
import { getUser } from "services/auth"

const LoginForm = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState(false)

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [errors, setErrors] = React.useState({})

  const handleSubmit = event => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    setIsLoading(true)

    handleLogin({ email: email, password: password, admin: true }).then(res => {
      if (res.errors) {
        setIsLoading(false)
        setErrors(res.errors.reduce((obj, item) => ((obj[[item["field"]]] = item["message"]), obj), {}))
      } else {
        navigate("/admin/print-orders")
      }
    })
  }

  useEffect(() => {
    const isEmailValid = VALID_EMAIL.test(email) || email.length === 0

    if (!isEmailValid) {
      setErrors(errors => {return {...errors, email: "Invalid email"}})
    }
    else if(errors && errors.email){
      delete errors.email
      setErrors({...errors})
    }

    if(errors && errors.password)
    {
      delete errors.password
      setErrors({...errors})
    }

  }, [email])

  useEffect(() => {
    const isPasswordValid = password.length >= 6 || email.length === 0

    if (!isPasswordValid) {
      setErrors(errors => {return {...errors, password: "Password must be at least 6 characters"}})
    } else if(errors && errors.password){
      delete errors.password
      setErrors({...errors})
    }

  }, [password])

  useEffect(() => {
    const isLogin = isLoggedIn()
    if (isLogin ) {
      const user = getUser()
      if(user.role.includes("admin"))
      {
        navigate("/admin/print-orders")
      }
      else {
        navigate("/dashboard")
      }
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <div className="form-group text-left">
        <label htmlFor="email">Email</label>
        <div className="input-group">
          <input
            className="form-control"
            id="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          {email != "" && (
            <div className="input-group-append">
              <span className="input-group-text">
                <span
                  className="tooltip-wrapper ml-1"
                  data-title="Invalid email"
                >
                  {email != "" &&
                    (errors && errors["email"] ? (
                      <>
                        <i className="tooltip-icon ri-xl ri-close-line text-danger" data-tip={errors["email"]}></i>
                      </>
                    ) : (
                      <i className="ri-xl ri-check-line"></i>
                    ))}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="form-group text-left">
        <label htmlFor="#pword">Password</label>
        <div className="input-group">
          <input
            className="form-control"
            id="pword"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          {password != "" && (
            <div className="input-group-append">
              <span className="input-group-text">
                <span
                  className="tooltip-wrapper ml-1"
                  data-title="Sorry, wrong password"
                >
                  {password != "" &&
                    (errors && errors["password"] ? (
                      <>
                        <i className="tooltip-icon ri-xl ri-close-line text-danger" data-tip={errors["password"]}></i>
                      </>
                    ) : (
                      <i className="ri-xl ri-check-line"></i>
                    ))}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column">
        <Link className="text-body underline" to="/forgot-password">
          Forgot password?
        </Link>
        <button
          className="btn btn-primary"
          disabled={errors && Object.keys(errors).length > 0}
          type="submit"
        >
          {!isLoading ? (
            `SIGN IN`
          ) : (
            <span
              className="spinner-border"
              style={{ width: "1rem", height: "1rem" }}
              role="status"
            ></span>
          )}
        </button>
      </div>
    </form>
  )
}

export default LoginForm
