import React from "react"
import Layout from "../common/layout"

const About = () => {
  return (
    <Layout title="About Tinker" footerColor="bg-warning">
      <main className="pb-0 layout-page" role="main">
          <div className="container">
            <div className="row ">
              <h1 class='text-center'>What is Tinkersteps</h1>
              <br/>
              <br/>
              <p>Tinkersteps is a Melbourne based 3D printing service designed specially for teachers wanting to 3D print in the classroom. Most schools can only afford a few 3D printers due to high set up costs, ongoing labor, consumable and maintenance costs. This severely restricts teachers wanting to use 3D printing for the whole class as printing 25 or more models can be extremely time consuming and costly. </p>
              <br/>
              <p>Our aim is to give schools equal opportunity to access the latest technology by reducing the barriers of entry. We’ll handle the printing leaving teachers more time to teach in the classroom. We are starting with 3D printing as there is a growing need from teachers wanting to use 3D printing as a teaching tool, with your support we will expand into further offerings in the future such as laser cutting.</p>
            </div>
          </div>
      </main>
    </Layout>
  )
}

export default About;