import React, { useState } from "react"
import { GET_NEW_VERIFY_CODE, VERIFY_EMAIL, callApi } from "lib/api-server"
import Layout from "components/common/layout"
import FormSignUp from "./forms/register"
import "assets/scss/pages/register.scss"
import { navigate } from "gatsby"
import VerifyEmailPopup from "components/common/VerifyEmailPopup"

const RegisterPage = props => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [isGetNewVerifyCodeLoading, setIsGetNewVerifyCodeLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [inform, setInform] = useState(
    "Verify code was sent, let check in your email"
  )
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)

  const sendGetNewVerifyCodeAPI = async () => {
    const response = callApi("GET", `${GET_NEW_VERIFY_CODE}`)
    return response
  }

  const handleGetNewVerifyCode = () => {

    if (isGetNewVerifyCodeLoading) {
      return
    }

    setIsGetNewVerifyCodeLoading(true)
    setInform("")
    setIsError(false)

    sendGetNewVerifyCodeAPI()
      .then(res => {
        setIsGetNewVerifyCodeLoading(false)
        setInform(res.message)
      })
      .catch(err => {
        setIsGetNewVerifyCodeLoading(false)
      })
  }

  const handleVerifyEmail = (code) => {

    if (isVerifyLoading) {
      return
    }

    setIsVerifyLoading(true)
    setIsError(false)
    setInform("")

    callApi("POST", VERIFY_EMAIL, { verify_code: code })
      .then(res => {
        if (res.errors) {
          setIsError(true)
          setIsVerifyLoading(false)
        } else {
          window.localStorage.setItem("user", JSON.stringify(res))
          navigate("/dashboard")
        }
      })
      .catch(err => {
        setIsError(true)
        setIsVerifyLoading(false)
      })
  }

  return (
    <>
      <Layout title="Sign Up" footerColor="bg-warning">
        <main className="bg-white " role="main">
          <div className="container">
            <div className="text-center">
              <h1 className="page-title">Sign up</h1>
              <p className="pb-5">
                Sign up for great features and new products
                <br />
                that are waiting for you.
              </p>
            </div>
            <FormSignUp
              setIsShowModal={setIsShowModal}
              sendGetNewVerifyCodeAPI={sendGetNewVerifyCodeAPI}
            />
          </div>
        </main>
        {
          isShowModal &&
          <VerifyEmailPopup
            isError={isError}
            inform={inform}
            isVerifyLoading={isVerifyLoading}
            handleGetNewVerifyCode={handleGetNewVerifyCode}
            isGetNewVerifyCodeLoading={isGetNewVerifyCodeLoading}
            handleVerifyEmail={handleVerifyEmail}
          />
        }
      </Layout>
    </>
  )
}

export default RegisterPage
