import React from "react"

export default function NotFound() {
  return (
    <main className="container" role="main">
      <div className="row align-items-md-end">
        <div className="col-md-4 col-lg-5">
          <h1>404 Not Found</h1>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Obcaecati corporis ad soluta eos odio.
          </p>
        </div>
      </div>
    </main>
  )
}
