import React, { useState, useCallback, useMemo } from "react"
import { useDropzone } from 'react-dropzone'
import { DIMENTION_LIMIT } from 'lib/scope'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import Layout from 'components/common/layout'
import { updateInfoUploadFileInfo } from "actions/index"
import { callApi, UPLOAD_FILE } from "lib/api-server"
import { uploadFile } from "src/services/upload"
import { ProgressBar } from 'react-bootstrap'
import { toast } from "react-hot-toast"

const UploadFileStep = (props) => {

  const dispatch = useDispatch()
  const  { project }  = useSelector(state => state.fileUploadInfo)
  const [loadedPercent, setLoadedPercent] = useState()
  const ProgressBarInstance = useMemo(()=>  <ProgressBar now={loadedPercent} label={`${loadedPercent}%`}/>, [loadedPercent])

  const checkFile = (fileUrl) =>{

    const formData = new FormData()
    formData.append("file_url", fileUrl)

    callApi("POST", UPLOAD_FILE.replace(":id", project.id), formData).then(res => {

      if(res.error)
      {
        toast.error(`Something went wrong ${res.error}`, {duration: 4000})
      }
      else
      {
        dispatch(updateInfoUploadFileInfo(res))
        navigate("upload-analysis", { state: { project: project}, replace: false})
      }

    })
  }

  const onDrop = useCallback(acceptedFiles => {

    if(acceptedFiles.length > 0)
    {
      setLoadedPercent(0)
      uploadFile(acceptedFiles[0]).then((res) => {
        res.on("httpUploadProgress", (progress) => {
          if(setLoadedPercent)
          {
            setLoadedPercent(() => Math.round(progress.loaded / progress.total * 100))
          }
        })
        .send(function(err, data) {
          if (err) {
            toast.error(`Something went wrong ${err.code} ${err.message}`, {duration: 4000})
          } else {
            const urlFIle= `https://${process.env.AWS_BUCKET_NAME}.s3.${process.env.AWS_REGION}.amazonaws.com/fileUpload/${acceptedFiles[0].name}`
            checkFile(urlFIle)
          }
        })
      })
    } else {
      toast.error("The type file must be STL or OBJ", { id: "dropFileError" })
    }
  }, [project])

  const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false, noClick: true, accept: ".obj, .stl"})

  return (
    <Layout title="Upload File" backgroundColor="bg-yellow" >
      <main className="container" role="main">
        <div className="card card-file-upload">
          <div className="card-header border-0">
            <h1>Upload File</h1>
          </div>
          <div className="card-body text-center">
            <h3 className="text-warning mt-4">{project?.name}</h3>
            <div className="my-4">
              <h5 className="font-weight-bold">Project Code: {project?.project_code}</h5>
              <h5 className="font-weight-normal">Project Submission Due Date: {project?.submission_date}
              </h5>
            </div>
            <div className="list-group-wrapper my-4">
              <ul className="list-group list-group-submit">
                <li className="list-group-item font-weight-bold">Project Constraints
                </li>
              </ul>
              <hr className="divider-yellow"/>
              <ul className="list-group list-group-submit">
                <li className="list-group-item">Student Budget:<span className="ml-auto">{project?.cost_per_print}
                  </span>
                </li>
                <li className="list-group-item">Dimension Limits:<span className="ml-auto">
                    {project?.dimension_limit} (W {DIMENTION_LIMIT[project?.dimension_limit]?.width}, L {DIMENTION_LIMIT[project?.dimension_limit]?.length}, H {DIMENTION_LIMIT[project?.dimension_limit]?.height})</span>
                </li>
                <li className="list-group-item">Material:<span className="ml-auto">{project?.material}
                  </span>
                </li>
                <li className="list-group-item">Layer Height:<span className="ml-auto">{project?.layer_height}
                  </span>
                </li>
              </ul>
            </div>
            {loadedPercent && (
              <div>
                {ProgressBarInstance}
                <label>Uploading, please wait...</label>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="dragdrop bg-blue-dark" id="dragdrop" {...getRootProps()}>
              <div className="dragdrop__wrapper dragdrop--empty dragdrop-upload">
                <div className="dragdrop__cta">
                  <input id="ip-drop-zone" {...getInputProps({accept: ".obj, .stl"})}/>
                  <p>To upload, drop a 3D model file into this box
                    <br/>or click the button below.
                  </p>
                  <label htmlFor="ip-drop-zone" className="btn btn-primary btn-single my-3 dragdrop__cta">Upload File</label>
                </div>
                <i className="ri-7x ri-drag-drop-line dragdrop__icon"></i>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default UploadFileStep
