import React, { useEffect, useState } from "react"
import Layout from "components/common/layout"
import { PROJECTS_API, callApi } from "lib/api-server"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactCodeInput from "react-code-input"
import "assets/scss/pages/project-list.scss"
import { Link } from "@reach/router"
import { getUser } from "services/auth"
import { handleToastCopy } from "lib/toast"
import BeatLoader from "react-spinners/BeatLoader";

const ProjectList = props => {
  const [isGettingProjects, setIsGettingProjects] = useState(true)
  const [projectList, setProjectList] = useState([])
  const [selectedProjectCode, setSelectedProjectCode] = useState("")
  const [userName, setUserName] = useState("")

  useEffect(() => {
    const user = getUser()

    if (user) {
      setUserName(user.name)
    }

    callApi("GET", PROJECTS_API)
      .then(res => {
        setIsGettingProjects(false)
        setProjectList(res.data)
      })
  }, [])

  const ProjectListConponents = projectList.map(project => {
    const isOpenForSubMission = project.open_for_submission

    return (
      <tr key={project.id}>
        <td className="text-left" width="36%">
          {project.name}
        </td>
        <td>
          <a
            style={{ color: "#2F50FB", cursor: "pointer" }}
            onClick={() =>
              setSelectedProjectCode(project.project_code)
            }
          >
            <i className="ri-xl ri-clipboard-line"></i>
            <span className="underline">
              {project.project_code}
            </span>
          </a>
        </td>
        <td>{project.created}</td>
        <td>
          <span
            className={`text-${
              isOpenForSubMission ? "success" : "danger"
            }`}
          >
            {isOpenForSubMission ? "Open" : "Closed"}
          </span>
        </td>
        <td>
          <Link
            className="btn btn-primary"
            to={`/projects/${project.id}`}
          >
            View
          </Link>
        </td>
      </tr>
    )
  })

  const Projects = (
    <main className="container" role="main">
      <div className="d-flex align-items-center page-title text-dark-blue">
        <h1 className="ml-3 mb-0">Hello, {userName ? userName : "teacher"}</h1>
        <div className="btn-wrapper ml-auto">
          <a className="page-title__link">
            <span>
              <i className="ri-xl ri-information-fill"></i>
            </span>
            Site Tutorial
          </a>
        </div>
      </div>
      <div className="row row-summary">
        <div className="col-12 col-summary-left"></div>
        <div className="col-12 col-summary-right"></div>
      </div>
      <div className="card card-content-wrapper card-project">
        <div className="card-header border-0 pb-0">
          <h2 className="text-warning mb-0">Projects</h2>
          <Link
            to="/new-project"
            className="btn btn-outline-primary rounded-pill"
          >
            Set Up New Project
          </Link>
        </div>
        <div className="card-body text-center">
          <div className="table-responsive">
            <table className="table table-align-center">
              <thead>
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col">Code</th>
                  <th scope="col">Created</th>
                  <th scope="col">Submission</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  isGettingProjects ?
                    <div className="position-absolute">
                      <BeatLoader loading={isGettingProjects} size={10} color="#E37D2B"/>
                    </div> :
                    projectList.length === 0 ? (
                      <tr>
                        <td className="text-left" colSpan='5'>You don't have any project</td>
                      </tr>
                    ): ProjectListConponents
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  )

  const Modal = (
    <>
      <main className="container" role="main">
        <div className="modal show modal-relative" tabIndex="-1" role="dialog" style={{display:'block'}}>
          <div className="modal-dialog modal-dialog-centered modal-noti" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="modal-title">Project Code</h1>
                <p className="mb-0">
                  Please ask students to enter the code below
                  <br />
                  when submitting their files.
                </p>
                <ReactCodeInput
                  value={selectedProjectCode}
                  className="form-row justify-content-center"
                  type="text"
                  disabled
                  fields={6}
                />
                <div className="btn-group">
                  <a
                    className="btn btn-outline-primary"
                    onClick={() => setSelectedProjectCode("")}
                  >
                    Close
                  </a>

                  <CopyToClipboard
                    text={selectedProjectCode}
                    onCopy={handleToastCopy}
                  >
                    <a className="btn btn-primary">Copy Code</a>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )

  return (
    <Layout>
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      {!selectedProjectCode ? Projects : Modal}
    </Layout>
  )
}

export default ProjectList
