const initialState = {
  project: null,
  info: null
}

const fileUploadInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PROJECT":
      return  {...state, project: action.payload.project} ;
    case "UPDATE_INFO":
      return  {...state, info: action.payload.info} ;
    default:
      return state;
  }
};

export default fileUploadInfoReducer