import axios from "axios"
import toast from "react-hot-toast"
import { logout } from '../services/auth'
import { navigate } from "gatsby"

export const LOGIN_API = "/api/v1/login"
export const PROJECTS_API = "/api/v1/projects"

// Sign-up-page
export const REGISTER_API = "/api/v1/register"
export const GET_SCHOOLS_API = "/api/v1/schools"
export const GET_NEW_VERIFY_CODE = "/api/v1/verifications/new"
export const VERIFY_EMAIL = "/api/v1/verifications/verify"

//Notification
export const NOTIFICATION_API = "/api/v1/notifications"

//Profile
export const ACCOUNT_API = "/api/v1/profile"
//logout
export const SIGN_OUT = "/api/v1/logout"
export const SEND_REQUEST_FORGOT_PASSWORD_API = "/api/v1/forgot_password"
export const RESET_PASSWORD_API = "/api/v1/password"

export const NOTIFICATIONS_API = "/api/v1/notifications"
export const UNREAD_NOTIFICATIONS_API = "/api/v1/notifications/unread"
export const DISMISS_NOTIFICATIONS_API = "/api/v1/notifications/:id/dismiss"

// New Project page
export const GET_SUBJECTS_API = "/api/v1/subjects"
export const ADD_SUBJECTS_API = "/api/v1/subjects"
export const APPROVE_NEW_PROJECT_API = "/api/v1/projects"

//Lesson Plan
export const GET_LESSON_PLAN_API = "/api/v1/lesson_plans"
export const GET_FILTER_TAGS_API = "/api/v1/lesson_plans/filter_tags"
export const GET_DETAIL_LESSON_PLAN = "/api/v1/lesson_plans/:id"
export const DOWNLOAD_LESSON_FILE_API = "/api/v1/lesson_files/:id/download"

//Feedback
export const FEEDBACK_API = "/api/v1/feedbacks"

// Detail project
export const GET_DETAIL_PROJECT_API = "/api/v1/projects/:id"
export const PUT_DETAIL_PROJECT_API = "/api/v1/projects/:id"
export const POST_CREATE_PRINT_ORDER = "/api/v1/projects/:project_id/print_orders"
export const REVIEW_ALL = "/api/v1/projects/:id/review_all"
export const DOWNLOAD_FILES = "/api/v1/files/download"
export const CHECK_COUPON = "/api/v1/coupons/check_only"

// Print order
export const GET_PRINT_ORDER = "/api/v1/print_orders/:id"
export const PUT_PRINT_ORDER = "/api/v1/print_orders/:id"
export const DELETE_PRINT_ORDER = "/api/v1/print_orders/:id"

export const CHECK_PROJECT_CODE = "/api/v1/projects/check_code"
export const UPLOAD_FILE = "/api/v1/projects/:id/upload"
export const CHECK_FILE_UPLOAD = "/api/v1/files/:id/check"
export const SUBMIT_FILE = "/api/v1/files/:id/submit"

// Preview file
export const GET_DETAIl_FILE = "/api/v1/files/:id"
export const APPROVE_FILE = "/api/v1/files/:id/approve"

//Admin Setting
export const SETTING_ADMIN_API = "/api/v1/admin/setting"

//Lesson Plan Admin
export const GET_LESSON_PLAN_ADMIN = "/api/v1/admin/lesson_plans"
export const DETAIL_LESSON_PLAN_ADMIN = "/api/v1/admin/lesson_plans/:id"
export const CREATE_LESSON_PLAN_API = "/api/v1/admin/lesson_plans"

//Admin Orders
export const ADMIN_GET_PRINT_ORDER_API = "/api/v1/admin/print_orders"
export const ADMIN_PUT_PRINT_ORDER_API = "/api/v1/admin/print_orders/:id"

//Admin Teacher
export const TEACHER_API = "/api/v1/admin/teachers"
export const TEACHER_DETAIL_API = "/api/v1/admin/teachers/:id"

//Admin Login
export const ADMIN_LOGIN_API = "/api/v1/admin/sessions"

//Admin coupon
export const ADMIN_COUPON_API = "/api/v1/admin/coupons"

const fetchTools = axios.create({
  baseURL: process.env.API_SERVER,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
})

fetchTools.interceptors.request.use(
  async config => {
    let user = JSON.parse(window.localStorage.getItem("user"))

    if (!user) {
      user = JSON.parse(window.localStorage.getItem("unverified_user"))
    }

    if (user && user.token) {
      config.headers["Authorization"] = `Bearer ${user.token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export const checkStatus = response => {
  if (response.status >= 200 && response.status <= 400) {
    return response
  } else {
    const error = new Error(response.statusText)
    throw error
  }
};

export const parseJSON = (response) => {
  return response.data;
};

export function callApi(
  method,
  api,
  data,
  config,
){
  let callApi = null;
  switch (method) {
    case 'POST':
      callApi = fetchTools.post(api, data, config);
      break;
    case 'PUT':
      callApi = fetchTools.put(api, data);
      break;
    case 'DELETE':
      callApi = fetchTools.delete(api, { params: data });
      break;
    default:
      callApi = fetchTools.get(api, { params: data })
      break
  }

  return callApi
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {

      if(error.response.status === 401) {
        toast.error('Session is expired. Please login again', {duration: 4000, id: "Session-expired"})
        logout(navigate("/login"))
      } else if(error.response.status === 500) {
        toast.error(error.response.data.error, {duration: 4000})
      }

      return Promise.reject(error)
    })
}
