import React from "react"
import LessonPlanList from "components/teacher/lesson_plans"

const LessonPlans = props => {
  return (
    <LessonPlanList admin={true}/>
  )
}

export default LessonPlans
