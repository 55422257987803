import React, { useState } from "react"
import Layout from "components/common/layout"

import { getUser } from "services/auth"
import { FEEDBACK_API, callApi } from "lib/api-server"

import ValidateForm from "components/common/ValidateForm"
import toast from "react-hot-toast"

const Feedback = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({});

  const [messageNameError, setMessageNameError] = useState("")
  const [messageSubjectError, setMessageSubjectError] = useState("")
  const [messageFeedbackError, setMessageFeedbackError] = useState("")

  const handleSend = (event) => {
    event.preventDefault()
    if (isLoading) {
      return
    }

    setIsLoading(true)
    
    callApi('POST', FEEDBACK_API, data)
      .then(response =>{
        setIsLoading(false)
        setData({...data, name: '', feedback: '', subject: ''})
        toast.success(response.message)
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response) {
          const fieldErrors = err.response.data.errors
          fieldErrors.forEach(item => {
            switch (item.field) {
              case "name":
                setMessageNameError(item.message)
                break
              case "subject":
                setMessageSubjectError(item.message)
                break
              case "feedback":
                setMessageFeedbackError(item.message)
                break
            }
          })
        }
      })
  }
  return (
    <Layout title="Feedback">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title text-dark-blue">
          <h1 className="ml-3 mb-0"></h1>
        </div>
        <h1 className="text-warning">Hello, { getUser().name}
          <br/>We’ll be glad to hear from you.
        </h1>
        <div className="row justify-content-md-end">
          <div className="col-md-8 col-lg-6">
            <form>
              <div className="form-group">
                <label for="name">Name</label>
                <div className="input-group">
                  <input 
                    className="form-control"
                    type="text"
                    id="name"
                    aria-describedby=""
                    placeholder="Type your name"
                    name="name"
                    value={data.name}
                    onChange={e => setData({...data, name: e.target.value})}
                    required
                  />
                  
                  {messageNameError && (
                    <ValidateForm
                      dataTitle={messageNameError}
                      tooltipStyle={{
                        marginLeft: "20px",
                        marginTop: "-30px",
                        width: "170px",
                      }}
                      isError={true}
                    />
                  )}
                </div>
              </div>
              <div className="form-group">
                <label for="subject">Subject</label>
                <div className="input-group">
                  <input 
                    className="form-control" 
                    type="text" 
                    id="subject"
                    aria-describedby="" 
                    placeholder="Project description" 
                    name="subject"
                    value={data.subject}
                    onChange={e =>setData({...data, subject: e.target.value})}
                    required
                  />
                  { messageSubjectError && (
                    <ValidateForm
                      dataTitle={messageSubjectError}
                      tooltipStyle={{
                        marginLeft: "20px",
                        marginTop: "-30px",
                        width: "170px",
                      }}
                      isError={true}
                    />
                  )}
                </div>
              </div>
              <div className="input-group">
                <textarea 
                  className="form-control" 
                  placeholder="Type something…"
                  rows="6"
                  name="feedback"
                  value={data.feedback}
                  onChange={e => setData({...data, feedback: e.target.value})}
                  required
                />
                {messageFeedbackError != '' && (
                  <ValidateForm
                    dataTitle={messageFeedbackError}
                    tooltipStyle={{
                      marginLeft: "20px",
                      marginTop: "-30px",
                      width: "170px",
                    }}
                    isError={true}
                  />
                )}
              </div>
              <div className="mt-3 text-right">
                <button 
                  className="btn btn-primary btn-single" 
                  type="submit"
                  disabled={ Object.keys(data).length < 3}
                  onClick = {handleSend}
                >
                  {!isLoading ? (`SEND`) 
                    : (
                      <span
                        className="spinner-border"
                        style={{ width: "1rem", height: "1rem" }}
                        role="status"
                      />
                  )}
                </button>
              </div>
              <div className="mt-5">
                <strong>Email </strong>
                <br/>
                <a className="text-body" href={`mailto:tinkerstepsaus@gmail.com`}>
                  tinkerstepsaus@gmail.com
                </a>
              </div>
            </form>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Feedback;