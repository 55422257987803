import React from "react"
import Layout from "../common/layout"

const ContactUs = () => {
  return (
    <Layout title="Contact Us" footerColor="bg-warning">
      <main className="pb-0 layout-page" role="main">
          <div className="container">
            <div className="row">
              <h1> Tinker Contact Infomation</h1>
              <p>If you have any questions you can get in contact with us at <a href="mailto:shang@tinkersteps.com.au">shang@tinkersteps.com.au</a> and a staff member will get back to you asap.</p>
            </div>
          </div>
      </main>
    </Layout>
  )
}

export default ContactUs;