import React, { useCallback, useEffect , useState, useMemo} from 'react'
import ModalPreview from '../modalPreview/index'
import { useSelector, useDispatch } from 'react-redux'
import Layout from 'components/common/layout'
import { useDropzone } from 'react-dropzone'
import { navigate } from 'gatsby'
import { updateInfoUploadFileInfo } from "actions"
import { callApi, UPLOAD_FILE, SUBMIT_FILE, CHECK_FILE_UPLOAD } from 'lib/api-server'
import { DIMENTION_LIMIT } from "src/lib/scope"
import axios from 'axios'
import { ProgressBar } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { uploadFile } from "src/services/upload"
import asset1 from 'assets/img/asset_1.svg'
import asset8 from 'assets/img/asset_8.svg'

const UploadAnalysis = (props) => {
  const dispatch = useDispatch()
  const { project , info } = useSelector(state => state.fileUploadInfo)

  const [quantity, setQuantity] = useState(1)

  const [printCost, setPrintCost] = useState(0)
  const [cancelTokenSource, setCancelTokenSource] = useState()
  const [isChecking, setIsChecking] = useState(false)
  const [fileName, setFileName] = useState("")
  const [error, setError] = useState(false)
  const [loadedPercent, setLoadedPercent] = useState()
  const ProgressBarInstance = useMemo(()=> <ProgressBar now={loadedPercent} label={`${loadedPercent}%`}/>, [loadedPercent])

  const checkFile = (fileUrl) =>{
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource)

    const formData = new FormData()
    formData.append("file_url", fileUrl)

    callApi("POST", UPLOAD_FILE.replace(":id", project.id), formData, {cancelToken: newCancelTokenSource.token}).then(res => {

      if(res.error)
      {
        setError(true)
      }
      else
      {
        setError(false)
        setLoadedPercent(null)
        dispatch(updateInfoUploadFileInfo(res))
      }

    }).catch(err => {
      if(err.response?.status == 500)
      {
        setError(true)
      }
      else
      {
        setError(false)
      }
    })
  }

  const onDrop = useCallback(acceptedFiles => {

    if(acceptedFiles.length > 0)
    {
      setFileName(acceptedFiles[0].name)

      if(cancelTokenSource)
      {
        cancelTokenSource.cancel()
      }

      uploadFile(acceptedFiles[0]).then((res) => {
        res.on("httpUploadProgress", (progress) => {
          setLoadedPercent(() => Math.round(progress.loaded / progress.total * 100))
        })
        .send(function(err, data) {
          if (err) {
            toast.error(`Something went wrong ${err.code} ${err.message}`, {duration: 4000})
          } else {
            const urlFIle= `https://${process.env.AWS_BUCKET_NAME}.s3.${process.env.AWS_REGION}.amazonaws.com/fileUpload/${acceptedFiles[0].name}`
            checkFile(urlFIle)
          }
        })
      })

      setQuantity(1)
    } else {
      toast.error("The type file must be STL or OBJ", { id: "dropFileError" })
    }
  }, [cancelTokenSource, project])

  useEffect(() => {
    if(info?.id)
    {
      localStorage.setItem("fileUploadId", JSON.stringify(info.id))
    }
  },[info])

  useEffect(()=>{

    if(!info)
    {
      const fileUploadId = JSON.parse(window.localStorage.getItem("fileUploadId"))

      if(fileUploadId)
      {
        callApi("GET", CHECK_FILE_UPLOAD.replace(":id", fileUploadId)).then(res => {
          dispatch(updateInfoUploadFileInfo(res))

          setFileName(res?.file_name)
          setQuantity(res?.quantity)
        })
      }
      else
      {
        navigate("/")
      }
    }
    else{
      setQuantity(info?.quantity)
      setFileName(info?.file_name)
    }

  },[])


  useEffect(()=>{
    if(quantity && info?.cost)
    {
      setPrintCost(Number(quantity * Number(info.cost)).toFixed(2))
    }
  },[quantity, info])

  const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false, noClick: true, accept: ".obj, .stl"})

  const handleSubmit = () => {
    callApi("PUT", SUBMIT_FILE.replace(":id", info.id), {quantity: quantity, note: info.note}).then(res => {
      dispatch(updateInfoUploadFileInfo(res))
      navigate("/upload-file/submit-file")
    }).catch(err => {
      if(err?.response?.errors)
      {
        toast.error(err.response.errors)
      }
      else if (err?.response?.data?.error)
      {
        toast.error(err.response.data.error)

      }
    })
  }

  const modal = useMemo(() => {
    if(info?.url)
    {
      return (
        <div className="custom-canvas-preview" id="custom_canvas_preview">
          <ModalPreview url={info.url} typeFile={info.file_name.slice(-3).toLowerCase()} />
        </div>
      )
    }
    return null
  }, [info?.url, info?.file_name])

  return (
    <>
     <Layout title="Upload File" footerColor="bg-warning" >
        <main className="container" role="main">
          <div className="text-center">
            <h1 className="mt-5">Upload Analysis</h1>
            <div className="d-inline-block" style={{width:"30%"}} >
              {
                info && Object.values(info.checks).includes(false) ?
                <>
                  <img className="my-4" style={{width: "59%"}} src={asset1}></img>
                  <h3 >There is something wrong with the file!</h3>
                </> :
                <>
                  <img className="my-4" style={{width: "59%"}} src={asset8}></img>
                  <h3>Well done. Your 3D model complies with the project constraints.</h3>
                </>
              }
            </div>
          </div>
          <div className="card-file-info">
            <div className="card-body">
              <h3 className="text-warning px-5">{fileName}</h3>
              <div className="row px-5">
                <div className="col-12 col-left">
                  {info?.url ? modal : <img className="img-fluid" src="https://via.placeholder.com/445?text=MODEL+PREVIEW"/>}
                  <span class='preview-note'>* Use your scroll wheel to zoom in and out</span>
                </div>
                <div className={`col-12 col-right ${isChecking && "d-flex justify-content-center align-items-center"}`}>
                  <div className="d-flex justify-content-start flex-column">
                    {loadedPercent ? (
                      <div>
                        {ProgressBarInstance}
                        <label>Uploading, please wait...</label>
                      </div>
                     ) : (
                      <>
                        <h3>Project Constraints Checklist</h3>
                        <hr/>
                        <ul className="list-unstyled">
                          <li className="project-constraint">
                            {info?.checks?.cost_per_print == true ?  <i className="ri-xl ri-check-line"/> : <i className="tooltip-icon ri-xl ri-close-line text-danger"/>}
                            <span className="project-constraint__title">Cost per Model Limit:
                            </span>
                            <strong>{project?.cost_per_print ? project.cost_per_print : 0}</strong>
                          </li>
                          <li className="project-constraint">
                          {info?.checks?.dimension_limit ?  <i className="ri-xl ri-check-line"/> : <i className="tooltip-icon ri-xl ri-close-line text-danger"/>}
                            <span className="project-constraint__title">Dimension Limits:
                            </span>
                            <strong>{project?.dimension_limit} (W {DIMENTION_LIMIT[project?.dimension_limit]?.width}, L {DIMENTION_LIMIT[project?.dimension_limit]?.length}, H {DIMENTION_LIMIT[project?.dimension_limit]?.height})</strong>
                          </li>
                          {/* <li className="project-constraint">
                            <span className="project-constraint__title">Student comments:</span>
                            <strong>{projectConstraints?.comment}</strong>
                          </li> */}
                          <li className="project-constraint">
                            <span className="project-constraint__title">Quantity:</span>
                            <strong>
                              <input id="number" type="number" step="1" min="1" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} />
                            </strong>
                          </li>
                        </ul>
                        <p className="printing-cost">Model Printing Cost:<strong
                            className="h3 ml-3">$ {printCost}</strong>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row px-5">
                <div className="col-12 col-left">
                  <div className="dragdrop" id="dragdrop" {...getRootProps()}>
                    <div
                      className="dragdrop__wrapper dragdrop--empty d-flex align-items-center">
                      <input id="ip-drop-zone" {...getInputProps({accept: ".obj, .stl"})} />
                      <i className="ri-4x ri-drag-drop-line dragdrop__icon"></i>
                      <div className="dragdrop__cta">
                        <p>To reupload, drop a 3D model file into this box or click
                          the button below.</p>
                        <label htmlFor="ip-drop-zone" className="btn btn-white dragdrop__cta">Upload File</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-right">
                  <textarea maxLength="100" className="form-control" id="file-info"
                    placeholder="Add personal notes (character limit 100)"
                    rows="6" value={info?.note} onChange={(e) => dispatch(updateInfoUploadFileInfo({...info, note: e.target.value}))}></textarea>
                </div>
              </div>
              <div className="btn-group text-right px-5">
                <a className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                  BACK</a>
                <button disabled={error || (info && info.checks.cost_per_print === false || info.checks.dimension_limit === false)} className="btn btn-primary" onClick={handleSubmit}>
                  SUBMIT</button>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default UploadAnalysis
