import React, { useState } from "react"
import Layout from "components/common/layout"
import CheckCodeProjectModal from 'components/student/upload_file/CheckCodeProjectModal'
import { Link } from "gatsby"
import pieChart from "assets/img/pie-chart.svg"
import asset3 from 'assets/img/asset_3.svg'
import asset4 from 'assets/img/asset_4.svg'
import asset5 from 'assets/img/asset_5.svg'
import asset6 from 'assets/img/asset_6.svg'
import asset7 from 'assets/img/asset_7.svg'

const Home =() => {
  const [isShowModalUploadFile, setIsShowModalUploadFile] = useState(false)

  return (
    <Layout title="Tinker Steps" footerColor="bg-warning">
      <main className="pb-0 home-index" role="main">
        <section className="bg-gray">
          <div className="container-fluid">
            <img style={{width:"80%"}} className="mb-5" src={asset3} />
            <h1>Tinker Steps</h1>
            <p className="fz-28 mb-4">
            3D printing service designed for schools
            </p>
            <a className="btn btn-primary" onClick={() => setIsShowModalUploadFile(true)}>
              STUDENT UPLOAD
            </a>
            <div className="section__desc">
              <p className="fz-24">
                Schools are a great place for kids to start learning through
                tinkering but sometimes, tinkering tools are out of reach for
                various reasons.
              </p>
              <p className="fz-24">
                <strong>
                  Our aim is to start lowering those barriers so all students
                  have access to all learning tools and we’re starting with 3D
                  printing!
                </strong>
              </p>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container-fluid">
            <div className="row feature feature--odd">
              <div className="col-md-6">
                <img
                  className="feature__img"
                  src={asset4}
                />
              </div>
              <div className="col-md-6">
                <p className="feature__desc">
                  Schools are a busy place and the last thing they need is to
                  have someone operate multiple 3D printers printing hundreds of
                  student projects.
                </p>
              </div>
            </div>
            <div className="row feature feature--even">
              <div className="col-md-6">
                <img
                  className="feature__img"
                  src={asset5}
                />
              </div>
              <div className="col-md-6">
                <p className="feature__desc">
                  Besides the expense of buying 5 or more printers, there are
                  ongoing labor, repair and running costs.
                </p>
              </div>
            </div>
            <div className="row feature feature--odd">
              <div className="col-md-6">
                <img
                  className="feature__img"
                  src={asset6}
                />
              </div>
              <div className="col-md-6">
                <p className="feature__desc">
                  Prints can fail and unless you have someone monitoring the
                  jobs, you could waste valuable time reprinting.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-blue-dark">
          <div className="container-fluid">
            <img className="mb-5" style={{width: "30%"}} src={asset7} />
            <h3 className="h1">
              So let Tinker Steps handle the stress of printing.
            </h3>
            <div className="section__desc pt-100">
              <p className="fz-28">
                Create a free account to start using our
                <br/>
                Classroom Print Manager which allows students to submit files to
                you and get instant print cost quotes.
              </p>
              <Link className="btn btn-primary" to="/signup">
                Create a Free Account
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-warning-bottom">
          <div className="container-fluid">
            <h3 className="h1 text-warning mb-5">
              Watch the video below for
              <br/>
              a quick run through on how it all works.
            </h3>
            <div className="embed-responsive embed-responsive-16by9 mw-960">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/pk5Oj_InYb0"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </section>
      </main>

      { isShowModalUploadFile && <CheckCodeProjectModal setIsShowModalUploadFile={setIsShowModalUploadFile} /> }

    </Layout>
  )
}

export default Home