import React from 'react'
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { callApi, SUBMIT_FILE} from "lib/api-server"
import { toast } from 'react-hot-toast'

const SubmitFileFinalStep = () => {
  const { info } = useSelector(state => state.fileUploadInfo)
  const handleSubmit = () => {
    callApi("PUT", SUBMIT_FILE.replace(":id", info.id), {
      first_name: info?.first_name, 
      last_name: info?.last_name, 
      submit: true
    }).then(res => {
      navigate("/upload-file/succes-upload-file")
    }).catch(err => {
      if(err?.response?.errors)
      {
        toast.error(err.response.errors)
      }
      else if (err?.response?.data?.error)
      {
        toast.error(err.response.data.error)
      }
    })
  }

  return (
    <>
      <h1 className="text-center">Submit File</h1>
      <ul className="nav nav-progress">
        <li className="nav-item">
          <a className="nav-link">REVIEW</a>
        </li>
        <li className="nav-item">
          <a className="nav-link">FORM</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active">SUBMIT</a>
        </li>
      </ul>
      <div className="card card-submit">
        <div className="card-body d-flex">
          <div className="card-left">
            <h3 className="card-title">Submission Summary</h3>
            <ul className="list-group list-group-submit">
              <li className="list-group-item">Name:<span className="ml-auto">{info?.first_name}.{info?.last_name}.</span>
              </li>
              <li className="list-group-item">School:<span className="ml-auto">{info?.project.school}</span>
              </li>
              <li className="list-group-item">Teacher:<span className="ml-auto">{info?.project.teacher}</span>
              </li>
              <li className="list-group-item">Class:<span className="ml-auto">{info?.project.class_name}</span>
              </li>
            </ul>
            <hr/>
            <ul className="list-group list-group-submit">
              <li className="list-group-item">Project Title:<span className="ml-auto">{info?.project.title}</span>
              </li>
              <li className="list-group-item">Project Due Date:<span className="ml-auto">{info?.project.due_date}</span>
              </li>
            </ul>
            <hr/>
            <ul className="list-group list-group-submit">
              <li className="list-group-item font-weight-bold">Total Print Time:<span className="ml-auto">{info?.total_print_time}</span>
              </li>
              <li className="list-group-item font-weight-bold">Total Grams Used:<span className="ml-auto">{info?.total_grams} g</span>
              </li>
              <li className="list-group-item font-weight-bold">Total Cost:<span className="ml-auto">{info?.total_cost}</span>
              </li>
            </ul>
            <hr/>
          </div>
          <div className="card-right">
            <a className="btn-next" onClick={() => handleSubmit()}>SUBMIT
              <i className="ri-xl ri-arrow-right-line"></i>
            </a>
            <a className="btn-back" onClick={() => navigate(-1)}>BACK
              <i className="ri-xl ri-arrow-left-line"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubmitFileFinalStep
