import React, { useState, useEffect, useRef } from "react"
import Layout from "components/common/layout"
import { getUser } from "services/auth"
import {
  GET_SUBJECTS_API,
  ADD_SUBJECTS_API,
  APPROVE_NEW_PROJECT_API,
  callApi,
} from "lib/api-server"
import ReactCodeInput from "react-code-input"
import ReactTooltip from "react-tooltip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import DatePicker from "react-datepicker"
import moment from "moment"
import CurrencyInput from 'react-currency-input-field';
import { Link } from "@reach/router"
import { DIMENTION_LIMIT } from "lib/scope"
import { handleToastCopy } from "lib/toast"

import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/pages/new-project.scss"

const TITLE_STEP = [
  "New Project - 1/3 Project Details",
  "New Project - 2/3 Project Details",
  "New Project - 3/3 Review",
]
const TITLE_BUTTON = ["NEXT", "REVIEW", "APPROVE"]

const NAME_FOR_VARIABLES = {
  name: "Project name",
  submission_date: "Submission deadline",
  class_name: "Class",
  teacher_name: "Teacher name",
  school: "School",
  cost_per_print: "Per student budget",
  dimension_limit: "Dimension limit",
  material: "Material",
  layer_height: "Layer height",
  infill: "Infill",
  total_budget: "Total Project Budget",
}

const ATTRIBUTES_STEP = [
  ["name", "submission_date", "class_name", "teacher_name", "school"],
  ["cost_per_print", "dimension_limit", "material", "layer_height", "infill", "total_budget"]
]

const NewProject = () => {
  const [step, setStep] = useState(0)
  const [teacherName, setTeacherName] = useState("")
  const [subjectList, setSubjectList] = useState([])
  const [formValue, setFormValue] = useState({
    name: "",
    submission_date: "",
    class_name: "",
    teacher_name: "",
    school: "",
    subject_ids: [],
    cost_per_print: "",
    dimension_limit: "Small",
    material: "PLA",
    layer_height: "0.2",
    infill: "10",
    total_budget: "",
  })
  const [isShowAddSubjectForm, setIsShowAddSubjectForm] = useState(false)
  const addSubjectInputRef = useRef(null)
  const [isAddNewSubjectLoading, setIsAddNewSubjectLoading] = useState(false)
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [projectCode, setProjectCode] = useState("")
  const [errors, setErrors] = useState({})

  const handleChangeFormValue = event => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  // Select subject Click
  const handleClickSubject = subjectId => {
    const currentSubjects = formValue.subject_ids
    const index = currentSubjects.indexOf(subjectId)

    if (index === -1) {
      setFormValue({ ...formValue, subject_ids: [...currentSubjects, subjectId] })
    } else {
      currentSubjects.splice(index, 1)
      setFormValue({ ...formValue, subject_ids: currentSubjects })
    }
  }

  // Add new subject
  const handleAddSubject = (e) => {
    e.preventDefault()

    const newSubject = addSubjectInputRef.current.value
    if (newSubject && !isAddNewSubjectLoading) {
      setIsAddNewSubjectLoading(true)
      callApi("POST", ADD_SUBJECTS_API, { name: newSubject })
        .then(res => {
          setSubjectList([...subjectList, res])
          setIsAddNewSubjectLoading(false)
          addSubjectInputRef.current.value = ""
        })
        .catch(err => {
          setIsAddNewSubjectLoading(false)
        })
    }
  }

  const handleApproveProject = () => {
    if (isApproveLoading) {
      return
    }

    setIsApproveLoading(true)
    setErrors({})

    const body = {
      name: formValue.name,
      submission_date: formValue.submission_date,
      class_name: formValue.class_name,
      cost_per_print: formValue.cost_per_print,
      dimension_limit: formValue.dimension_limit,
      material: formValue.material,
      layer_height: formValue.layer_height,
      total_budget: formValue.total_budget,
      subject_ids: formValue.subject_ids,
      school: formValue.school,
      teacher_name: formValue.teacher_name,
      infill: formValue.infill,
    }

    callApi("POST", APPROVE_NEW_PROJECT_API, body)
      .then(res => {
        setIsApproveLoading(false)
        setProjectCode(res.project_code)
      })
      .catch(err => {
        setIsApproveLoading(false)

        if (err.response.data.errors) {
          window.scrollTo({ top: 0, behavior: "smooth" })
          setErrors(
            err.response.data.errors.reduce(
              (obj, item) => ((obj[[item["field"]]] = item), obj),
              {}
            )
          )
        }
      })
  }

  useEffect(() => {
    const user = getUser()

    if (user.name) {
      setTeacherName(user.name)
      setFormValue({ ...formValue, teacher_name: user.name, school: user.school })
    }

    callApi("GET", GET_SUBJECTS_API)
      .then(res => {
        setSubjectList(res)
      })
  }, [])

  const handleChangeStep = () => {
    const newErrors = {}
    ATTRIBUTES_STEP[step].map(attribute => {
      if(formValue[attribute] === "")
      {
        newErrors[attribute] = { field: attribute, message: `${NAME_FOR_VARIABLES[attribute]} can't be blank` }
      }
    })

    if(Object.keys(newErrors).length  !== 0)
    {
      setErrors(newErrors)
    }
    else
    {
      setErrors({})
      setStep(step => step + 1)
    }
  }

  const Step1 = (
    <>
      <h6 className="mt-3 mb-0">Project Profile</h6>
      <hr className="mt-2 mb-4" />
      <div className="form-group">
        <label htmlFor="proj-name">Project Name *</label>
        <input
          className="form-control"
          type="text"
          id="proj-name"
          name="name"
          value={formValue.name}
          onChange={handleChangeFormValue}
          aria-describedby=""
          placeholder="Type Project Name"
        />
        {errors && errors["name"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["name"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proj-deadline">Project Submission Deadline *</label>
        <DatePicker
          wrapperClassName="form-control"
          placeholderText="DD/MM/YYYY"
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
          name="submission_date"
          onChange={value =>
            handleChangeFormValue({
              target: {
                value: moment(value).format("DD/MM/YYYY"),
                name: "submission_date",
              },
            })
          }
          value={formValue.submission_date.toString()}
          customInput={
            <input
              className="form-control"
              type="text"
              value={formValue.submission_date}
              onChange={handleChangeFormValue}
              id="proj-deadline"
              aria-describedby=""
            />
          }
        ></DatePicker>
        {errors && errors["submission_date"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["submission_date"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proj-class">Class *</label>
        <input
          className="form-control"
          type="text"
          id="proj-class"
          name="class_name"
          value={formValue.class_name}
          onChange={handleChangeFormValue}
          aria-describedby=""
          placeholder="Type Class"
        />
        {errors && errors["class_name"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["class_name"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proj-teacher">Teacher *</label>
        <input
          className="form-control"
          type="text"
          id="proj-teacher"
          name="teacher_name"
          value={formValue.teacher_name}
          onChange={handleChangeFormValue}
          aria-describedby=""
          placeholder="Type Teacher Name"
        />
        {errors && errors["teacher_name"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["teacher_name"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proj-school">School *</label>
        <input
          className="form-control"
          type="text"
          id="proj-school"
          name="school"
          value={formValue.school}
          onChange={handleChangeFormValue}
          aria-describedby=""
          placeholder="Type School"
        />
        {errors && errors["school"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["school"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="proj-subject">
          Subject
          <span
            className="tooltip-wrapper ml-1"
            data-title="Please click relevant subject"
          >
            <i className="tooltip-icon ri-question-fill" data-tip="Please click relevant subject"></i>
          </span>
        </label>
        <div className="tags">
          {subjectList.map(subject => (
            <span
              key={subject.id}
              className={`badge badge-outline-orange ${
                formValue.subject_ids.find(item => item === subject.id) && "active"
              }`}
              onClick={() => handleClickSubject(subject.id)}
            >
              {subject.name}
            </span>
          ))}
        </div>
      </div>
      <div className="form-group">
        {!isShowAddSubjectForm ? (
          <a
            className="text-primary"
            onClick={() => setIsShowAddSubjectForm(true)}
          >
            <i className="ri-xl ri-add-circle-fill mr-3"></i>Add Subject
          </a>
        ) : (
          <>
            <label htmlFor="proj-subject-add">Add Subject</label>
            <div className="d-flex align-items-center">
              <input
                ref={addSubjectInputRef}
                className="form-control"
                id="proj-subject-add"
                type="text"
              />
              <a className="text-primary ml-3" onClick={handleAddSubject}>
                {isAddNewSubjectLoading ? (
                  <span
                    className="spinner-border text-primary"
                    style={{ width: "1rem", height: "1rem" }}
                    role="status"
                  />
                ) : (
                  <i className="ri-xl ri-add-circle-fill"></i>
                )}
              </a>
            </div>
          </>
        )}
      </div>
    </>
  )

  const Step2 = (
    <>
      <h6 className="mt-3 mb-0">Project Constraints</h6>
      <hr className="mt-2 mb-4" />
      <div className="form-group">
        <label htmlFor="budget" className="position-relative">
          Per Student Budget
          <span
            className="tooltip-wrapper ml-1"
            data-title="Assign a $ amount per student"
          >
            <i className="tooltip-icon ri-question-fill"  data-tip="Assign a $ amount per student"></i>
          </span>
          <mark style={{color: '#e37d2b', background: 'white'}}>(Note this does not include gst)</mark>
        </label>
        <CurrencyInput
          className="form-control"
          id="input-example"
          name="cost_per_print"
          placeholder="Please enter a number"
          value={formValue.cost_per_print}
          decimalsLimit={2}
          onValueChange={(value, name) =>
            handleChangeFormValue({
              target: {
                name: name,
                value: value
              }})
            }
          placeholder="$"
          prefix="$ "
        />
        {errors && errors["cost_per_print"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["cost_per_print"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="limit">
          Dimension Limit
          <span className="tooltip-wrapper ml-1">
            <i className="tooltip-icon ri-question-fill" data-tip="Limit"></i>
          </span>
        </label>
        <select
          className="custom-select"
          aria-describedby="limitHelp"
          name="dimension_limit"
          value={formValue.dimension_limit}
          onChange={handleChangeFormValue}
        >
          <option value="Small">Small</option>
          <option value="Medium">Medium</option>
          <option value="Large">Large</option>
        </select>
        <small className="form-text text-muted ml-auto" id="limitHelp">
          <p className="text-right mb-0">
            Length(y) Limit:{" "}
            <span className="d-inline-block mr-4">
              {DIMENTION_LIMIT[formValue.dimension_limit].length}
            </span>{" "}
            Height(z) Limit:
            <span className="d-inline-block mr-4">
              {DIMENTION_LIMIT[formValue.dimension_limit].width}
            </span>{" "}
            Width(x) Limit:
            <span className="d-inline-block">
              {DIMENTION_LIMIT[formValue.dimension_limit].height}
            </span>
          </p>
        </small>
        {errors && errors["dimension_limit"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["dimension_limit"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="material">
          Material
          <span className="tooltip-wrapper ml-1">
            <i className="tooltip-icon ri-question-fill" data-tip="Material"></i>
          </span>
        </label>
        <select
          className="custom-select"
          name="material"
          value={formValue.material}
          onChange={handleChangeFormValue}
          aria-describedby=""
        >
          <option value="PLA">PLA</option>
        </select>
        {errors && errors["material"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["material"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="height">
          Layer Height
          <span className="tooltip-wrapper ml-1">
            <i className="tooltip-icon ri-question-fill" data-tip="Layer"></i>
          </span>
        </label>
        <select
          className="custom-select"
          name="layer_height"
          value={formValue.layer_height}
          onChange={handleChangeFormValue}
          aria-describedby=""
        >
          <option value="0.2">0.2 (Select this for fine detail models or final product)</option>
          <option value="0.3">0.3 (Select this for rough prototype or basic shaped models, this is faster and cheaper)</option>
        </select>
        {errors && errors["layer_height"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["layer_height"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="infill">
          Infill
          <span className="tooltip-wrapper ml-1">
            <i className="tooltip-icon ri-question-fill" data-tip="infill"></i>
          </span>
        </label>
        <select
          className="custom-select"
          name="infill"
          value={formValue.infill}
          onChange={handleChangeFormValue}
          aria-describedby=""
        >
          <option value="10">10%</option>
          {/* <option value="20">20%</option> */}
          {/* <option value="30">30%</option>
          <option value="40">40%</option>
          <option value="50">50%</option>
          <option value="60">60%</option>
          <option value="70">70%</option>
          <option value="80">80%</option>
          <option value="90">90%</option>
          <option value="100">100%</option> */}
        </select>
        {errors && errors["infill"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["infill"].message}
          </label>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="total">
          Total Project Budget
          <span
            className="tooltip-wrapper ml-1"
          >
            <i className="tooltip-icon ri-question-fill" data-tip="If the total weight is above 500g, the delivery is $30<br/> This will let you know
                if total cost is over budget limit in review window."></i>
          </span>
        </label>
        <CurrencyInput
            className="form-control"
            id="input-example"
            name="total_budget"
            placeholder="Please enter a number"
            value={formValue.total_budget}
            decimalsLimit={2}
            onValueChange={(value, name) =>
              handleChangeFormValue({
                target: {
                  name: name,
                  value: value
                }})
              }
            placeholder="$"
            prefix="$ "
          />
        {errors && errors["total_budget"] && (
          <label className="text-danger mt-2 mb-0">
            {errors["total_budget"].message}
          </label>
        )}
      </div>
    </>
  )

  const Step3 = (
    <>
      <h6 className="mt-3 mb-0">Project Summary</h6>
      <hr className="mt-2 mb-0" />
      <ul className="list-group list-group-flush list-group-review">
        <li className="list-group-item px-0 d-flex">
          Project Name:
          <strong className="ml-auto">{formValue.name}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Project Submission Deadline:
          <strong className="ml-auto">{formValue.submission_date}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          School:<strong className="ml-auto">{formValue.school}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Class:<strong className="ml-auto">{formValue.class_name}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Teacher:<strong className="ml-auto">{formValue.teacher_name}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Subject Covered:
          <strong className="ml-auto">
            {formValue.subject_ids
              .map(
                item => subjectList.find(subject => subject.id === item).name
              )
              .join(", ")}
          </strong>
        </li>
      </ul>
      <h6 className="mt-3 mb-0">Model Constraints</h6>
      <hr className="mt-2 mb-0" />
      <ul className="list-group list-group-flush list-group-review">
        <li className="list-group-item px-0 d-flex">
          Per Student Budget:
          <strong className="ml-auto">$ {formValue.cost_per_print}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Dimension Limit:
          <strong className="ml-auto">
            W {DIMENTION_LIMIT[formValue.dimension_limit].width}, L{" "}
            {DIMENTION_LIMIT[formValue.dimension_limit].length}, H{" "}
            {DIMENTION_LIMIT[formValue.dimension_limit].height}
          </strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Set Material:<strong className="ml-auto">{formValue.material}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Set Layer Height:
          <strong className="ml-auto">{formValue.layer_height}</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Set Infill:<strong className="ml-auto">{formValue.infill}%</strong>
        </li>
        <li className="list-group-item px-0 d-flex">
          Total Project Budget:
          <strong className="ml-auto">$ {formValue.total_budget}</strong>
        </li>
      </ul>
    </>
  )

  return (
    <Layout title="New Project">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>

      <main className="container" role="main">
        <div className="d-flex align-items-center page-title text-dark-blue">
          <h1 className="ml-3 mb-0">
            Hello, {teacherName ? teacherName : "Teacher"}
          </h1>
          <div className="btn-wrapper ml-auto">
            <Link className="page-title__link" to="/projects">
              <span className="btn btn-icon btn-outline-warning rounded-circle bg-white">
                <i className="ri-xl ri-arrow-left-line"></i>
              </span>
              Back to Projects
            </Link>
          </div>
        </div>
        <div className="row row-summary">
          <div className="col-12 col-summary-left"></div>
          <div className="col-12 col-summary-right"></div>
        </div>
        <div className="card card-content-wrapper">
          <div className="card-header border-0 pb-0">
            <h2 className="text-warning mb-0">{TITLE_STEP[step]}</h2>
          </div>
          {errors && step === 2 && (
            <div className="border-0 pb-0 mt-4">
              {Object.keys(errors).map((key, index) => (
                <p key={index} className="text-danger mb-0">
                  {errors[key].message}
                </p>
              ))}
            </div>
          )}
          <div className="card-body">
            {step === 0 ? Step1 : step === 1 ? Step2 : Step3}
          </div>
        </div>
        <div className="text-right">
          <div className="btn-group">
            {step !== 0 && (
              <a
                className="btn btn-outline-primary"
                onClick={() => setStep(0)}
              >
                {step === 1 ? "BACK" : "EDIT"}
              </a>
            )}

            <a
              className="btn btn-primary"
              onClick={() => {
                if (step < 2) {
                  // setStep(step => step + 1)
                  handleChangeStep()
                } else {
                  handleApproveProject()
                }
              }}
            >
              {!isApproveLoading ? (
                TITLE_BUTTON[step]
              ) : (
                <span
                  className="spinner-border"
                  style={{ width: "1rem", height: "1rem" }}
                  role="status"
                />
              )}
            </a>
          </div>
        </div>
      </main>
      {projectCode && (
        <>
          <div
            className="modal show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-noti"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <h1 className="modal-title">Project is now live!</h1>
                  <p className="mb-0">
                    Please ask students to enter the code below
                    <br />
                    when submitting their files.
                  </p>
                  <ReactCodeInput
                    value={projectCode}
                    className="form-row justify-content-center"
                    type="text"
                    fields={6}
                    disabled
                  />
                  <div className="btn-group">
                    <CopyToClipboard
                      text={projectCode}
                      onCopy={handleToastCopy}
                    >
                      <span className="btn btn-outline-primary">Copy Code</span>
                    </CopyToClipboard>
                    <Link className="btn btn-primary" to="/projects">
                      Done
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop"></div>
        </>
      )}
    </Layout>
  )
}

export default NewProject
