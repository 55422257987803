import { LOGIN_API, callApi, ADMIN_LOGIN_API } from "lib/api-server"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {}

const setUser = user =>
  window.localStorage.setItem("user", JSON.stringify(user))

export const handleLogin = ({ email, password, admin }) => {
  let body = { email: email, password: password }
  
  const loginLink = admin ? ADMIN_LOGIN_API : LOGIN_API

  return callApi("POST", loginLink, body)
    .then(res => {
      setUser(res)
      return res
    })
    .catch(err => {
      return err.response.data
    })
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.token
}

export const logout = callback => {
  window.localStorage.clear()
  if (callback) {
    callback()
  }
}
