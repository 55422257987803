import React, { useState, useEffect } from "react"
import { TEACHER_API, callApi } from "lib/api-server"
import { getUser } from "services/auth"
import { Link } from "gatsby"
import Layout from "components/common/layout"
import Pagination from "rc-pagination"
import BeatLoader from "react-spinners/BeatLoader"
import ReactTooltip from "react-tooltip"

import "rc-pagination/assets/index.css"
import "assets/scss/pages/teacher.scss"

const PER = 10
const AdminTeacherList = props => {
  const [teachers, setTeachers] = useState([{}])
  const [pagination, setPagination] = useState({
    per_page: 10,
    total_objects: 0,
    total_pages: 1,
    current_page: 1,
  })
  const [loading, setLoading] = useState(true);

  const getTeachers = () => {
    setLoading(true)
    callApi(
      "GET",
      `${TEACHER_API}?search=${searchTerm}&page=${pagination.current_page}&per=${pagination.per_page}`
    )
      .then(res => {
        setLoading(false)
        setTeachers(res.data)
        setPagination(res.meta.pagination)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const onChangePage = page => {
    pagination.current_page = page
    setPagination({ ...pagination })
    getTeachers()
  }
  //Search
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
      getTeachers()
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  useEffect(() => {
    let delayDebounceFn
    if(searchTerm)
    {
      delayDebounceFn = setTimeout(() => {
        getTeachers()
      }, 500)
    }
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <>
      <Layout title="Teachers">
        <div id="background">
          <div className="circle circle--top"></div>
          <div className="circle circle--bottom"></div>
        </div>
        <main className="container" role="main">
          <div className="d-flex align-items-center page-title">
            <span className="page-title__icon bg-white">
              <i className="ri-xl ri-user-fill text-warning"></i>
            </span>
            <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
            <div className="btn-wrapper ml-auto">
              <Link className="btn btn-primary" to="/admin/teachers/new">
                Create
              </Link>
            </div>
          </div>
          <div className="card card-content-wrapper">
            <div className="card-header">
              <h2 className="text-warning mb-0">Teachers</h2>
              <form>
                <input
                  className="form-control form-control--content-search"
                  type="text"
                  placeholder="Search (School, Teacher)"
                  value={searchTerm}
                  onChange={e => {
                    setSearchTerm(e.target.value)
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      getTeachers(1, PER)
                    }
                  }}
                />
              </form>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <BeatLoader loading={loading} size={10} color="#E37D2B"/>
                <table className="table table-align-center">
                  <thead>
                    <tr>
                      <th scope="col">Teacher</th>
                      <th scope="col">School</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teachers?.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <Link to={`/admin/teachers/${item.id}`}>
                            {item.name}
                          </Link>
                        </th>
                        <td>{item.school_name}</td>
                        <td>
                          {item.email}
                          {item.is_confirmed ? (
                            <span className="ml-1">
                              <i className="fa fa-check-circle text-success"></i>
                            </span>
                          ) : (
                            <span
                              className="tooltip-wrapper ml-1"
                              data-title="Email not verified"
                            >
                              <i className="tooltip-icon fa fa-check-circle" data-tip="Email not verified"></i>
                            </span>
                          )}
                        </td>
                        <td>{item?.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {pagination.total_pages > 1 && (
              <nav className="mt-auto" aria-label="...">
                <ul className="pagination justify-content-center">
                  <Pagination
                    pageSize={pagination.per_page}
                    total={pagination.total_objects}
                    onChange={page => {
                      onChangePage(page)
                    }}
                    current={pagination.current_page}
                  />
                </ul>
              </nav>
            )}
          </div>
        </main>
      </Layout>
    </>
  )
}
export default AdminTeacherList
