import React, { useState, useEffect } from 'react';
import Layout from "components/common/layout"

import { getUser } from "services/auth"
import { Link } from "@reach/router"

import { GET_LESSON_PLAN_API, GET_FILTER_TAGS_API, callApi } from "lib/api-server"

import Pagination from 'rc-pagination';
import Select from 'react-select'
import BeatLoader from "react-spinners/BeatLoader";

// css
import 'rc-pagination/assets/index.css';
import "assets/scss/pages/lesson-plan.scss"

const LessonPlans = (props) => {
  const lessonPath = props.admin ? '/admin/resources' : '/resources'

  let [loading, setLoading] = useState(true);

  const [dataLessonPlan, setDataLessonPlan] = useState([])

  const [pagination, setPagination] = useState({
    per_page: 10,
    current_page: 1,
    total_pages: 1,
    total_objects: 0,
  });

  const getLessonPlans = () => {
    setLoading(true)

    let url = `${GET_LESSON_PLAN_API}?filter_tags=${filterTags.map(item => item.value)}&page=${pagination.current_page}`
    if(sortDown) {
      url = `${url}&sort_down=${sortDown}`
    }

    callApi("GET", url)
      .then(data => {
        setDataLessonPlan(data.data)
        setPagination(data.meta.pagination)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const onChangePage = (page) => {
    pagination.current_page = page
    setPagination({...pagination})

    getLessonPlans()
  }

  // filter
  const [options, setOptions] = useState([{}])
  const [filterTags, setFilterTags] = useState([])
  const [sortDown, setSortDown] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])

  useEffect(() => {
    callApi("GET", GET_FILTER_TAGS_API)
      .then(data => {
        const tags = data?.map((item) =>(
          {value: item, label: item}
        ))
        setOptions(tags)
      })
      .catch(err => console.log(err))
  }, [])

  const handleFilterTags = (tags) => {
    setFilterTags(tags)
  }

  const handleSortDown = () =>{
    setSortDown(!sortDown)
  }

  useEffect(() => {
    getLessonPlans()
  },[filterTags, sortDown])

  return (
    <Layout title="Resources">
      <div id="background">
        <div className="circle circle--top"></div>
        <div className="circle circle--bottom"></div>
      </div>
      <main className="container" role="main">
        <div className="d-flex align-items-center page-title">
          <span className="page-title__icon bg-white">
            <i className="ri-xl ri-user-fill text-warning"></i>
          </span>
          <h1 className="ml-3 mb-0">Hello, {getUser().name}</h1>
          {props.admin && (
            <div className="btn-wrapper ml-auto">
              <Link to={`/admin/resources/new`}>
                <a className="btn btn-primary">Create</a>
              </Link>
            </div>
          )}
        </div>
        <div className="card card-content-wrapper">
          <div className="card-header">
            <h2 className="text-warning mb-0">Resources</h2>
            <form>
              <div className="input-group">
                  <Select
                    placeholder="Filter by tags"
                    value={filterTags}
                    onChange={handleFilterTags}
                    options={options}
                    isMulti
                    // name="colors"
                    className="form-control custom-react-select basic-multi-select"
                    classNamePrefix="select"
                  />
                <div className="input-group-append">
                  <a className={sortDown ? "input-group-text active" : "input-group-text" } onClick={handleSortDown}>sort down</a>
                </div>
              </div>
            </form>
          </div>
          <div className="card-body">
            <BeatLoader loading={loading} size={10} color="#E37D2B"/>

            <ul className="list-unstyled list-article">
              { dataLessonPlan?.map((lesson, index) => (
                  <li className="media media-article" key={index}>
                  <Link to={`${lessonPath}/${lesson.id}`}>
                    <img
                      src={lesson.thumb_image.url}
                      alt=""
                    />
                  </Link>

                  <div className="media-body">
                    <h5 className="media__title">
                      <Link to={`${lessonPath}/${lesson.id}`} className="link-black">
                        {lesson?.title}
                      </Link>
                    </h5>
                    <p>
                      {lesson?.description}
                    </p>
                    <div className="tags">
                      {
                        lesson.tag_list?.map((childItem, index) => (
                          <a className="badge badge-warning badge-pill badge-md" key={index}>
                            {childItem}
                          </a>
                        )
                      )}

                    </div>
                  </div>
                </li>
                )
              )}

            </ul>
          </div>

          {pagination.total_pages > 1 && (
            <nav className="mt-auto" aria-label="...">
              <ul className="pagination justify-content-center">
                <Pagination
                  pageSize={pagination.per_page}
                  total={pagination.total_objects}
                  onChange={(page) => {
                    onChangePage(page)
                  }}
                  current={pagination.current_page}
                />
              </ul>
            </nav>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default LessonPlans;