import React from 'react'
import { useLoader } from "@react-three/fiber"
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

const MAP_TYPE_FILE = {
  stl: STLLoader,
  obj: OBJLoader
}

const Model = ({ url, typeFile }) => {
  const TypeLoad = MAP_TYPE_FILE[typeFile]
  const gltf = useLoader(TypeLoad, url)

  if(url?.slice(-3).toLowerCase() === "stl")
  {
    return (
      <mesh visible geometry={gltf} receiveShadow >
        <meshStandardMaterial  attach="material" color='white'/>
      </mesh>
    )
  }

  return <primitive object={gltf} />
};

export default Model