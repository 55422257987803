import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "@reach/router"
import { handleLogin, isLoggedIn, getUser } from "services/auth"
import { VALID_EMAIL } from "lib/validate"
import { callApi, VERIFY_EMAIL, GET_NEW_VERIFY_CODE } from "lib/api-server"

import toast from "react-hot-toast"
import ReactTooltip from "react-tooltip"
import VerifyEmailPopup from "components/common/VerifyEmailPopup"

import "assets/scss/pages/login.scss"

const LoginForm = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState()

  const [isShowModal, setIsShowModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [inform, setInform] = useState(
    "Verify code was sent, let check in your email"
  )
  const [isGetNewVerifyCodeLoading, setIsGetNewVerifyCodeLoading] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    setIsLoading(true)

    handleLogin({ email: email, password: password }).then(res => {
      if (res.errors) {
        setIsLoading(false)
        setErrors(res.errors.reduce((obj, item) => ((obj[[item["field"]]] = item["message"]), obj), {}))
      } else if (!res.is_verified) {
        setIsLoading(false)
        setIsShowModal(true)
      }
      else {
        toast.success("You are successfully logged in")
        navigate("/dashboard")
      }
    })
  }

  const sendGetNewVerifyCodeAPI = async () => {
    const response = callApi("GET", `${GET_NEW_VERIFY_CODE}`)
    return response
  }

  const handleGetNewVerifyCode = () => {

    if (isGetNewVerifyCodeLoading) {
      return
    }

    setIsGetNewVerifyCodeLoading(true)
    setInform("")
    setIsError(false)

    sendGetNewVerifyCodeAPI()
      .then(res => {
        setIsGetNewVerifyCodeLoading(false)
        setInform(res.message)
      })
      .catch(err => {
        setIsGetNewVerifyCodeLoading(false)
      })
  }

  useEffect(() => {
    const isEmailValid = VALID_EMAIL.test(email) || email.length === 0

    if (!isEmailValid) {
      setErrors(errors => {return {...errors, email: "Invalid email"}})
    }
    else if(errors && errors.email){
      delete errors.email
      setErrors({...errors})
    }

    if(errors && errors.password)
    {
      delete errors.password
      setErrors({...errors})
    }

  }, [email])

  useEffect(() => {
    const isPasswordValid = password.length >= 6 || email.length === 0

    if (!isPasswordValid) {
      setErrors(errors => {return {...errors, password: "Password must be at least 6 characters"}})
    } else if(errors && errors.password){
      delete errors.password
      setErrors({...errors})
    }

  }, [password])

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/dashboard")
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild();
  },[errors]);

  const handleVerifyEmail = (code) => {

    if (isVerifyLoading) {
      return
    }

    setIsVerifyLoading(true)
    setIsError(false)
    setInform("")

    callApi("POST", VERIFY_EMAIL, { verify_code: code })
      .then(res => {

        if (res.errors) {
          setIsError(true)
          setIsVerifyLoading(false)
        } else {
          const user = getUser()
          user.is_verified = true
          window.localStorage.setItem("user", JSON.stringify(user))
          toast.success("You are successfully logged in")
          navigate("/dashboard")
        }
      })
      .catch(err => {
        setIsError(true)
        setIsVerifyLoading(false)
      })
  }

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className="form-group text-left">
          <label htmlFor="email">Email</label>
          <div className="input-group">
            <input
              className="form-control"
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            {email != "" && (
              <div className="input-group-append">
                <span className="input-group-text">
                  <span
                    className="tooltip-wrapper ml-1"
                    data-title="Invalid email"
                  >
                    {email != "" &&
                      (errors && errors["email"] ? (
                        <>
                          <i className="tooltip-icon ri-xl ri-close-line text-danger" data-tip={errors["email"]}></i>
                        </>
                      ) : (
                        <i className="ri-xl ri-check-line"></i>
                      ))}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="form-group text-left">
          <label htmlFor="#pword">Password</label>
          <div className="input-group">
            <input
              className="form-control"
              id="pword"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            {password !== "" && (
              <div className="input-group-append">
                <span className="input-group-text">
                  <span
                    className="tooltip-wrapper ml-1"
                    data-title="Sorry, wrong password"
                  >
                    {password !== "" &&
                      (errors && errors["password"] ? (
                        <>
                          <i className="tooltip-icon ri-xl ri-close-line text-danger" data-tip={errors["password"]}></i>
                        </>
                      ) : (
                        <i className="ri-xl ri-check-line"></i>
                      ))}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <Link className="text-body underline" to="/forgot-password">
            Forgot password?
          </Link>
          <button
            className="btn btn-primary"
            disabled={errors && Object.keys(errors).length > 0}
            type="submit"
          >
            {!isLoading ? (
              `SIGN IN`
            ) : (
              <span
                className="spinner-border"
                style={{ width: "1rem", height: "1rem" }}
                role="status"
              ></span>
            )}
          </button>

          <p className="text-primary">
            New to Tinker Steps?
            <Link to="/signup" className="btn btn-link font-weight-bold">
              SIGN UP
            </Link>
          </p>
        </div>
      </form>
      {
        isShowModal &&
        <VerifyEmailPopup
          isError={isError}
          inform={inform}
          handleVerifyEmail={handleVerifyEmail}
          handleGetNewVerifyCode={handleGetNewVerifyCode}
          isVerifyLoading={isVerifyLoading}
          handleCancel={() => setIsShowModal(false)}
          isGetNewVerifyCodeLoading={isGetNewVerifyCodeLoading}
          forSigninPage={true}
        />
      }
    </>
  )
}

export default LoginForm
