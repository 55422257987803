import React, { useState } from "react"
import { ADMIN_COUPON_API, callApi } from "lib/api-server"
import CurrencyInput from "react-currency-input-field"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ValidateForm from "../../common/ValidateForm"
import toast from "react-hot-toast"
import { useSelector, useDispatch } from 'react-redux'
import { setCouponList } from 'actions'
import {COUPON_TYPE_ENUM} from 'src/lib/scope'

const COUPON_DATA_INIT = {
  code: "",
  couponType: "percent",
  value: "",
}

const AddCouponForm = () => {
  const [couponData, setCouponData] = useState({
    ...COUPON_DATA_INIT,
    couponExpirationDate: null,
  })
  const [isAddCouponLoading, setIsAddCouponLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const coupons = useSelector(state => state.coupons)

  const resetCouponForm = () => {
    setCouponData({ ...COUPON_DATA_INIT, couponExpirationDate: null })
  }

  const handleAddCoupon = e => {
    e.preventDefault()

    if (!isAddCouponLoading) {
      setIsAddCouponLoading(true)

      const formData = new FormData()
      formData.append("coupon_type", couponData.couponType)
      formData.append("expiration_date", couponData.couponExpirationDate)
      formData.append("value", couponData.value)
      formData.append("code", couponData.code)

      callApi("POST", ADMIN_COUPON_API, formData)
        .then(res => {
          toast.success("Coupon is added successfully")
          setIsAddCouponLoading(false)
          dispatch(setCouponList([...coupons, res].sort((a, b) => { return new Date(a.expiration_date) - new Date(b.expiration_date) })))
          resetCouponForm()
        })
        .catch(err => {
          if (err.response.data && err.response.data.errors) {
            setErrors(
              err.response.data.errors.reduce(
                (obj, item) => ((obj[[item["field"]]] = item), obj),
                {}
              )
            )
          }

          setIsAddCouponLoading(false)
        })
    }
  }

  return (
    <form autoComplete="off" onSubmit={e => handleAddCoupon(e)}>
      <div className="form-group">
        <label htmlFor="code">Coupon Code</label>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            id="code"
            name="code"
            aria-describedby=""
            value={couponData.code}
            onChange={e => {
              delete errors.code
              setCouponData({ ...couponData, code: e.target.value })
            }}
          />
          {errors && errors["code"] && (
            <ValidateForm
              dataTitle={errors["code"].message}
              isError={errors["code"].message}
            />
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="coupon-type">Coupon Type</label>
        <select
          className="custom-select"
          type="text"
          id="coupon-type"
          aria-describedby=""
          value={couponData.couponType}
          onChange={e => {
            delete errors.value
            if (e.target.value === "1" && Number(couponData.value > 100)) {
              setCouponData({
                ...couponData,
                couponType: e.target.value,
                value: 100,
              })
            } else {
              setCouponData({ ...couponData, couponType: e.target.value })
            }
          }}
        >
          <option value={'percent'}>Percentage</option>
          <option value={'fixed'}>Fixed Price</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="coupoun-value">
          Coupon value
          <span
            className="tooltip-wrapper ml-1"
            data-title="Assign a value to coupon"
          ></span>
        </label>
        <div className="input-group">
          <CurrencyInput
            className="form-control"
            id="coupoun-value"
            name="coupoun_value"
            placeholder="Please enter a number"
            value={couponData.value}
            allowDecimals={false}
            onValueChange={value => {
              delete errors.value
              if (couponData.couponType === "percent" && Number(value) > 100) {
                setCouponData({ ...couponData, value: 100 })
              } else {
                setCouponData({ ...couponData, value: value ? value : "" })
              }
            }}
            placeholder={couponData.couponType === "percent" ? "%" : "$"}
            prefix={couponData.couponType === "percent" ? "% " : "$ "}
          />
          {errors && errors["value"] && (
            <ValidateForm
              dataTitle={errors["value"].message}
              isError={errors["value"].message}
            />
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="expiration-date">Expiration Date Coupon</label>
        <div className="input-group">
          <DatePicker
            wrapperClassName="form-control"
            placeholderText="DD/MM/YYYY h:mm aa"
            dateFormat="dd/MM/yyyy h:mm aa"
            minDate={new Date()}
            excludeOutOfBoundsTimes
            name="expirision_date"
            showTimeSelect
            onChange={value => {
              delete errors.expiration_date
              setCouponData({ ...couponData, couponExpirationDate: value })
            }}
            selected={couponData.couponExpirationDate}
            customInput={
              <input
                className="form-control"
                type="text"
                id="expiration-date"
                aria-describedby=""
                style={
                  errors &&
                  errors["expiration_date"] && {
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    borderRight: "0",
                  }
                }
              />
            }
          />
          {errors && errors["expiration_date"] && (
            <ValidateForm
              dataTitle={errors["expiration_date"].message}
              isError={errors["expiration_date"].message}
            />
          )}
        </div>
      </div>
      <button className="btn btn-primary" type="submit">
        {isAddCouponLoading ? (
          <span
            style={{ width: "1rem", height: "1rem" }}
            className="spinner-border text-primary"
            role="status"
          />
        ) : (
          `ADD`
        )}
      </button>
    </form>
  )
}

export default AddCouponForm
