import React, { useState, useEffect } from "react"
import ReactTooltip from "react-tooltip"

const ValidateForm = props => {
  const { dataTitle, tooltipStyle, isError } = props

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="input-group-append">
      <span className="input-group-text">
        <span className="tooltip-wrapper ml-1" data-title={dataTitle}>
          {isError ? (
            <>
              <i className="tooltip-icon ri-error-warning-fill text-danger" data-tip={props.dataTitle}></i>
            </>
          ) : (
            <i className="ri-xl ri-check-line"></i>
          )}
        </span>
      </span>
    </div>
  )
}

export default ValidateForm
