import fileUploadInfoReducer from './fileUploadInfoReducer'
import zoomReducer from './zoomReducer'
import couponsReducer from './couponsReducer'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  fileUploadInfo: fileUploadInfoReducer,
  zoom: zoomReducer,
  coupons: couponsReducer
})

export default rootReducer