import React, { useState, useEffect } from "react"
import { VALID_EMAIL } from "lib/validate"
import { TEACHER_API, GET_SCHOOLS_API, callApi } from "lib/api-server"
import Layout from "components/common/layout"
import { navigate, Link } from "gatsby"
import TeacherProjectForm from "components/common/form/TeacherProjectForm"

const TeacherCreate = props => {
  const [messageErrors, setMessageErrors] = useState({})
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    repeat_password: "",
    school_address: ""
  })

  const [schoolList, setSchoolList] = useState([])
  const [selectedSchool, setSelectedSchool] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isShow, setIsShow] = useState({
    isShowPassword: false,
    isShowRepeatPassword: false,
  })

  useEffect(() => {
    callApi("GET", GET_SCHOOLS_API)
      .then(data => {
        setSchoolList(data)
      })
  }, [])

  useEffect(() => {
    if (selectedSchool && schoolList && schoolList.length > 0) {
      setData({
        ...data,
        school_address: schoolList.find(item => item.id === selectedSchool)
          ?.address,
      })
    }
  }, [selectedSchool, schoolList])

  useEffect(() => {
    if (data.email !== "") {
      if (!VALID_EMAIL.test(data.email) && !messageErrors?.emailMessage) {
        setMessageErrors({
          ...messageErrors,
          emailMessage: "Email has to be in a format of .edu.au or edu.",
        })
      } else if (VALID_EMAIL.test(data.email) && messageErrors.emailMessage) {
        delete messageErrors.emailMessage
        setMessageErrors({...messageErrors})
      }
    }
  }, [data.email])

  const handleSubmit = e => {
    e.preventDefault()

    if (isLoading) {
      return
    }

    if (data.password.length < 6) {
      setMessageErrors({
        ...messageErrors,
        passwordMessage:
          "The password must be at least 6",
      })
      return
    }

    if (data.password !== data.repeat_password) {
      setMessageErrors({
        ...messageErrors,
        repeatPasswordMessage:
          "The repeat password must be equal to the password",
      })
      return
    }

    let body = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      school_id: selectedSchool,
      school_address: data.school_address,
      password: data.password,
      password_confirmation: data.repeat_password,
    }

    setIsLoading(true)

    callApi("POST", TEACHER_API, body)
      .then(res => {
        navigate("/admin/teachers")
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response) {
          const fieldErrors = err.response.data.errors

          fieldErrors.forEach(item => {
            switch (item.field) {
              case "first_name":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    firstNameMessage: item.message,
                  }
                })
                break
              case "last_name":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    lastNameMessage: item.message,
                  }
                })
                break
              case "phone":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    contactMessage: item.message,
                  }
                })
                break
              case "school_address":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    schoolAddressMessage: item.message,
                  }
                })
                break
              case "email":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    emailMessage: item.message,
                  }
                })
                break
              case "password":
                setMessageErrors(messageErrors =>{
                  return {
                    ...messageErrors,
                    passwordMessage: item.message,
                  }
                })
              case "school_id":
              setMessageErrors(messageErrors =>{
                return {
                  ...messageErrors,
                  schoolIDMessage: item.message,
                }
              })
            }
          })
        }
      })
  }

  return (
    <>
      <Layout title="Account Setting" footerColor="bg-warning">
        <main className="bg-white " role="main">
          <div className="container">
            <div className="text-center">
              <h1 className="page-title">Teacher Profile</h1>
              <p className="pb-5">
                Change your personal details
                <br />
                and account settings.
              </p>
            </div>
            <TeacherProjectForm 
              data={data}
              setData={setData}
              messageErrors={messageErrors}
              isShow={isShow}
              setIsShow={setIsShow}
              schoolList={schoolList}
              selectedSchool={selectedSchool}
              setSelectedSchool={setSelectedSchool}
              isCreate={true}
            />
            <div className="btn-group text-center form-cta">
              <Link className="btn btn-outline-primary" to="/admin/teachers">
                BACK
              </Link>
              <a className="btn btn-primary" onClick={handleSubmit}>
                {isLoading ? (
                  <span
                    className="spinner-border"
                    style={{ width: "1rem", height: "1rem" }}
                    role="status"
                  ></span>
                ) : (
                  `SAVE`
                )}
              </a>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default TeacherCreate
