import React, { useState, useEffect } from "react"
import Layout from "../common/layout"
import { navigate } from "gatsby"
import ValidateForm from "components/common/ValidateForm"
import { RESET_PASSWORD_API, callApi } from "lib/api-server"
import { useParams } from "@reach/router"
import toast from "react-hot-toast"

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [messageErrors, setMessageErrors] = useState({})
  const [isShow, setIsShow] = useState({
    isShowPassword: false,
    isShowRepeatPassword: false,
  })
  const { token } = useParams()

  const handleSendChangePassword = event => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    setIsLoading(true)
    const body = {
      password: password,
      password_confirmation: repeatPassword,
      reset_password_token: token,
    }

    callApi("PUT", RESET_PASSWORD_API, body)
      .then(res => {
        setIsLoading(false)
        toast.success("Password is updated. Please try to sign in")
        navigate("/login")
      })
      .catch(err => {
        const data = err.response.data
        setIsLoading(false)

        if (err.response) {
          const fieldErrors = err.response.data.errors

          if(fieldErrors) {
            fieldErrors.forEach(item => {
              switch (item?.field) {
                case "password":
                  setMessageErrors({
                    ...messageErrors,
                    passwordMessage: item.message,
                  })
                  break
                case "password_confirmation":
                  setMessageErrors({
                    ...messageErrors,
                    repeatPasswordMessage: item.message,
                  })
                  break
              }
            })

          }
        }
      })
  }

  return (
    <Layout title="Forgot Password">
      <div className="container-fluid" id="background">
        <div className="row h-100">
          <div className="col-md-8 bg-blue-dark h-100">1</div>
          <div className="col-md-4 bg-yellow-dark h-100">2</div>
        </div>
      </div>
      <main className="container" role="main">
        <div className="row align-items-md-end">
          <div className="col-md-4 col-lg-5">
            <h1>Tinker Steps</h1>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Obcaecati corporis ad soluta eos odio.
            </p>
          </div>
          <div className="col-md-6">
            <div className="card text-center card-signin">
              <div className="card-body">
                <h2 className="h1">Change your password</h2>
                <p className="my-3 my-lg-5">Please enter your new password</p>
                <div className="form-group text-left">
                  <label htmlFor="#repword">New Password</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="repword"
                      type={isShow.isShowPassword ? "text" : "password"}
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value)
                        delete messageErrors.passwordMessage
                      }}
                    />

                    {messageErrors.passwordMessage ? (
                      <ValidateForm
                        dataTitle={messageErrors.passwordMessage}
                        isError={true}
                      />
                    ) : (
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {isShow.isShowPassword ? (
                            <i
                              className="ri-lg ri-eye-off-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowPassword: !isShow.isShowPassword,
                                })
                              }}
                            />
                          ) : (
                            <i
                              className="ri-lg ri-eye-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowPassword: !isShow.isShowPassword,
                                })
                              }}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group text-left">
                  <label htmlFor="#repword">Repeat New Password</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="repword"
                      type={isShow.isShowRepeatPassword ? "text" : "password"}
                      value={repeatPassword}
                      onChange={e => {
                        setRepeatPassword(e.target.value)
                        delete messageErrors.repeatPasswordMessage
                      }}
                    />

                    {messageErrors.repeatPasswordMessage ? (
                      <ValidateForm
                        dataTitle={messageErrors.repeatPasswordMessage}
                        isError={true}
                      />
                    ) : (
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {isShow.isShowRepeatPassword ? (
                            <i
                              className="ri-lg ri-eye-off-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowRepeatPassword: !isShow.isShowRepeatPassword,
                                })
                              }}
                            />
                          ) : (
                            <i
                              className="ri-lg ri-eye-line text-warning"
                              onClick={() => {
                                setIsShow({
                                  ...isShow,
                                  isShowRepeatPassword: !isShow.isShowRepeatPassword,
                                })
                              }}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <a
                    className="btn btn-primary"
                    onClick={handleSendChangePassword}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border"
                        style={{ width: "1rem", height: "1rem" }}
                        role="status"
                      />
                    ) : (
                      "RESET"
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ForgotPassword
