import React from "react"
import Layout from "../common/layout"

const Pricing = () => {
  return (
    <Layout title="Pricing" footerColor="bg-warning">
      <main className="pb-0 layout-page" role="main">
          <div className="container">
            <h1>Pricing</h1>
            <p>
              We calculate the cost of our prints by using the time it takes to print. Print time will vary depending on the settings you choose. 
            </p>
            <table className='table table-bordered text-left'>
              <tbody>
                <tr>
                  <td><b>Cost per hr</b></td>
                  <td>$4.00 (GST ex)</td>
                </tr>

                <tr>
                  <td><b>Delivery</b></td>
                  <td>
                    Free if total weight is under 500g. 
                    <br/>
                    Over 500g incurs a $15 fee. 
                  </td>
                </tr>

                <tr>
                  <td><b>Set up costs</b></td>
                  <td>
                    $30 per class project. This includes all support removal and STL file conversions. 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </main>
    </Layout>
  )
}

export default Pricing;