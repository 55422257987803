export const updateProjectUploadFileInfo = (project) => {
  return {
    type: "UPDATE_PROJECT",
    payload: {
      project: project
    }
  }
}

export const updateInfoUploadFileInfo = (info) => {
  return {
    type: "UPDATE_INFO",
    payload: {
      info: info
    }
  }
}

export const changeZoom = (zoom) => {
  return {
    type: "CHANGE_ZOOM",
    payload: zoom
  }
}

export const setCouponList = (coupons) => {
  return {
    type: "SET_COUPONS",
    payload: coupons
  }
}