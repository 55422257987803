import React, { useState, useEffect, useCallback } from "react"
import { SETTING_ADMIN_API, callApi } from "lib/api-server"
import toast from "react-hot-toast"
import { useDropzone } from "react-dropzone"

const SettingsForm = () => {
  const [data, setData] = useState({
    printCost: "",
    deliveryCost: "",
    absDensity: "",
    plaDensity: "",
    printSpeed: "",
    setupCost: "",
    diameter: "",
    file: undefined,
  })
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  useEffect(() => {
    callApi("GET", SETTING_ADMIN_API)
      .then(res => {
        setData({
          printCost: res.print_cost,
          deliveryCost: res.delivery_cost,
          absDensity: res.abs_density,
          plaDensity: res.pla_density,
          printSpeed: res.print_speed,
          setupCost: res.setup_cost,
          diameter: res.diameter,
        })
      })
      .catch(err => console.log(err))
  }, [])

  const handleSave = event => {
    event.preventDefault()
    if (!isSaveLoading) {
      setIsSaveLoading(true)

      const formData = new FormData()
      formData.append("print_cost", data.printCost)
      formData.append("delivery_cost", data.deliveryCost)
      formData.append("abs_density", data.absDensity)
      formData.append("pla_density", data.plaDensity)
      formData.append("diameter", data.diameter)
      formData.append("print_speed", data.printSpeed)
      formData.append("setup_cost", data.setupCost)
      formData.append("file", data.file)

      callApi("PUT", SETTING_ADMIN_API, formData)
        .then(res => {
          toast.success(res.message)
          setIsSaveLoading(false)
        })
        .catch(err => {
          setIsSaveLoading(false)
        })
    }
  }

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setData({
          ...data,
          file: acceptedFiles[0],
        })
        toast.dismiss()
      } else {
        toast.error("Only accept json type file", {
          id: "upload-error-type-file",
        })
      }
    },
    [data]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true })

  return (
    <form>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="print-cost">
              Print Cost (Per Hour AUD Dollar Rate)
            </label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="print-cost"
              aria-describedby=""
              value={data.printCost}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, printCost: e.target.value })
                }
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="delivery-cost">Delivery Cost (AUD Price)</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="delivery-cost"
              aria-describedby=""
              value={data.deliveryCost}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, deliveryCost: e.target.value })
                }
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="setup-cost">Setup Cost (AUD Price)</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="setup-cost"
              aria-describedby=""
              value={data.setupCost}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, setupCost: e.target.value })
                }
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="abs-density">ABS Density</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="abs-density"
              aria-describedby=""
              value={data.absDensity}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, absDensity: e.target.value })
                }
              }}
            />
          </div>
        </div>

        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="pla-density">PLA Density</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="pla-density"
              aria-describedby=""
              value={data.plaDensity}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, plaDensity: e.target.value })
                }
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="print-speed">Print Speed</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="print-speed"
              aria-describedby=""
              value={data.printSpeed}
              onChange={e => {
                const re = /^[0-9]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, printSpeed: e.target.value })
                }
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="print-speed">Diameter</label>
            <input
              className="form-control"
              type="text"
              pattern="[0-9]*"
              id="print-speed"
              aria-describedby=""
              value={data.diameter}
              onChange={e => {
                const re = /^[0-9\b\.]+$/
                if (e.target.value === "" || re.test(e.target.value)) {
                  setData({ ...data, diameter: e.target.value })
                }
              }}
            />
          </div>

          {/*<div className="form-group">
            <label htmlFor="ip-file-upload">File: {data?.file?.name}</label>
            <div className="dragdrop" id="dragdrop" {...getRootProps()}>
              <input id="ip-file-upload" {...getInputProps({accept: ".json"})} />
              <div className="dragdrop__wrapper dragdrop--empty">
                <i className="ri-4x ri-drag-drop-line dragdrop__icon"></i>
                <div className="dragdrop__cta">
                  <label htmlFor="ip-file-upload" className="btn btn-white dragdrop__cta">
                    Upload File
                  </label>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <button className="btn btn-primary" type="submit" onClick={handleSave}>
          {isSaveLoading ? (
            <span
              style={{ width: "1rem", height: "1rem" }}
              className="spinner-border text-primary"
              role="status"
            />
          ) : (
            `SAVE`
          )}
        </button>
      </div>
    </form>
  )
}

export default SettingsForm
