import React from "react"
import { Helmet } from "react-helmet"
import Favicon from "assets/img/favicon.png"

export default function Meta(props) {
  return (
    <Helmet>
      <title> {props.title} </title>
      <meta
        name="description"
        value={props.description || process.env.PAGE_DESCRIPTION}
      ></meta>
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
      <script
        src="https://kit.fontawesome.com/380d67b9f1.js"
        crossorigin="anonymous"
      ></script>
      <link rel="icon" type="image/png" href={Favicon} />
    </Helmet>
  )
}
